<div class="promotion-mobile-container">
  <div class="header-container">
    <fa-icon [icon]="faArrowLeft" class="back-icon" (click)="goBack()"></fa-icon>
    <div class="title">SALDO</div>
  </div>
  <div class="promotion-body">
    <div class="promotion-content">
      <div class="total-saldo-container w-100">
        <div class="text">Jumlah Saldo Tersedia</div>
        <div class="value">@if(!loadBalance){
          <div class="text-nowrap fw-bold">Loading...</div>
          } @else {
          <div class="text-nowrap fw-bold">{{balance | currency:' ' : 'symbol' : '1.0-0'}}</div>
          }</div>
      </div>

      <div class="filter-container">
        <div class="filter-left" (click)="openFilter()">
          <img src="../../template/green/assets/images/mobile/Filter.webp" class="img-fluid filter-icon"/>
            <div class="filter-text">Filter</div>
        </div>
        <div class="filter-right">
          <div class="filter-group">
            <div class="filter-name">{{getTypeText(type)}}</div>
            <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearType()"></fa-icon>
          </div>
          <div class="filter-group">
            <div class="filter-name">{{getValueFromDateList(selectedDate)}}</div>
            <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearDate()"></fa-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="promotion-table" id="scrollableTable" (scroll)="onScroll($event)">
      @if(balanceHistory !== null){
        <div class="mt-2"></div>
        @if(balanceHistory.length != 0){
          @for (item of balanceHistory; track item;) {
            <div class="saldo-list-container mt-2">
              <div class="list-header">
                <div class="text" [ngClass]="{
                  'negative-amount': item.balance < 0,
                  'positive-amount': item.balance > 0
                }"><img src="../../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(item._id)"/>{{ functionGetType(item.type) }}</div>
                <div class="date">{{ dateFormat(item.created_at) }}</div>
              </div>
              <div class="list-content">
                <div class="list-item">
                  <dl>
                    <dt>Saldo Sebelum</dt>
                    <dd><span class="colon">:</span>IDR {{ IDRFormat(item.balance_before) }}</dd>

                    <dt>Jumlah Transaksi</dt>
                    <dd><span class="colon">:</span><span [ngClass]="{
                      'negative-amount': item.balance < 0,
                      'positive-amount': item.balance > 0
                    }">IDR {{item.balance < 0? '-' :''}}{{ IDRFormat(getAbs(item.balance)) }}</span></dd>

                    <dt>Saldo Sesudah</dt>
                    <dd><span class="colon">:</span>IDR {{IDRFormat(saldoCurrent(item.balance_before, item.balance))}}</dd>
                  </dl>
                </div>
              </div>
            </div>
          }
          @if(pageIndex+1 <= maxPage){
            <div class="d-flex justify-content-center align-content-center">
              @if(isLoading){
                <div class="spinner-border"></div>
              } @else {
                <div (click)="loadMoreData()"> Load More <fa-icon [icon]="faCaretDown" [size]="'1x'" class="arrow-left"></fa-icon> </div>
              }
            </div>
          }
        }
      } @else {
        <div class="d-flex justify-content-center align-content-center">
          <div class="spinner-border"></div>
        </div>
        }
    </div>
  </div>
</div>
