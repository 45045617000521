import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { NgTemplateOutlet, CommonModule } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@componentpath/services/toast.service';

@Component({
  selector: 'app-toasts',
  standalone: true,
  imports: [NgbToastModule, CommonModule, NgTemplateOutlet],
  template: `
    <ng-container *ngFor="let toast of toastService.toasts">
      <ngb-toast
        [class]="toast.classname"
        [autohide]="true"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)"
      >
        <ng-template
          [ngTemplateOutlet]="toast.template || defaultTemplate"
          [ngTemplateOutletContext]="toast.context || {}"
        ></ng-template>
      </ngb-toast>
    </ng-container>
  `,
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    style: 'z-index: 1200',
  },
})
export class ToastComponent {
  toastService = inject(ToastService);

  @ViewChild('defaultTemplate', { static: true })
  defaultTemplate!: TemplateRef<any>;

  constructor() {}
}
