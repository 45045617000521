<div class="jackpot px-0 mx-0" [ngClass]="hideLiveScore? 'hide-livescore':''">
  @if(currentScreenSize === 'xSmall'){
    <div class="container-xxl d-block px-0 mx-0 jackpot-inner">
      <span class="value mobile" #a>
        <div class="blink-text">IDR {{jackpotGlobalVal | formatNumberId}}</div>
      </span>
      <img ngSrc="../../../template/green/assets/images/jackpot/{{websiteJSONData.misc.jackpot}}/jackpot_mobile.gif" width="951" height="136" priority />
    </div>
  } @else {
    <div class="container-xxl d-block px-0 mx-0 jackpot-inner">
      <span class="value" style="justify-content: flex-end !important;" #a>
        <div class="blink-text" style="font-size: 3vw; margin-right: 60px;">IDR {{jackpotGlobalVal | formatNumberId}}</div>
      </span>
      <img ngSrc="../../../template/green/assets/images/jackpot/{{websiteJSONData.misc.jackpot}}/jackpot_desktop.gif" width="951" height="136" priority />
    </div>
  }
</div>
