<div class="modal-header">
    <h4 class="modal-title">PENGAJUAN KLAIM</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center">
        <div class="bg-blue p-4 mb-3 rounded">
            @if(successMessage !== '') {
            <div class="alert alert-success p-2" role="alert">
                {{successMessage}}
            </div>
            }
            @if(errorMessage !== '') {
            <div class="alert alert-danger p-2" role="alert">
                {{errorMessage}}
            </div>
            }
            <form (submit)="claimReferralSubmit()">
                <div class="input-group mb-4">
                    <input type="number" class="form-control bg-blue2" placeholder="Total" [(ngModel)]="total"
                        name="total" [max]="max" (keyup)="checkMax()">
                    <span class="input-group-text">
                        <span class="btn btn-sm btn-outline-yellow" (click)="clickMax()">MAX</span>
                    </span>
                </div>
                <div class="text-end">
                    <button class="btn btn-sm btn-yellow2 py-1 px-4" type="submit" [disabled]="loginSubmit">
                        @if(loginSubmit) {
                        <div class="spinner-border p-2 spinner-border-sm"></div>
                        } @else {
                        PROSES
                        }
                    </button>
                </div>
            </form>
        </div>
        <div class="saldo-container">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Tanggal</th>
                        <th scope="col">Jumlah Klaim</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    @if(claimedReferral === null) {
                    <tr>
                        <td colspan="4" align="center">
                            <div class="spinner-border"></div>
                        </td>
                    </tr>
                    } @else if(claimedReferral.data.records.length === 0) {
                    <tr>
                        <td colspan="4" align="center">
                            No Data
                        </td>
                    </tr>
                    } @else {
                    @for (item of claimedReferral.data.records; track item;) {
                    <tr>
                        <td>{{item._id}}</td>
                        <td>{{dateFormat(item.created_at)}}</td>
                        <td>IDR {{IDRFormat(item.claimed_amount)}}</td>
                        <td>
                            @if(item.status !== 0) {
                            <span class="badge badge-pill bg-warning">Waiting for Approval</span>
                            } @else if(item.status === 2) {
                            <span class="badge badge-pill bg-success">Approved</span>
                            }
                        </td>
                    </tr>
                    }
                    }
                </tbody>
                <caption>
                    <div class="row">
                        <div class="col-6">
                            @if(claimedReferral !== null) {
                            Showing
                            <b>{{(claimedReferral.data.limit*(claimedReferral.data.page-1))+1}}</b>-<b>{{(claimedReferral.data.limit*claimedReferral.data.page)
                                > claimedReferral.data.total_records ? claimedReferral.data.total_records :
                                claimedReferral.data.limit*claimedReferral.data.page}}</b>
                            from
                            <b>{{claimedReferral.data.total_records}}</b> data
                            }
                        </div>
                        <div class="col-6">
                            @if(claimedReferral !== null && claimedReferral.data.max_page > 1) {
                            <ul class="pagination">
                                <li class="page-item" [class.disabled]="page===1" (click)="pageChange(page-1)">
                                    <button class="page-link">Previous</button>
                                </li>
                                @for (item of pagination; track item;) {
                                <li class="page-item" (click)="pageChange(item)">
                                    <button class="page-link" [class.active]="page===item">{{item}}</button>
                                </li>
                                }
                                <li class="page-item" [class.disabled]="page===claimedReferral.data.max_page"
                                    (click)="pageChange(page+1)">
                                    <button class="page-link">Next</button>
                                </li>
                            </ul>
                            }
                        </div>
                    </div>
                </caption>
            </table>
        </div>
    </div>
</div>