@if(currentScreenSize != 'mobile'){
  <div class="page-promotion">
    <app-header-simple />
    <div class="page-inner">
      <div class="container-xxl row m-auto">
        <div class="promotion-body mb-5">
          <div class="sidebar-column">
            <app-sidebar-menu [active]="'statement'" />
          </div>
          <div class="col-9 pl-0 promotion-container content-container" id="promotion-container">
            <div class="promotion-inner h-100">
              <div class="promotion-title-custom">
                @if(currentState === state.Month){
                  <div class="title-promotion">Statement</div>
                  <div class="subtitle-promotion">Max Data : 3 Bulan</div>
                } @else if(currentState === state.Provider) {
                  <div class="title-promotion">Detail</div>
                } @else {
                  <div class="title-promotion">Detail By Provider</div>
                }

              </div>
              <div class="page-inner-container ">
                <div class="saldo-container mt-4">
                  @if (currentState === state.Month ) {
                  <div class="row mb-4">
                    <div class="col-4">
                      @if (month.code !== -2) {
                      <button class="btn btn-primary navigation-button inter-bold px-3 py-2" [disabled]="month.code === -2"
                        (click)="changeMonth(month.code - 1, currentMonth - 1)">
                        <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                        {{ months[currentMonth - 1] }}
                      </button>
                      }
                    </div>
                    <div
                      class="col-4 text-white text-center month-current text-uppercase inter-bold d-flex align-items-center justify-content-center">
                      <!-- {{ month.value }}{{ subtitle }} -->
                      {{ subtitle }}
                    </div>
                    <div class="col-4 text-end">
                      @if (month.code !== 0) {
                      <button class="btn btn-primary navigation-button inter-bold px-3 py-2" [disabled]="month.code === 0"
                        (click)="changeMonth(month.code + 1, currentMonth + 1)">
                        {{ months[currentMonth + 1] }}
                        <fa-icon class="arrow-right" [icon]="faCaretRight"></fa-icon>
                      </button>
                      }
                    </div>
                  </div>
                  } @if (currentState === state.Date ) {
                  <div class="">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <button class="btn btn-primary text-white navigation-button inter-bold px-3 py-2"
                          (click)="clickBack()">
                          <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                          Back
                        </button>
                      </div>
                      <div class="col-4 text-center">
                        <div
                          class="text-white text-center month-current text-uppercase inter-bold d-flex align-items-center justify-content-center">
                          {{ subtitle }}
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mb-4 nagivation-color mx-0 rounded">
                    <div class="col-4" style="align-self: center;">
                      @if (isNotLimitStart()) {
                      <button class="btn btn-primary navigation-button inter-bold py-2 nagivation-color"
                        (click)="changeDate(getYesterdayDate())">
                        <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                        {{ formatDisplayDate(getYesterdayDate()) }}
                      </button>
                      }
                    </div>
                    <div class="col-4 text-center inter-bold provider-name">
                      <div class="dropdown-provider">
                        <button (click)="toogleDropdownProvider()" class="dropbtn">{{providerName}} <fa-icon class="arrow-down" [icon]="faCaretDown"></fa-icon></button>
                        @if(showDropdown){
                          <div id="provider-dropdown" class="dropdown-content">
                            <div class="dropdown-content-inner">
                              <div class="d-flex row">
                                  <input type="text" placeholder="Search.." id="provider_filter" class="form-control" (keyup)="filterDropdownProvider($event)">
                                  <fa-icon class="search-icon" [icon]="faMagnifyingGlass"></fa-icon>
                              </div>
                              @for (item of filterProviderLists; track $index) {
                                <span (click)="detailClick(item.provider, item.name)">{{item.name}}</span>
                              }
                            </div>

                          </div>
                        }
                      </div>
                    </div>
                    <div class="col-4 text-end " style="align-self: center;">
                      @if (isNotLimitEnd()) {
                      <button class="btn btn-primary navigation-button inter-bold py-2 nagivation-color"
                        (click)="changeDate(getTomorrowDate())">
                        {{ formatDisplayDate(getTomorrowDate()) }}
                        <fa-icon class="arrow-right" [icon]="faCaretRight"></fa-icon>
                      </button>
                      }
                    </div>
                  </div>
                  } @if (currentState === state.Provider ) {
                  <div class="">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <button class="btn btn-primary text-white navigation-button inter-bold px-3 py-2"
                          (click)="clickBack()">
                          <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                          Back
                        </button>
                      </div>
                      <div class="col-4 text-center">
                        <div
                          class="text-white text-center month-current text-uppercase inter-bold d-flex align-items-center justify-content-center">
                          {{ subtitle }}
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <p></p>
                    </div>
                  </div>
                  <div class="row mb-4 nagivation-color mx-0 rounded">
                    <div class="col-6">
                      @if (isNotLimitStart()) {
                      <button class="btn btn-primary navigation-button inter-bold py-2 nagivation-color"
                        (click)="changeDateProvider(getYesterdayDate())">
                        <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                        {{ formatDisplayDate(getYesterdayDate()) }}
                      </button>
                      }
                    </div>
                    <div class="col-6 text-end">
                      @if (isNotLimitEnd()) {
                      <button class="btn btn-primary navigation-button inter-bold py-2 nagivation-color"
                        (click)="changeDateProvider(getTomorrowDate())">
                        {{ formatDisplayDate(getTomorrowDate()) }}
                        <fa-icon class="arrow-right" [icon]="faCaretRight"></fa-icon>
                      </button>
                      }
                    </div>
                  </div>
                  }
                  <div class="table-promotion-container">
                    <table class="table table-promotion table-responsive-promotion">
                      <thead>
                        <tr>
                          @if(currentState == state.Month) {
                          <th scope="col">Tanggal</th>
                          <th scope="col">Jumlah Turnover</th>
                          <th scope="col">Jumlah Menang/Kalah</th>
                          <th scope="col"></th>
                          } @else if(currentState == state.Provider) {
                          <th scope="col">Provider</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Jumlah Menang/Kalah</th>
                          <th scope="col"></th>
                          } @else if(currentState == state.Date) {
                          <th scope="col">Provider</th>
                          <th scope="col">Permainan</th>
                          <th scope="col">Tipe</th>
                          <th scope="col">Jumlah Bet</th>
                          <th scope="col">Jumlah Menang</th>
                          <th scope="col" class="text-end">Waktu</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        @if(currentState == state.Month) {
                          @if(statement.monthly.result === null) {
                          <tr>
                            <td colspan="4" align="center">
                              <div class="spinner-border"></div>
                            </td>
                          </tr>
                          } @else if(statement.monthly.result.data.result.records.length === 0) {
                          <tr>
                            <td colspan="4" align="center">No Data</td>
                          </tr>
                          } @else {
                            @for (item of statement.monthly.result.data.result.records; track item;) {
                          <tr>
                            <td>{{ dateFormat(item.played_datetime) }}</td>
                            <td>IDR {{ IDRFormat(item.turnover_amount) }}</td>
                            <td [ngClass]="{
                                'negative-amount': item.win_lose_amount < 0,
                                'positive-amount': item.win_lose_amount > 0
                              }">
                              IDR {{item.win_lose_amount < 0? '-':''}}{{ IDRFormat(getAbs(item.win_lose_amount)) }}
                            </td>

                            <td class="">
                              <button class="btn btn-primary btn-sm btn-detail py-2 float-end d-none d-lg-block"
                                (click)="providerClick(item.played_datetime)">
                                Detail
                              </button>
                              <button class="btn btn-primary btn-sm btn-detail btn-icon py-2 float-end d-block d-lg-none"
                                (click)="providerClick(item.played_datetime)">
                                <fa-icon class="arrow-right" [icon]="faCaretRight"></fa-icon>
                              </button>
                            </td>
                          </tr>
                          }
                          }
                        } @else if(currentState == state.Provider) {
                          @if(statement.provider.result === null) {
                            <tr>
                              <td colspan="4" align="center">
                                <div class="spinner-border"></div>
                              </td>
                            </tr>
                          } @else if(statement.provider.result.data.result.records.length === 0) {
                            <tr>
                              <td colspan="4" align="center">No Data</td>
                            </tr>
                          } @else {
                            @for (item of statement.provider.result.data.result.records; track item;) {
                        <tr>
                          <td>{{ item.provider_name }}</td>
                          <td>IDR {{ IDRFormat(item.turnover_amount) }}</td>
                          <td [ngClass]="{
                              'negative-amount': item.win_lose_amount < 0,
                              'positive-amount': item.win_lose_amount > 0
                            }">
                            IDR {{item.win_lose_amount < 0? '-' :''}}{{ IDRFormat(getAbs(item.win_lose_amount)) }}
                          </td>
                          <td class="">
                            <button class="btn btn-primary btn-sm btn-detail py-2 float-end d-none d-lg-block"
                              (click)="detailClick(item.provider, item.provider_name)">
                              Detail
                            </button>
                            <button class="btn btn-primary btn-sm btn-detail btn-icon py-2 float-end d-block d-lg-none"
                                (click)="detailClick(item.provider, item.provider_name)">
                                <fa-icon class="arrow-right" [icon]="faCaretRight"></fa-icon>
                              </button>
                          </td>
                        </tr>
                            }
                          }
                        } @else if(currentState == state.Date) {
                        @if(statement.detail.result === null) {
                        <tr>
                          <td colspan="7" align="center">
                            <div class="spinner-border"></div>
                          </td>
                        </tr>
                        } @else if(statement.detail.result.data.result.records.length
                        === 0) {
                        <tr>
                          <td colspan="7" align="center">No Data</td>
                        </tr>
                        } @else {
                          @for (item of statement.detail.result.data.result.records; track item;) {
                        <tr>
                          <td><img src="../template/green/assets/images/key.webp" class="img-fluid copy-icon" (click)="copyToClipboard(item._id)"/>{{ item.provider_name }}</td>
                          <td>{{ item.game_name }}</td>
                          <td>{{ item.game_category }}</td>
                          <td>IDR {{ IDRFormat(item.bet_amount) }}</td>
                          <td [ngClass]="{
                              'negative-amount': item.win_lose_amount < 0,
                              'positive-amount': item.win_lose_amount > 0
                            }">
                            IDR {{item.win_lose_amount < 0? '-' :''}}{{ IDRFormat(getAbs(item.win_lose_amount)) }}
                          </td>
                          <td class="text-end">{{ dateFormat(item.played_datetime) }} <br> {{ hourFormat(item.played_datetime) }}</td>
                        </tr>
                        }
                      }
                        }
                      </tbody>
                    </table>
                  </div>
                  @if(currentState == state.Date && length != 0){
                    <mat-paginator
                    #paginator
                    (page)="handlePageEvent($event)"
                    [length]="length"
                    [pageSize]="pageSize"
                    [showFirstLastButtons]="true"
                    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                    [hidePageSize]="false"
                    [pageIndex]="pageIndex-1"
                    aria-label="Select page"
                    class="promotion-paginator">
                     </mat-paginator>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <app-footer [data]="footerData" />
    </div>
  </div>
} @else {
  <app-statement-mobile></app-statement-mobile>
}

