import {
  Component, Inject, PLATFORM_ID, TemplateRef,ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SaldoMobileComponent } from '@template/green/mobile/saldo-mobile/saldo-mobile.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ToastService } from '@componentpath/services/toast.service';
import { ToastComponent } from '@componentpath/parts/toast/toast.component';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
@Component({
  selector: 'app-saldo',
  standalone: true,
  imports: [SaldoMobileComponent, MatPaginatorModule,HeaderSimpleComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, MatFormFieldModule, MatSelectModule, CommonModule, ToastComponent],
  templateUrl: './saldo.component.html',
  styleUrl: './saldo.component.scss'
})
export class SaldoComponent {
  @ViewChild('toastCopy') toastCopy!: TemplateRef<any>;
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';

  //table variable
  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50];
  showPageSizeOptions = true;

  pageEvent!: PageEvent;

  faCircleChevronLeft: IconProp = faCircleChevronLeft;
  faCircleChevronRight: IconProp = faCircleChevronRight;

  balanceHistory: any = null;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  subscription: Subscription = new Subscription();
  balance: any = null;

  type: string = '';
  created_at: any = '';

  selectedType: string = '';
  selectedDate: string = '';

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.getData(e.pageIndex + 1);
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.doc.body.scrollTo(0, 0)
    }, 200);
  }
  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else{
        this.currentScreenSize = 'desktop';
      }
    });
  }



  functionGetType(s:number){
    let valueType = this.dataType[s.toString()];
    if(valueType === undefined){
      valueType = s;
      if(valueType >= 70000 && valueType <= 70099){
        valueType = "Seamless Play"
      }
      if(valueType >= 80000 && valueType <= 80099){
        valueType = "Referral"
      }
      if(valueType >= 80100 && valueType <= 80199){
        valueType = "Tournament"
      }
      if(valueType >= 80201){
        valueType = "Promosi Rollingan"
      }
      if(valueType >= 80202){
        valueType = "Promosi Cashback"
      }
      if(valueType >= 80203){
        valueType = "Promosi Deposit"
      }
      if(valueType >= 80204){
        valueType = "Promosi Airdrop"
      }
      if(valueType >= 80205){
        valueType = "Promosi Spinwheel"
      }
      if(valueType == 30001){
        valueType = "Deposit"
      }
      if(valueType == 30002){
        valueType = "Withdrawal"
      }
      if(valueType == 30002){
        valueType = "Withdrawal"
      }
      if(valueType == 30003){
        valueType = "Deposit Ditolak"
      }
      if(valueType == 30004){
        valueType = "Withdrawal Ditolak"
      }
      if(valueType == 30005){
        valueType = "Deposit Diterima"
      }
      if(valueType == 30006){
        valueType = "Withdrawal Diterima"
      }
      if(valueType == 50001){
        valueType = "Transfer In"
      }
      if(valueType == 50002){
        valueType = "Transfer Out"
      }
      if(valueType == 50003){
        valueType = "Refund Transfer In"
      }
      if(valueType == 50004){
        valueType = "Refund Transfer Out"
      }
    }

    return valueType
  }

  typeList: Record<string, any> = {
    "83001": "Deposit",
    "83002": "Withdrawal",
    "83004": "Withdrawal Ditolak",
    "50001": "Transfer Masuk",
    "50002": "Transfer Keluar",
    "700xx": "Seamless Play",
    "800xx": "Referral",
    "801xx": "Tournament",
    "80201": "Promosi Rollingan",
    "80202": "Promosi Cashback",
    "80203": "Promosi Deposit",
    "80204": "Promosi Airdrop",
    "80205": "Promosi Spinwheel",
  }


  dataType:Record<string,any> ={
      "83001": "Deposit",
      "83002": "Withdrawal",
      "83004": "Withdrawal Ditolak",
      "50001": "Transfer Masuk",
      "50002": "Transfer Keluar",
      "700xx": "Seamless Play",
      "800xx": "Referral",
      "801xx": "Tournament",
      "80201": "Promosi Rollingan",
      "80202": "Promosi Cashback",
      "80203": "Promosi Deposit",
      "80204": "Promosi Airdrop",
      "80205": "Promosi Spinwheel",
  }

  typeLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "83001": "Deposit",
      "83002": "Withdrawal",
      "83004": "Withdrawal Ditolak",
      "50001": "Transfer Masuk",
      "50002": "Transfer Keluar",
      "700xx": "Seamless Play",
      "800xx": "Referral",
      "801xx": "Tournament",
      "80201": "Promosi Rollingan",
      "80202": "Promosi Cashback",
      "80203": "Promosi Deposit",
      "80204": "Promosi Airdrop",
      "80205": "Promosi Spinwheel",
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  copyToClipboard(id: string): void {
    navigator.clipboard.writeText(id).then(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Berhasil Copy ID', type : 'default' },
        duration: 3000,
        panelClass: "notification-default"
      });
    }).catch(err => {
      console.error('Failed to copy ID to clipboard', err);
    });
  }

  IDRFormat(value: number) {
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);
    return Intl.NumberFormat('id-ID').format(roundedValue);
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD ');
  }

  hourFormat(value: string) {
    return dayjs(value + 'Z').format('HH:mm:ss');
  }



  saldoCurrent(saldoBefore: any, saldo: any) {
    return parseInt(saldoBefore) + parseInt(saldo);
  }

  getAbs(value: number): number {
    return Math.abs(value);
  }


  typeChange(event: any) {
    this.type = event.value;
    this.getData(1);
  }

  dateChange(event: any) {
    const today = dayjs().format('YYYY-MM-DD');
    const lastDay = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const endMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    const startWeek = dayjs().subtract(dayjs().day(), 'day').format('YYYY-MM-DD');
    const endWeek = dayjs().add(6 - (dayjs().day()), 'day').format('YYYY-MM-DD');
    const startPastWeek = dayjs().subtract((dayjs().day() + 7), 'day').format('YYYY-MM-DD');
    const endPastWeek = dayjs().subtract(((6 - (dayjs().day())) + 7), 'day').format('YYYY-MM-DD');
    switch (event.value) {
      case '':
        this.created_at = '';
        break;
      case 'today':
        this.created_at = { start: today, end: today };
        break;
      case 'yesterday':
        this.created_at = { start: lastDay, end: lastDay };
        break;
      case 'monthNow':
        this.created_at = { start: startMonth, end: endMonth };
        break;
      case 'weekNow':
        this.created_at = { start: startWeek, end: endWeek };
        break;
      case 'weekBefore':
        this.created_at = { start: startPastWeek, end: endPastWeek };
        break;
      default:
        this.created_at = '';
    }
    this.getData(1);
  }

  getData(pageIndex : number) {
    this.balanceHistory = null;
    const userProfileData: any = this.auth.userProfileData;
    let currentType = this.type;
    if (this.type === "00000" ){
      currentType = '';
    }
    this.request.getBalanceHistory(this.auth.sessionValue, userProfileData._id, { type: currentType, created_at: this.created_at }, { page: pageIndex, limit: this.pageSize }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.length = v.data.total_records;
          this.pageIndex = pageIndex;
          this.balanceHistory = v;
          this.scrollToTop();
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'default'){
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.getData(1);

      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
const items = [
  { type: 1 },
  { type: 2 },
  { type: 3 },
  { type: 4 },
];
