<div class="modal-header">
    <h4 class="modal-title">DETAIL</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center">
        <div class="saldo-container">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Login ID</th>
                        <th scope="col">Total Omzet</th>
                        <th scope="col">Total Setoran</th>
                        <th scope="col">Total Bonus</th>
                    </tr>
                </thead>
                <tbody>
                    @if(getReferralDownline === null) {
                    <tr>
                        <td colspan="5" align="center">
                            <div class="spinner-border"></div>
                        </td>
                    </tr>
                    } @else if(getReferralDownline.data.records.length === 0) {
                    <tr>
                        <td colspan="5" align="center">
                            No Data
                        </td>
                    </tr>
                    } @else {
                    @for (item of getReferralDownline.data.records; track item;) {
                    <tr>
                        <td>{{item._id}}</td>
                        <td>{{item.parent_login_id}}</td>
                        <td>IDR {{IDRFormat(item.total_downline_turnover)}}</td>
                        <td>IDR {{IDRFormat(item.total_downline_deposit)}}</td>
                        <td>IDR {{IDRFormat(item.earning)}}</td>
                    </tr>
                    }
                    }
                </tbody>
                <caption>
                    <div class="row">
                        <div class="col-6">
                            @if(getReferralDownline !== null) {
                            Showing
                            <b>{{(getReferralDownline.data.limit*(getReferralDownline.data.page-1))+1}}</b>-<b>{{(getReferralDownline.data.limit*getReferralDownline.data.page)
                                > getReferralDownline.data.total_records ? getReferralDownline.data.total_records :
                                getReferralDownline.data.limit*getReferralDownline.data.page}}</b>
                            from
                            <b>{{getReferralDownline.data.total_records}}</b> data
                            }
                        </div>
                        <div class="col-6">
                            <!-- <ul class="pagination">
                                <li class="page-item disabled">
                                    <button class="page-link">Previous</button>
                                </li>
                                <li class="page-item">
                                    <button class="page-link active">1</button>
                                </li>
                                <li class="page-item">
                                    <button class="page-link">2</button>
                                </li>
                                <li class="page-item">
                                    <button class="page-link">3</button>
                                </li>
                                <li class="page-item">
                                    <button class="page-link">4</button>
                                </li>
                                <li class="page-item">
                                    <button class="page-link">Next</button>
                                </li>
                            </ul> -->
                        </div>
                    </div>
                </caption>
            </table>
        </div>
    </div>
</div>