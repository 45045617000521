<div class="card card-game">
    <div class="card-game-top">
        <div class="ratio ratio-1x1">
            @if(image !== '' && image !== undefined) {
            <img [src]="image" [alt]="name" class="card-img-top">
            } @else() {
            <div class="no-image">No Image</div>
            }
        </div>

        @if(gacor !== '') {
        <div class="gacor">
            <fa-icon [icon]="faClock"></fa-icon> Gacor : {{gacor}}
        </div>
        }
        <div [class]="'favourite favourite-'+favourite">
            <fa-icon [icon]="faStar"></fa-icon>
        </div>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{name}}</h5>
        <div class="provider">{{provider}}</div>
        <div class="percent">
            <div class="progress">
                <div class="progress-bar" [style]="{width: percent+'%'}"></div>
            </div>
            <div class="text">
                {{percent}} %
            </div>
        </div>
        <div class="pattern">Pola Slot <span class="sep">:</span><span class="value">{{pattern}}</span></div>
        <div class="bottom">
            @if(check1) {
            <fa-icon class="check check-true" [icon]="faCircleCheck"></fa-icon>
            } @else {
            <fa-icon class="check check-false" [icon]="faCircleXmark"></fa-icon>
            }
            @if(check2) {
            <fa-icon class="check check-true" [icon]="faCircleCheck"></fa-icon>
            } @else {
            <fa-icon class="check check-false" [icon]="faCircleXmark"></fa-icon>
            }
            @if(check3) {
            <fa-icon class="check check-true" [icon]="faCircleCheck"></fa-icon>
            } @else {
            <fa-icon class="check check-false" [icon]="faCircleXmark"></fa-icon>
            }
            <div class="dc">
                @if(dc) {
                (DC ON)
                } @else {
                (DC OFF)
                }
            </div>
        </div>
    </div>
</div>