import { Component, Inject, PLATFORM_ID,OnInit, ViewChild, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import dayjs from 'dayjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretLeft, faCaretRight, faWallet, faCircleXmark,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule,DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { Location } from '@angular/common';
import { FilterMobileComponent } from '../filter-mobile/filter-mobile.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { RouterExtService } from '@componentpath/services/scripts.service';
@Component({
  selector: 'app-transaction-mobile',
  standalone: true,
  imports: [TransactionMobileComponent,HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, MatFormFieldModule, MatSelectModule, CommonModule,MatPaginatorModule],
  templateUrl: './transaction-mobile.component.html',
  styleUrl: './transaction-mobile.component.scss'
})
export class TransactionMobileComponent implements OnInit{
  subscription: Subscription = new Subscription();
  prevURL: string = '';

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    private routerExtService: RouterExtService
  ) {}

  faCaretLeft: IconProp = faCaretLeft;
  faCaretRight: IconProp = faCaretRight;
  faWallet: IconProp = faWallet;
  faCircleXmark: IconProp = faCircleXmark;
  faArrowLeft: IconProp = faArrowLeft;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  transaction: any = null;
  transactionDetail: any = null;
  transactionDetailLoading: any = null;

  type: any = '0';
  status: any = '';
  created_at: any = '';

  selectedType: any = '';
  selectedDate: any = '';

  part : string = 'default';

  //table variable
  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50];
  showPageSizeOptions = true;
  maxPage = 0;

  pageEvent!: PageEvent;

  isLoading = false;
  codes: any = {
    "0": "All",
    "1": "Deposit",
    "2": "Withdrawal",
    "3": "Manual Adjustment In",
    "4": "Manual Adjustment Out",
    "5": "Auto Deposit"
  };

  openFilter() {
    if(this.type == ''){
      this.type = '0';
    }
    let currentFilter = {
      type: this.type,
      created_at : this.selectedDate
    }
    let dataFilter = {
      type: 'transaction',
      data: {
        currentFilter: currentFilter,
        typeList: this.codes,
        dateList : this.dateList
      }
    }
    this.bottomSheet.open(FilterMobileComponent, {
      data: dataFilter
    }).afterDismissed().subscribe((v) => {
      // console.log(v)
      this.onSelectFilter(v);
    })
  }

  clearType(){
    this.selectedType = '';
    this.type = '';
    this.transaction = null;
    this.pageIndex = 1;
    this.getData();
  }

  clearDate(){
    this.selectedDate = '';
    this.created_at = '';
    this.transaction = null;
    this.pageIndex = 1;
    this.getData();
  }

  onSelectFilter(e: any): void {
    this.type = e.type;
    this.dateChange(e.created_at);
    this.selectedDate = e.created_at;
    this.transaction = null;
    this.pageIndex = 1;
    this.getData();
  }

  dateList:Record<string,any> ={
    "": "Semua Tanggal",
    "today": "Hari Ini",
    "yesterday": "Kemarin",
    "weekNow": "Minggu Ini",
    "weekBefore": "Minggu Lalu",
    "monthNow": "Bulan Ini",
}

goBack(): void {
  if (isPlatformBrowser(this.platformId)) {
    this.prevURL = this.routerExtService.getPreviousUrl();
    // console.log(this.prevURL)
    // const currentUrl = window.location.href;
    // const referrerUrl = document.referrer;

    // const extractDomain = (url: string) => {
    //   try {
    //     const { hostname } = new URL(url);
    //     return hostname;
    //   } catch (e) {
    //     return null;
    //   }
    // };

    // const currentDomain = extractDomain(currentUrl);
    // const referrerDomain = extractDomain(referrerUrl);

    // if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
    //   this.location.back();
    // } else {
    //   this.router.navigate(['/']);
    // }

    // console.log(this.prevURL)
    // console.log(this.router.url)
    if (this.prevURL === this.router.url) {
      if (this.part === 'detail') {
        this.router.navigate(['/transaction']);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      if (this.part === 'default') {
        // console.log(this.part);
        this.router.navigate(['/']);
      } else {
        // console.log(this.prevURL)
        this.router.navigateByUrl(this.prevURL);
      }
    }
  }
}

  onScroll(event: any) {
    this.checkScrollBottom();
  }

  checkScrollBottom() {
      const element = this.doc.getElementById('scrollableTable');
      if (!element) return;
      const isScrolledBottom = element.scrollTop + element.offsetHeight >= element.scrollHeight-5;
      if (isScrolledBottom && !this.isLoading) {
        this.loadMoreData();
      }
  }

  loadMoreData() {
    this.isLoading = true;
    if(this.pageIndex+1 <= this.maxPage){
      this.pageIndex++;
      this.getData();
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageIndex = e.pageIndex + 1;
    this.getData();
  }

  clickBack() {
    this.transactionDetailLoading = null;
    this.transactionDetail = null;
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'default' } });
  }

  IDRFormat(value: number) {
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);
    return Intl.NumberFormat('id-ID').format(roundedValue);
  }

  customIDRFormat(value: number){
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);

    if(roundedValue < 0){
      return '- IDR ' + Intl.NumberFormat('id-ID').format(roundedValue*-1);
    } else {
      return 'IDR '+Intl.NumberFormat('id-ID').format(roundedValue);
    }
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.doc.body.scrollTo(0, 0)
    }, 200);
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD');
  }

  hourFormat(value: string) {
    return dayjs(value + 'Z').format('HH:mm:ss');
  }

  pageChange(page: number) {
    this.pageIndex = page;
    this.getData();
  }

  typeChange(event: any) {
    this.transactionDetailLoading = null;
    this.pageIndex = 1;
    this.type = event.value;
    this.getData();
  }

  dateChange(event: any) {
    this.transactionDetailLoading = null;
    if (event === '') {
      this.created_at = '';
    } else if (event === 'today') {
      this.created_at = {
        start: dayjs().format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event === 'yesterday') {
      this.created_at = {
        start: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      };
    } else if (event === 'monthNow') {
      this.created_at = {
        start: dayjs().startOf('month').format('YYYY-MM-DD'),
        end: dayjs().endOf('month').format('YYYY-MM-DD')
      };
    } else if (event === 'weekNow') {
      this.created_at = {
        start: dayjs().subtract(dayjs().day(), 'day').format('YYYY-MM-DD'),
        end: dayjs().add(6 - (dayjs().day()), 'day').format('YYYY-MM-DD')
      };
    } else if (event === 'weekBefore') {
      this.created_at = {
        start: dayjs().subtract((dayjs().day() + 7), 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(((6 - (dayjs().day())) + 7), 'day').format('YYYY-MM-DD')
      };
    }
  }

  statusChange(event: any) {
    this.transactionDetailLoading = null;
    this.pageIndex = 1;
    this.status = event.value;
    this.getData();
  }

  typeLabel(value: any) {
    if (value == '') {
      return 'Semua Tipe';
    }
    let label: any = '';
    const codes: any = {
      "0": "Semua Tipe",
      "1": "Deposit",
      "2": "Withdrawal",
      "3": "Manual Adjustment In",
      "4": "Manual Adjustment Out",
      "5": "Auto Deposit"
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  getValueFromDateList(key: string): any {
    return this.dateList[key];
  }

  statusLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "3": 'Rejected',
      "2": 'Approved',
      "1": 'On Hold',
      "0": 'Pending',
      "" : 'All'
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  getData() {
    let query: any = {
      type: this.type,
      date: this.created_at
    }
    if (this.status !== '') {
      query['status'] = this.status;
    }
    // console.log(query);
    this.request.getTransaction(this.auth.sessionValue, query, { page: this.pageIndex, limit: this.pageSize }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.length = v.data.total_records;
          this.maxPage = v.data.max_page;
          if(this.transaction == null){
            this.transaction = v.data.records;
          } else {
            this.transaction.push(...v.data.records);
          }
          this.isLoading = false;
          this.isLoading = false
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  copyToClipboard(id: string): void {
    navigator.clipboard.writeText(id).then(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'ID Key berhasil disalin', type : 'mobile-default' },
        duration: 3000,
        panelClass: "notification-default"
      });
    }).catch(err => {
      console.error('Failed to copy ID to clipboard', err);
    });
  }

  clickDetail(id: string) {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'detail' } });
    this.transactionDetailLoading = true;
    this.request.getTransactionById(this.auth.sessionValue, id).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.scrollToTop();
          this.transactionDetailLoading = false;
          this.transactionDetail = v;
          // console.log(v);
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      // if(params['part'] == 'default'){
        
      // } 
      if (params['part'] == 'detail') {
        this.part = 'detail';
      } else {
        this.part = 'default';
        this.transactionDetailLoading = null;
      }
    });
    if(this.transactionDetail == null){
      this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'default' } });
      this.part = 'default';
      this.transactionDetailLoading = null;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.getData();

      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
