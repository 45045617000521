<div class="promotion-mobile-container">
  <div class="header-container">
    <fa-icon [icon]="faArrowLeft" class="back-icon" (click)="goBack()"></fa-icon>
    <div class="title">STATEMENT</div>
  </div>
  @if(currentState === state.Month){
  <div class="statement-header">
    <div class="col-4 text-start">
      @if (month.code !== -2) {
      <button
        [disabled]="month.code === -2"
        (click)="changeMonth(month.code - 1, currentMonth - 1)"
        class="button-header"
      >
        <fa-icon [icon]="faCaretLeft" class="arrow-left"></fa-icon>
        {{ months[currentMonth - 1] }}
      </button>
      }
    </div>
    <div class="col-4">
      <div class="subtitle-header">
        {{ subtitleMonth }}
      </div>
    </div>
    <div class="col-4 text-end">
      @if (month.code !== 0) {
      <button
        [disabled]="month.code === 0"
        (click)="changeMonth(month.code + 1, currentMonth + 1)"
        class="button-header"
      >
        {{ months[currentMonth + 1] }}
        <fa-icon [icon]="faCaretRight" class="arrow-left"></fa-icon>
      </button>
      }
    </div>
  </div>
  } @else if(currentState === state.Provider){
    <div class="statement-header">
      <div class="col-4 text-start">
        <button
        [disabled]="!isNotLimitStart()"
        [ngClass]="{'disable-button': !isNotLimitStart()}"
          (click)="changeDateProvider(getYesterdayDate())"
          class="button-header"
        >
          <fa-icon [icon]="faCaretLeft" class="arrow-left"></fa-icon>
          {{ formatDisplayDate(getYesterdayDate()) }}
        </button>
      </div>
      <div class="col-4">
        <div class="subtitle-header">
          {{ subtitleMonth }}
        </div>
      </div>
      <div class="col-4 text-end">
        <button
        [disabled]="!isNotLimitEnd()"
        [ngClass]="{'disable-button': !isNotLimitEnd()}"
          (click)="changeDateProvider(getTomorrowDate())"
          class="button-header"
        >
        {{ formatDisplayDate(getTomorrowDate()) }}
          <fa-icon [icon]="faCaretRight" class="arrow-left"></fa-icon>
        </button>
      </div>
    </div>
  }@else{
    <div class="statement-header">
      <div class="col-4 text-start">
        <button
        [disabled]="!isNotLimitStart()"
        [ngClass]="{'disable-button': !isNotLimitStart()}"
          (click)="changeDate(getYesterdayDate())"
          class="button-header"
        >
          <fa-icon [icon]="faCaretLeft" class="arrow-left"></fa-icon>
          {{ formatDisplayDate(getYesterdayDate()) }}
        </button>
      </div>
      <div class="col-4">
        <div class="subtitle-header">
          {{ subtitleMonth }}
        </div>
      </div>
      <div class="col-4 text-end">
        <button
        [disabled]="!isNotLimitEnd()"
        [ngClass]="{'disable-button': !isNotLimitEnd()}"
          (click)="changeDate(getTomorrowDate())"
          class="button-header"
        >
        {{ formatDisplayDate(getTomorrowDate()) }}
          <fa-icon [icon]="faCaretRight" class="arrow-left"></fa-icon>
        </button>
      </div>
    </div>
    <div class="provider-name-header" (click)="openFilter()">
      {{providerName}} <fa-icon class="arrow-down" [icon]="faCaretDown"></fa-icon>
    </div>
  }
  <div class="promotion-body ">
    <div class="promotion-content">
      @if (currentState === state.Month ) {
        @if(statement.monthly.result === null) {
          <div class="text-center">
            <div class="spinner-border"></div>
          </div>
        }@else {
          @if(statement.monthly.result.data.result.records.length === 0) {
            <div class="no-data-content">
              <img src="../../template/green/assets/images/mobile/no_data.webp" class="img-fluid no-data-icon"/>
              <div class="no-data-title">
                No Data Available
              </div>
              <div class="no-data-subtitle">
                There is no data to show you right now
              </div>
            </div>
          } @else {
          <div class="promotion-table month-table" id="monthTable">
            @if(statement.monthly.result !== null) {
            @for (item of statement.monthly.result.data.result.records; track item;)
            {
            <div class="transaction-list-container mt-2">
              <div class="list-header">
                <div class="text">{{ dateFormat(item.played_datetime) }}
                </div>
              </div>
              <div class="list-container">
                <div class="list-content">
                  <div class="list-item">
                    <dl>
                      <dt>Jumlah Turnover</dt>
                      <dd>
                        <span class="colon">:</span>IDR
                        {{ IDRFormat(item.turnover_amount) }}
                      </dd>

                      <dt>Jumlah Menang/Kalah</dt>
                      <dd>
                        <span class="colon">:</span
                        ><span
                          [ngClass]="{
                            'negative-amount': item.win_lose_amount < 0,
                            'positive-amount': item.win_lose_amount > 0
                          }"
                          >{{ customIDRFormat(item.win_lose_amount) }}</span
                        >
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="button-detail" (click)="providerClick(item.played_datetime)">
                  Show Detail
                </div>
              </div>

            </div>
            }
          }
          </div>
        }
      }
        }

      @if(currentState === state.Provider){
        <div class="promotion-table provider-table" id="providerTable">
          @if(statement.provider.result === null) {
            <div class="text-center">
              <div class="spinner-border"></div>
            </div>
          }@else {
          @if(statement.provider.result.data.result.records.length === 0) {
            <div class="no-data-content">
              <img src="../../template/green/assets/images/mobile/no_data.webp" class="img-fluid no-data-icon"/>
              <div class="no-data-title">
                No Data Available
              </div>
              <div class="no-data-subtitle">
                There is no data to show you right now
              </div>
            </div>
          } @else {
            @for (item of statement.provider.result.data.result.records; track item;) {
            <div class="transaction-list-container mt-2">
              <div class="list-header">
                <div class="text">{{ item.provider_name }}
                </div>
              </div>
              <div class="list-container">
                <div class="list-content">
                  <div class="list-item">
                    <dl>
                      <dt>Jumlah Turnover</dt>
                      <dd>
                        <span class="colon">:</span>IDR
                        {{ IDRFormat(item.turnover_amount) }}
                      </dd>

                      <dt>Jumlah Menang/Kalah</dt>
                      <dd>
                        <span class="colon">:</span
                        ><span
                          [ngClass]="{
                            'negative-amount': item.win_lose_amount < 0,
                            'positive-amount': item.win_lose_amount > 0
                          }"
                          >{{ customIDRFormat(item.win_lose_amount) }}</span
                        >
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="button-detail" (click)="detailClick(item.provider, item.provider_name)">
                  Show Detail
                </div>
              </div>

            </div>
            }
          }
        }
        </div>
      }

      @if(currentState === state.Date){
        <div class="promotion-table date-table" id="dateTable" (scroll)="onScroll($event)">
          @if(statement.detail.result === null) {
            <div class="text-center">
              <div class="spinner-border"></div>
            </div>
          }@else {
            @if(statement.detail.result.length === 0){
              <div class="no-data-content">
                <img src="../../template/green/assets/images/mobile/no_data.webp" class="img-fluid no-data-icon"/>
                <div class="no-data-title">
                  No Data Available
                </div>
                <div class="no-data-subtitle">
                  There is no data to show you right now
                </div>
              </div>
            } @else{
            @for (item of statement.detail.result; track item;) {
          <div class="transaction-list-container mt-2">
            <div class="list-header header-game-detail">
              <div class="text"><img src="../../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(item._id)"/>{{ item.game_name }}
              </div>
              <div class="detail-date">
                <div>
                  {{ dateFormat(item.played_datetime) }}
                </div>
                <div class="detail-hour">
                  {{ hourFormat(item.played_datetime) }}
                </div>
              </div>
            </div>
              <div class="list-content">
                <div class="list-item">
                  <dl>
                    <dt>Tipe Permainan</dt>
                    <dd>
                      <span class="colon">:</span>
                      <span>{{item.game_category }}
                      </span>
                    </dd>

                    <dt>Jumlah Bet</dt>
                    <dd>
                      <span class="colon">:</span
                      ><span
                        >{{ IDRFormat(item.bet_amount) }}</span
                      >
                    </dd>

                    <dt>Jumlah Menang/Kalah</dt>
                    <dd>
                      <span class="colon">:</span
                      ><span
                        [ngClass]="{
                          'negative-amount': item.win_lose_amount < 0,
                          'positive-amount': item.win_lose_amount > 0
                        }"
                        >{{ customIDRFormat(item.win_lose_amount) }}</span
                      >
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          }
        }
          @if(isLoadingDetail){
            <div class="text-center">
              <div class="spinner-border"></div>
            </div>
          }
        }
        </div>
      }
    </div>
  </div>
</div>
