import { InjectionToken } from '@angular/core';
import { Request, Response } from 'express';

export const REQUEST = new InjectionToken<Request>('REQUEST');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');
export const WEBSITECONFIG = new InjectionToken<Response>('WEBSITECONFIG');
export const WEBSITEJSON = new InjectionToken<Response>('WEBSITEJSON');
export const SLIDESHOWJSON = new InjectionToken<Response>('SLIDESHOWJSON');
export const POPUPSJSON = new InjectionToken<Response>('POPUPSJSON');
export const REFERRALRULEJSON = new InjectionToken<Response>('REFERRALRULEJSON');
export const PROVIDERJSON = new InjectionToken<Response>('PROVIDERJSON');
export const CATEGORYPROVIDERJSON = new InjectionToken<Response>('CATEGORYPROVIDERJSON');
export const CATEGORYSLIDESHOWJSON = new InjectionToken<Response>('CATEGORYSLIDESHOWJSON');
export const PROMOJSON = new InjectionToken<Response>('PROMOJSON');
export const FOOTERJSON = new InjectionToken<Response>('FOOTERJSON');
export const NEWSTICKERJSON = new InjectionToken<Response>('NEWSTICKERJSON');
export const BANKREGISTERJSON = new InjectionToken<Response>('BANKREGISTERJSON');
export const TRANSACTIONCONFIGJSON = new InjectionToken<Response>('TRANSACTIONCONFIGJSON');