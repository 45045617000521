import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, CommonModule,DOCUMENT } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { ProfileInformationMobileComponent } from "@template/green/mobile/parts/profile-information-mobile/profile-information-mobile.component";
import { PinComponent } from "@template/green/mobile/parts/pin/pin.component";
import { PasswordComponent } from "@template/green/mobile/parts/password/password.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FormsModule } from '@angular/forms';
import {
  faUser,
  faEnvelope,
  faPhone,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthService } from '@componentpath/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouterExtService } from '@componentpath/services/scripts.service';
@Component({
  selector: 'app-profile-mobile',
  standalone: true,
  imports: [CommonModule, HeaderComponent, ProfileInformationMobileComponent, PinComponent, PasswordComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './profile-mobile.component.html',
  styleUrl: './profile-mobile.component.scss'
})
export class ProfileMobileComponent {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private routerExtService: RouterExtService
  ) { }

  prevURL: string = '';

  faUser: IconProp = faUser;
  faEnvelope: IconProp = faEnvelope;
  faPhone: IconProp = faPhone;
  faArrowLeft: IconProp = faArrowLeft;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  websiteJSONData: any = this.data.websiteJSONData;
  hide = true;
  hidePin = true;
  part: string = 'default';

  subscription: Subscription = new Subscription();
  profile: any = null;
  security:any = null;

  isEditable:boolean = false;

  goBack(): void {
    // this.location.back();
    if (isPlatformBrowser(this.platformId)) {
      this.prevURL = this.routerExtService.getPreviousUrl();

      if (this.prevURL === this.router.url) {
        if (this.part === 'profile' || this.part === 'pin' || this.part === 'password') {
          this.router.navigate(['/profile']);
        } else {
          this.router.navigate(['/']);
        }
      } else {
        if (this.part === 'default') {
          // console.log(this.part);
          this.router.navigate(['/']);
        }
        if (this.part === 'profile' || this.part === 'pin' || this.part === 'password') {
          this.router.navigate(['/profile']);
        }
        // } else {
        //   this.router.navigateByUrl(this.prevURL);
        // }
      }
    }
  }

  changePart(part: string) {
    this.part = part;
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: this.part } });
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.profile = this.auth.userProfileData;
        this.security = this.auth.userSecurityData;
      }
    }));
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.profile = this.auth.userProfileData;
      }
    }));

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.part = params['part'] || 'default';
      this.profile = this.auth.userProfileData;
    });
    if (isPlatformBrowser(this.platformId)) {
      this.isEditable = this.data.websiteConfigData.editProfile as boolean;
      for (const value of this.footerJSONData.records) {
        this.subscriptionData();
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
