<div class="promotion-body">
  <div class="profile-section">
    <div class="header">
      <div class="title-container">
        <div class="title">PIN KEAMANAN</div>
        <div class="subtitle">
          Gunakan PIN Keamanan unduk veirfikasi login kedalam akun anda
        </div>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Masukan Pin Lama Anda</mat-label>
          <input maxlength="6" matInput [type]="hideOldPin ? 'password' : 'text'" [(ngModel)]="pin[0]" (input)="pinInput($event)"/>
          <button
            mat-icon-button
            matSuffix
            (click)="hideOldPin = !hideOldPin"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideOldPin"
          >
            <mat-icon matSuffix>{{
              hideOldPin ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="input-item">
        <mat-form-field class="w-100">
          <mat-label>Masukan Pin Baru Anda</mat-label>
          <input maxlength="6" matInput [type]="hideNewPin ? 'password' : 'text'" [(ngModel)]="pin[1]" (input)="pinInput($event)"/>
          <button
            mat-icon-button
            matSuffix
            (click)="hideNewPin = !hideNewPin"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideNewPin"
          >
            <mat-icon matSuffix>{{
              hideNewPin ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="input-item">
        <mat-form-field class="w-100">
          <mat-label>Konfirmasi Pin Baru</mat-label>
          <input maxlength="6"matInput [type]="hideConfirmationPin ? 'password' : 'text'" [(ngModel)]="pin[2]" (input)="pinInput($event)"/>
          <button
            mat-icon-button
            matSuffix
            (click)="hideConfirmationPin = !hideConfirmationPin"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideConfirmationPin"
          >
            <mat-icon matSuffix>{{
              hideConfirmationPin ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="save-button"
    [class.disabled-div]="isSubmit"
    (click)="editPin()"
    [ngClass]="{ 'disabled-div': isSubmit }">
        @if(isSubmit) {
        <div class="spinner-border p-2 spinner-border-sm"></div>
        } @else {
        SAVE
        }
    </div>
