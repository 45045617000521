<mat-dialog-content class="popup-content-app animation-fadein">
    <div class="content-app">
        <button [mat-dialog-close] class="btn-logout">
            <fa-icon [icon]="faXmark" class="close-button" size="xl"></fa-icon>
        </button>
        <div class="content-header">
            <div class="title-text">
                Scan disini untuk Download APK
            </div>
        </div>
        <div class="image-popups">
            <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="websiteJSONData.configuration.apk_url" [width]="360" [imageHeight]="360"
                [errorCorrectionLevel]="'M'">
            </qrcode>
        </div>
        <div class="content-middle">
            <div class="title-text">
                atau Download APK Disini
            </div>
        </div>
        <div class="content-button">
            <a class="btn-image" [href]="websiteJSONData.configuration.apk_url">
                <img src="../../../template/green/assets/images/apk/desktop/download-button-android-desktop.webp">
            </a>
        </div>
    </div>
</mat-dialog-content>