
@if(currentScreenSize != 'mobile'){
<div class="page-saldo">
  <app-header-simple />
  <ng-template #toastCopy let-message="message">
    {{ message }}
  </ng-template>
  <div class="page-inner">
    <div class="container-xxl row m-auto">
      <div class="promotion-body">
      <div class="sidebar-column">
        <app-sidebar-menu [active]="'saldo'" />
      </div>
      <div class="col-9  promotion-container content-container" id="promotion-container">
        <div class=" promotion-inner h-100">
          <div class="promotion-title-custom">
            <div class="title-promotion">Saldo</div>
          </div>
          <div class="page-inner-container">
            <div class="saldo-container pt-2">
              <div class="row mb-4 mx-0">
                <div class="col-6 ps-0">
                  <mat-form-field style="width: 100%; margin-top: 1rem">
                    <mat-label>Tipe</mat-label>
                    <mat-select [(value)]="selectedType" (selectionChange)="typeChange($event)">
                      <mat-option value="">Semua</mat-option>
                      @for(item of dataType | keyvalue; track item;){
                        <mat-option value="{{item.key}}">{{item.value}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6 pe-0">
                  <mat-form-field style="width: 100%; margin-top: 1rem">
                    <mat-label>Rentang Tanggal</mat-label>
                    <mat-select [(value)]="selectedDate" (selectionChange)="dateChange($event)">
                      <mat-option value="">Semua</mat-option>
                      <mat-option value="today">Hari Ini</mat-option>
                      <mat-option value="yesterday">Kemarin</mat-option>
                      <mat-option value="weekNow">Minggu Ini</mat-option>
                      <mat-option value="weekBefore"
                      >Minggu Kemarin</mat-option>
                      <mat-option value="monthNow">Bulan Ini</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="table-promotion-container">
                <table class="table table-promotion table-responsive-promotion">
                  <thead style="border-radius: 1rem">
                    <tr>
                      <th scope="col">Tipe</th>
                      <th scope="col">Saldo Sebelum</th>
                      <th scope="col">Jumlah Transaksi</th>
                      <th scope="col">Saldo Sesudah</th>
                      <th scope="col" class="text-end">Tanggal</th>
                    </tr>
                  </thead>
                  <tbody>
                    @if(balanceHistory === null) {
                    <tr>
                      <td colspan="5" align="center">
                        <div class="spinner-border"></div>
                      </td>
                    </tr>
                    }
                    @else {
                      @if(balanceHistory.data.total_records === 0) {
                    <tr>
                      <td colspan="5" align="center">No Data</td>
                    </tr>
                    } @else { @for (item of balanceHistory.data.records; track
                    item;) {
                    <tr>
                      <td>
                        <img src="../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(item._id)"/>
                        <span>{{ functionGetType(item.type) }}</span>
                      </td>
                      <td>IDR {{ IDRFormat(item.balance_before) }}</td>
                      <td
                        [ngClass]="{
                          'negative-amount': item.balance < 0,
                          'positive-amount': item.balance > 0
                        }"
                      >
                        IDR {{item.balance < 0? '-' :''}}{{ IDRFormat(getAbs(item.balance)) }}
                      </td>
                      <td>
                        IDR
                        {{
                          IDRFormat(
                            saldoCurrent(item.balance_before, item.balance)
                          )
                        }}
                      </td>
                      <td class="text-end">
                        {{ dateFormat(item.created_at) }} <br />
                        {{ hourFormat(item.created_at) }}
                      </td>
                    </tr>
                    } } }
                  </tbody>
                </table>
                @if(balanceHistory !== null) {
                  <mat-paginator
                  #paginator
                  (page)="handlePageEvent($event)"
                  [length]="length"
                  [pageSize]="pageSize"
                  [showFirstLastButtons]="true"
                  [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                  [hidePageSize]="false"
                  [pageIndex]="pageIndex-1"
                  aria-label="Select page"
                  class="promotion-paginator">
                   </mat-paginator>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>
  </div>
  <app-footer [data]="footerData" />
</div>
} @else {
  <app-saldo-mobile></app-saldo-mobile>
}
