<div class="navigation-title">
    <div class="back-button" (click)="backButton()">
        <fa-icon [icon]="faArrowLeft" [size]="'2xl'"></fa-icon>
    </div>
    <div class="title">MASUK</div>
</div>
<div class="container-fluid login-mobile-component px-0 pt-3 pb-5">
    <div class="main-login-mobile">
        <div class="d-flex align-items-center w-100 img-login mb-5">
            <img [src]="websiteJSONData.header.logo" />
        </div>
        <form (ngSubmit)="login()" class=" w-100 mt-2">
            <div class="input-group border-bottom my-4">
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
                </span>
                <input type="text" class="form-control bg-transparent text-white border-right-0" placeholder="Nama Pengguna"
                    [(ngModel)]="username" name="username" (ngModelChange)="toUppercase($event)" aria-label="Nama Pengguna" autocomplete="off" />
            </div>
    
            <div class="input-group border-bottom mb-3">
                <span class="input-group-text bg-transparent border-0">
                    <fa-icon [icon]="faLock" class="fa-1x"></fa-icon>
                </span>
                <input type="password" class="form-control bg-transparent text-white border-right-0"
                    [type]="isPasswordVisible ? 'text' : 'password'" placeholder="Password" [(ngModel)]="password"
                    name="password" aria-label="Password" autocomplete="off" />
                <button class="btn btn-outline-secondary text-white border-0 outline-0" type="button"
                    (click)="togglePasswordVisibility()">
                    <fa-icon [icon]="isPasswordVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                </button>
            </div>
            <button id="login-button" class="fixed-bottom track-login text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile" type="submit" [disabled]="disabledLoginButton">
                masuk
            </button>
        </form>
    </div>
</div>