import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderMobileComponent, HeaderSimpleComponent, SlugNamePipe],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit{

  dataGameEvent: any;
  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    public data: DataService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      [Breakpoints.Small, 'small'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  public getGamesEvent(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesEvent().subscribe({
        next: (response) => {
          this.dataGameEvent = response;
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => { },
      });
    }
  }

  ngOnInit(): void {
    this.getGamesEvent();
  }

}
