<div class="footer">
    <div class="container-xxl m-auto">
        @if(data.en !== undefined) {
        <div class="footer-desc" [innerHTML]="data.en"></div>
        }

        <div class="text-center">
            © Copyright 2024 Gacor. All rights reserved.
        </div>
    </div>
</div>