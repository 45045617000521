<div class="container-side-menu">
  <div class="d-flex align-items-center justify-content-center pt-3 pb-3 container-menu-logo">
    <a href="/" class="text-decoration-none">
      <img class="img-sidenav" [src]="websiteJSONData.header.logo" />
    </a>
  </div>

  <!-- <div class="d-flex flex-column my-4 px-3">
    @if(currentScreenSize !== "desktop"){
    <div class="section-content">
      <div class="d-flex align-items-center gap-3">
        <img src="../template/green/assets/images/menu-dots.png" class="section-img" />
        <p class="setion-name my-auto">Menu</p>
      </div>
    </div>
    }
  </div> -->
    <div class="d-flex flex-column px-3">

      @if(currentScreenSize === "desktop"){
        <p class="section-title">SLOTS</p>
      }
      <div class="section-content">
        <div class="d-flex align-items-center gap-3" [ngClass]="{ 'active-section': activeMenu === 'SL' }" (click)="clickMenu('SL', true, '')" >
          <img src="../template/green/assets/images/menu-slots.png" class="section-img" />
          <p class="setion-name my-auto">Slots</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'JACKPOT' }"
          (click)="clickMenu('JACKPOT', false, 'SL')"
        >
          <img
            src="../template/green/assets/images/menu-jackpot.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Jackpot Slots</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'CRASH' }"
          (click)="clickMenu('CRASH', false, '')"
        >
          <img
            src="../template/green/assets/images/menu-crash.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Crash Games</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'BONANZA' }"
          (click)="clickMenu('BONANZA', false, 'SL')"
        >
          <img
            src="../template/green/assets/images/menu-bonanza.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Bonanza Games</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'MEGAWAY' }"
          (click)="clickMenu('MEGAWAY', false, 'SL')"
        >
          <img
            src="../template/green/assets/images/menu-megaway.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Megaway Games</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'BUYIN' }"
          (click)="clickMenu('BUYIN', false, 'SL')"
        >
          <img
            src="../template/green/assets/images/menu-buyin.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Buy-In Games</p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column my-4 px-3">
      @if(currentScreenSize === "desktop"){
        <p class="section-title">CASINO</p>
      }
      <div class="section-content">
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'LC' }"
          (click)="clickMenu('LC', true, '')"
        >
          <img
            src="../template/green/assets/images/menu-casino.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Live Casino</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'GAMESHOW' }"
          (click)="clickMenu('GAMESHOW', false, 'LC')"
        >
          <img
            src="../template/green/assets/images/menu-gameshow.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Live Game Shows</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'BACCARAT' }"
          (click)="clickMenu('BACCARAT', false, 'LC')"
        >
          <img
            src="../template/green/assets/images/menu-baccarat.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Live Baccarat</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'BLACKJACK' }"
          (click)="clickMenu('BLACKJACK', false, 'LC')"
        >
          <img
            src="../template/green/assets/images/menu-blackjack.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Live Blackjack</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'ROULETTE' }"
          (click)="clickMenu('ROULETTE', false, 'LC')"
        >
          <img
            src="../template/green/assets/images/menu-roulette.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Live Roulette</p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column px-3">
      @if(currentScreenSize === "desktop"){
        <p class="section-title">OTHER FUN GAMES</p>
      }
      <div class="section-content">
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'FH' }"
          (click)="clickMenu('FH', true, '')"
        >
          <img
            src="../template/green/assets/images/menu-arcade.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Arcade</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'SB' }"
          (click)="clickMenu('SB', true, '')"
        >
          <img
            src="../template/green/assets/images/menu-sportbook.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Sportbook</p>
        </div>
        <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'ES' }"
          (click)="clickMenu('ES', true, '')"
        >
          <img
            src="../template/green/assets/images/menu-esports.png"
            class="section-img"
          />
          <p class="setion-name my-auto">E-Sports</p>
        </div>
        <!-- <div
          class="d-flex align-items-center gap-3"
          [ngClass]="{ 'active-section': activeMenu === 'LT' }"
          (click)="clickMenu('LT', true, '')"
        >
          <img
            src="../template/green/assets/images/menu-sportbook.png"
            class="section-img"
          />
          <p class="setion-name my-auto">Togel</p>
        </div> -->
      </div>
    </div>

    @if(currentScreenSize === "desktop"){
      <hr class="my-4" />
      <div class="d-flex flex-column mb-4 gap-3 px-4">
        <a [routerLink]="['/promotion']" class="text-decoration-none py-2 text-white"
          >Promosi</a
        >
        <a [routerLink]="['/referral']" class="text-decoration-none py-2 text-white"
          >Referral</a
        >
        <a [routerLink]="['/statement']" class="text-decoration-none py-2 text-white"
          >Statement</a
        >
        <a [routerLink]="['/transaction']" class="text-decoration-none py-2 text-white">Transaksi</a>
        <a [routerLink]="['/deposit']" class="text-decoration-none py-2 text-white"
          >Deposit</a
        >
        <a [routerLink]="['/withdrawal']" class="text-decoration-none py-2 text-white"
          >Withdrawal</a
        >
        <a [routerLink]="['/contact']" class="text-decoration-none py-2 text-white"
          >Hubungi Kami</a
        >
        <a [routerLink]="['/providers']" class="text-decoration-none py-2 text-white">Providers</a>
      </div>
    }
</div>