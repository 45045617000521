import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { Slideshow2Component } from '@componentpath/parts/slideshow2/slideshow2.component';
import { AllGamesComponent } from '@componentpath/parts/all-games/all-games.component';
import { AllProvidersComponent } from '@componentpath/parts/all-providers/all-providers.component';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { db } from '@componentpath/db/db';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [HeaderSimpleComponent, FooterComponent, Slideshow2Component, AllGamesComponent, AllProvidersComponent],
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss'
})
export class CategoryComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private request: RequestService,
    private _Activatedroute: ActivatedRoute
  ) { }

  id: any = '';
  subscription: Subscription = new Subscription();
  slideshowJSONData: any = this.data.slideshowJSONData;
  slideshowData: any = [];
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  categoryProviderData: any = this.data.categoryproviderJSONData;
  allProvidersData: any = [];

  gameTypes: Array<any> = [''];
  gameData: Array<any> = [''];

  getGames() {
    // db.dbGamesExists().then((e) => {

    // })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 10, [''], this.gameTypes, [''], '', 'created_at', 'desc').subscribe({
        next: (v) => {
          this.gameData = v['items'];
          // db.addGames(v['items']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription = this._Activatedroute.paramMap.subscribe((params) => {
        this.id = params.get('id');
        if (this.id === '') {
          this.gameTypes = [''];
        } else if (this.id === 'slots') {
          this.gameTypes = ['SL'];
        } else if (this.id === 'live-casino') {
          this.gameTypes = ['LC'];
        } else if (this.id === 'fish-hunter') {
          this.gameTypes = ['FH'];
        } else if (this.id === 'sportbook') {
          this.gameTypes = ['SB'];
        }

        this.getGames();

        for (const value of this.categoryProviderData) {
          if (value.code === this.id) {
            this.allProvidersData = value.providers;
            break;
          }
        }
      });

      for (const value of this.slideshowJSONData.records) {
        if (value.code === 'HOMEPAGE') {
          this.slideshowData = value.contents;
          break;
        }
      }

      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.subscription) this.subscription.unsubscribe();
    }
  }
}
