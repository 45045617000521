<div class="header-tab">
    <span class="line"></span>
</div>
<!--TEMPLATE PAGE-->
@if(data.type === 'simple'){
    <div class="filter-container simple-list">
        <ul>
            @for (item of data.data.listArray; track $index) {
                <li>
                    <button type="button" (click)="selectTypeBank(item.code)">
                        <span>{{item.name}}</span>
                        @if (data.data.currentFilter === item.code) {
                            <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                        }
                    </button>
                </li>
            }
        </ul>
    </div>
}

@if(data.type === 'simple-search'){
    <div class="filter-container simple-list">
        <div class="w-100 search-create-bank">
            <input type="text" class="form-control" autocomplete="off" [(ngModel)]="inputSearch"
                (keyup)="onSearchValue($event)" placeholder="Search Bank.." name="searchBankInput"
                aria-label="Search Bank.." />
            <!-- <button type="button" class="btn">
                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
            </button> -->
        </div>
        <ul class="list-image">
            @for (item of searchedList; track $index) {
                <li>
                    <button type="button" (click)="selectCreateBank(item)">
                        <span class="img">
                            <img [src]="item['images']['small']" [alt]="item['name']">
                            <span>{{item['name'] === "OTHER BANK" ? 'BANK LAINNYA': item['name']}}</span>
                        </span>
                        @if (data.data.currentFilter._id === item._id) {
                            <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                        }
                    </button>
                </li>
            }
        </ul>
    </div>
}
@if(data.type === 'list-providers'){
    <div class="filter-container simple-list">
        <div class="w-100 search-create-bank">
            <input type="text" class="form-control" autocomplete="off" [(ngModel)]="inputSearch"
                (keyup)="onSearchValue($event)" placeholder="Search Providers.." name="searchProvidersInput"
                aria-label="Search Providers.." />
            <!-- <button type="button" class="btn">
                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
            </button> -->
        </div>
        <ul class="list-image">
            @for (item of searchedList; track $index) {
                <li>
                    <button type="button" (click)="selectCreateBank(item)">
                        <span class="img">
                            <span>{{item['name']}}</span>
                        </span>
                        @if (data.data.currentFilter._id === item._id) {
                            <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                        }
                    </button>
                </li>
            }
        </ul>
    </div>
}

@if(data.type === 'list-games'){
  <div class="list-container">
    <div class="title-filter title-filter-simple">
      Filter
    </div>
    <div class="filter-container">
      <div class="filter-name-group">
        <div>Providers</div>
        <div class="view-all" (click)="filterProviders = !filterProviders"> {{filterProviders ? 'Lihat Semua':'Sembunyikan'}}</div>
      </div>
      @if(filterProviders){
        <div class="filter-group">
          @if(!foundListProiders){
            <button class="btn-filter active">All Providers</button>
          }
          @for(item of data.data.providers; track $index){
            @if(item.checked){
              <button class="btn-filter" (click)="selectProviderGame(item)"
                [ngClass]="item.checked ? 'active': ''">{{item.name}}</button>
            }
          }
        </div>
      } @else {
        <div class="filter-group">
          @for(item of data.data.providers; track $index){
            <button class="btn-filter" (click)="selectProviderGame(item)"
            [ngClass]="item.checked ? 'active': ''">{{item.name}}</button>
          }
        </div>
        
      }

      <div class="filter-name">Category</div>
      <div class="filter-group">
        <button class="btn-filter" [ngClass]="allCategoryActive ? 'active':''" (click)="setAllCategory()">All Category</button>
        @for(item of data.data.categories; track $index){
        <button class="btn-filter title-transform" (click)="selectCategoryGame(item)"
          [ngClass]="item.checked ? 'active': ''">{{item.name}}</button>
        }
      </div>

      <!-- @if (featureList.length>0) { -->
        <div class="filter-name">Feature</div>
        <div class="filter-group">
          <button class="btn-filter" [ngClass]="allFeatureActive ? 'active':''" (click)="setAllFeature()">All Feature</button>
          @for(item of featureList; track $index){
          <button class="btn-filter" (click)="selectFeatureGame(item)"
            [ngClass]="item.checked ? 'active': ''">{{item.name}}</button>
          }
        </div>
      <!-- } -->

      <div class="filter-name">Sort By</div>
      <div class="filter-group">
        <button class="btn-filter" (click)="selectSortGame('popularity')"
          [ngClass]="currentSort === 'popularity' ? 'active': ''">Popularity</button>
        <button class="btn-filter" (click)="selectSortGame('latest')"
          [ngClass]="currentSort === 'latest' ? 'active': ''">Lastest</button>
      </div>
    </div>
  </div>
  <div class="action-container">
    <div class="reset-filter-container">
      <div class="reset-filter" (click)="resetFilterGame()">
        Hapus Filter
      </div>
    </div>
    <div class="submit-filter-container">
      <div class="submit-filter" (click)="submitFilterGame()">
        Tampilkan
      </div>
    </div>
  </div>
}

@if(data.type === 'balance' || data.type === 'transaction'){
  <div class="balance-container">
    <div class="filter-container">
      <div class="filter-name">Tipe</div>
      <div class="filter-group">
        @for(item of data.data.typeList | keyvalue:compareFn; track item;){
          <button class="btn-filter" (click)="selectButtonType(item.key)" [ngClass]="{ active: currentType == item.key }">{{item.value}}</button>
        }
      </div>
      <div class="filter-name">Rentang Tanggal</div>
      <div class="filter-group">
        @for(item of data.data.dateList | keyvalue:compareDate; track item;){
          <button class="btn-filter" (click)="selectButtonDate(item.key)" [ngClass]="{ active: currentDate == item.key }">{{item.value}}</button>
        }
      </div>
    </div>
  </div>
  <div class="action-container">
    <div class="reset-filter-container">
      <div class="reset-filter" (click)="resetFilter()">
        Hapus Filter
      </div>
    </div>
    <div class="submit-filter-container">
      <div class="submit-filter" (click)="submitFilter()">
        Tampilkan
      </div>
    </div>
  </div>
}
@if(data.type === 'statement'){
  <div class="filter-container simple-list">
    <div class="w-100 search-provider">
        <input type="text" class="form-control uppercase" autocomplete="off" [(ngModel)]="inputSearch"
            (keyup)="onSearchProvider($event)" (input)="onTyping($event)" placeholder="Search Provider" name="searchProviderInput"
            aria-label="Search Provider" />
        <!-- <button type="button" class="btn">
            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
        </button> -->
    </div>
    <ul class="list-provider">
        @for (item of filterProviderLists; track $index) {
          <li>
            <button type="button" (click)="selectProvider(item)">
                <span>{{item.name}}</span>
                @if (data.data.currentFilter.provider === item.provider) {
                    <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                }
            </button>
        </li>
        }
    </ul>
</div>
}


<!--TEMPLATE 2-->
