<swiper-container class="swiper-container" pagination="true" effect="coverflow" grab-cursor="true"
    centered-slides="true" slides-per-view="auto" coverflow-effect-rotate="0" coverflow-effect-stretch="500"
    coverflow-effect-depth="500" coverflow-effect-modifier="1.5" coverflow-effect-slide-shadows="true">
    @for (item of data.en; track item.order;) {
    <swiper-slide class="swiper-item">
        @if(item.target_url !== '' && item.target_url !== '#') {
        <a [href]="item.target_url" target="_blank">
            <img [src]="item.asset_url" [title]="item.title" />
        </a>
        } @else {
        <img [src]="item.asset_url" [title]="item.title" />
        }
    </swiper-slide>
    }
</swiper-container>