import { Component,PLATFORM_ID ,Inject,OnInit, ViewChild, ElementRef} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import dayjs from 'dayjs';
import { RequestService } from '@componentpath/services/request.service';
import { CommonModule,DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { faCaretDown, faBars, faCircleXmark,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { Location } from '@angular/common';
import { FilterMobileComponent } from '../filter-mobile/filter-mobile.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
@Component({
  selector: 'app-saldo-mobile',
  standalone: true,
  imports: [CommonModule,FontAwesomeModule],
  templateUrl: './saldo-mobile.component.html',
  styleUrl: './saldo-mobile.component.scss'
})
export class SaldoMobileComponent implements OnInit{

  subscription: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private snackBar: MatSnackBar,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    private router: Router,
  ) { }

  faCaretDown: IconProp = faCaretDown;
  faBars: IconProp = faBars;
  faCircleXmark: IconProp = faCircleXmark;
  faArrowLeft: IconProp = faArrowLeft;

  balance: number = 0;
  loadBalance: boolean = false;

  pageSize = 50;
  pageIndex = 0;
  maxPage = 0;

  balanceHistory: any = null;

  type: string = '';
  created_at: any = '';

  selectedType: string = '';
  selectedDate: string = '';

  isLoading : boolean = false;

  dataType:Record<string,any> ={
      "" : "Semua Tipe",
      "83001": "Deposit",
      "83002": "Withdrawal",
      "83004": "Withdrawal Ditolak",
      "50001": "Transfer Masuk",
      "50002": "Transfer Keluar",
      "700xx": "Seamless Play",
      "800xx": "Referral",
      "801xx": "Tournament",
      "80201": "Promosi Rollingan",
      "80202": "Promosi Cashback",
      "80203": "Promosi Deposit",
      "80204": "Promosi Airdrop",
      "80205": "Promosi Spinwheel",
  }

  dateList:Record<string,any> ={
      "": "Semua Tanggal",
      "today": "Hari Ini",
      "yesterday": "Kemarin",
      "weekNow": "Minggu Ini",
      "weekBefore": "Minggu Lalu",
      "monthNow": "Bulan Ini",
  }


  openFilter() {
    let currentFilter = {
      type: this.type,
      created_at : this.selectedDate
    }
    let dataFilter = {
      type: 'balance',
      data: {
        currentFilter: currentFilter,
        typeList: this.dataType,
        dateList : this.dateList
      }
    }
    this.bottomSheet.open(FilterMobileComponent, {
      data: dataFilter
    }).afterDismissed().subscribe((v) => {
      // console.log(v)
      this.onSelectFilter(v);
    })
  }

  clearType(){
    this.selectedType = '';
    this.type = '';
    this.balanceHistory = null;
    this.getData(1);
  }

  clearDate(){
    this.selectedDate = '';
    this.created_at = '';
    this.balanceHistory = null;
    this.getData(1);
  }

  onSelectFilter(e: any): void {
    this.type = e.type;
    this.dateChange(e.created_at);
    this.selectedDate = e.created_at;
    this.balanceHistory = null;
    this.getData(1);
  }

  goBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      // const currentUrl = window.location.href;
      // const referrerUrl = document.referrer;

      // const extractDomain = (url: string) => {
      //   try {
      //     const { hostname } = new URL(url);
      //     return hostname;
      //   } catch (e) {
      //     return null;
      //   }
      // };

      // const currentDomain = extractDomain(currentUrl);
      // const referrerDomain = extractDomain(referrerUrl);

      // if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
      //   this.location.back();
      // } else {
      //   this.router.navigate(['/']);
      // }
      this.router.navigate(['/']);
    }
  }

  onScroll(event: any) {
    this.checkScrollBottom();
  }

  checkScrollBottom() {
    const element = this.doc.getElementById('scrollableTable');
    if (!element) return;
    const isScrolledBottom = element.scrollTop + element.offsetHeight >= element.scrollHeight-5;
    if (isScrolledBottom && !this.isLoading) {
      this.loadMoreData();
    }
}


  getValueFromDateList(key: string): any {
    return this.dateList[key];
  }

  dateChange(event: any) {
    const today = dayjs().format('YYYY-MM-DD');
    const lastDay = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const startMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const endMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    const startWeek = dayjs().subtract(dayjs().day(), 'day').format('YYYY-MM-DD');
    const endWeek = dayjs().add(6 - (dayjs().day()), 'day').format('YYYY-MM-DD');
    const startPastWeek = dayjs().subtract((dayjs().day() + 7), 'day').format('YYYY-MM-DD');
    const endPastWeek = dayjs().subtract(((6 - (dayjs().day())) + 7), 'day').format('YYYY-MM-DD');
    switch (event.value) {
      case '':
        this.created_at = '';
        break;
      case 'today':
        this.created_at = { start: today, end: today };
        break;
      case 'yesterday':
        this.created_at = { start: lastDay, end: lastDay };
        break;
      case 'monthNow':
        this.created_at = { start: startMonth, end: endMonth };
        break;
      case 'weekNow':
        this.created_at = { start: startWeek, end: endWeek };
        break;
      case 'weekBefore':
        this.created_at = { start: startPastWeek, end: endPastWeek };
        break;
      default:
        this.created_at = '';
    }
  }

  loadMoreData() {
    this.isLoading = true;
    if(this.pageIndex+1 <= this.maxPage){
      this.pageIndex++;
      this.getData(this.pageIndex);
    }
  }

  saldoCurrent(saldoBefore: any, saldo: any) {
    return parseInt(saldoBefore) + parseInt(saldo);
  }

  getAbs(value: number): number {
    return Math.abs(value);
  }

  functionGetType(s:number){
    let valueType = this.dataType[s.toString()];
    if(valueType === undefined){
      valueType = s;
      if(valueType >= 70000 && valueType <= 70099){
        valueType = "Seamless Play"
      }
      if(valueType >= 80000 && valueType <= 80099){
        valueType = "Referral"
      }
      if(valueType >= 80100 && valueType <= 80199){
        valueType = "Tournament"
      }
      if(valueType >= 80201){
        valueType = "Promosi Rollingan"
      }
      if(valueType >= 80202){
        valueType = "Promosi Cashback"
      }
      if(valueType >= 80203){
        valueType = "Promosi Deposit"
      }
      if(valueType >= 80204){
        valueType = "Promosi Airdrop"
      }
      if(valueType >= 80205){
        valueType = "Promosi Spinwheel"
      }
      if(valueType == 30001){
        valueType = "Deposit"
      }
      if(valueType == 30002){
        valueType = "Withdrawal"
      }
      if(valueType == 30002){
        valueType = "Withdrawal"
      }
      if(valueType == 30003){
        valueType = "Deposit Ditolak"
      }
      if(valueType == 30004){
        valueType = "Withdrawal Ditolak"
      }
      if(valueType == 30005){
        valueType = "Deposit Diterima"
      }
      if(valueType == 30006){
        valueType = "Withdrawal Diterima"
      }
      if(valueType == 50001){
        valueType = "Transfer In"
      }
      if(valueType == 50002){
        valueType = "Transfer Out"
      }
      if(valueType == 50003){
        valueType = "Refund Transfer In"
      }
      if(valueType == 50004){
        valueType = "Refund Transfer Out"
      }
    }
    return valueType
  }

  getTypeText(s:any){
    let valueType = this.dataType[s.toString()];
    if(valueType === undefined){
      valueType = s;
      if(valueType >= 70000 && valueType <= 70099){
        valueType = "Seamless Play"
      }
      if(valueType >= 80000 && valueType <= 80099){
        valueType = "Referral"
      }
      if(valueType >= 80100 && valueType <= 80199){
        valueType = "Tournament"
      }
      if(valueType >= 80201){
        valueType = "Promosi Rollingan"
      }
      if(valueType >= 80202){
        valueType = "Promosi Cashback"
      }
      if(valueType >= 80203){
        valueType = "Promosi Deposit"
      }
      if(valueType >= 80204){
        valueType = "Promosi Airdrop"
      }
      if(valueType >= 80205){
        valueType = "Promosi Spinwheel"
      }
      if(valueType == 30001){
        valueType = "Deposit"
      }
      if(valueType == 30002){
        valueType = "Withdrawal"
      }
      if(valueType == 30002){
        valueType = "Withdrawal"
      }
      if(valueType == 30003){
        valueType = "Deposit Ditolak"
      }
      if(valueType == 30004){
        valueType = "Withdrawal Ditolak"
      }
      if(valueType == 30005){
        valueType = "Deposit Diterima"
      }
      if(valueType == 30006){
        valueType = "Withdrawal Diterima"
      }
      if(valueType == 50001){
        valueType = "Transfer In"
      }
      if(valueType == 50002){
        valueType = "Transfer Out"
      }
      if(valueType == 50003){
        valueType = "Refund Transfer In"
      }
      if(valueType == 50004){
        valueType = "Refund Transfer Out"
      }
    }
    return valueType
  }

  copyToClipboard(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(id).then(() => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'ID Key berhasil disalin', type : 'mobile-default' },
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "notification-default"
        });
      }).catch(err => {
        console.error('Failed to copy ID to clipboard', err);
      });
    }
  }

  IDRFormat(value: number) {
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);
    return Intl.NumberFormat('id-ID').format(roundedValue);
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD ');
  }

  hourFormat(value: string) {
    return dayjs(value + 'Z').format('HH:mm:ss');
  }

  getData(pageIndex : number) {
    const userProfileData: any = this.auth.userProfileData;
    let currentType = this.type;
    if (this.type === "00000" || this.type === "" ){
      currentType = '';
    }

    this.request.getBalanceHistory(this.auth.sessionValue, userProfileData._id, { type: currentType, created_at: this.created_at }, { page: pageIndex, limit: this.pageSize }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.pageIndex = pageIndex;
          this.maxPage = v.data.max_page;
          if(this.balanceHistory == null){
            this.balanceHistory = v.data.records;
          } else {
            this.balanceHistory.push(...v.data.records);
          }
          this.isLoading = false;
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          let valTrunc = Math.floor(v);
          this.balance = valTrunc;
          this.loadBalance = true;
        },
      })
    );
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.getData(1);
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}

