<div>
    @if(lobby) {
    <div class="lobby">
        <div class="container-xxl m-auto">
            <div class="lobby-inner">
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'GAMESHOW'"
                    (click)="lobbyClick('GAMESHOW')">
                    <img src="../../../assets/orianna/images/bg-lobby.png" />
                    <div class="lobby-item-inner">
                        GAMESHOW<br />
                        LOBBY
                    </div>
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'BLACKJACK'"
                    (click)="lobbyClick('BLACKJACK')">
                    <img src="../../../assets/orianna/images/bg-lobby.png" />
                    <div class="lobby-item-inner">
                        BLACKJACK<br />
                        LOBBY
                    </div>
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'ROULETTE'"
                    (click)="lobbyClick('ROULETTE')">
                    <img src="../../../assets/orianna/images/bg-lobby.png" />
                    <div class="lobby-item-inner">
                        ROULETTE<br />
                        LOBBY
                    </div>
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'BACCARAT'"
                    (click)="lobbyClick('BACCARAT')">
                    <img src="../../../assets/orianna/images/bg-lobby.png" />
                    <div class="lobby-item-inner">
                        BACCARAT<br />
                        LOBBY
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    <div class="all-games" [ngClass]="{'min-height': minHeight === true}">
        <div class="container-xxl m-auto">
            @if(filterAll === true) {
            <div class="filter">
                <div class="filter-left">
                    <span href="#" [ngClass]="{'active': type === '' }" (click)="filterAllClick('')">
                        ALL
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'slots' }" (click)="filterAllClick('slots')">
                        SLOTS
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'live-casino' }"
                        (click)="filterAllClick('live-casino')">
                        CASINO
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'fish-hunter' }"
                        (click)="filterAllClick('fish-hunter')">
                        FISH
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'sportbook' }" (click)="filterAllClick('sportbook')">
                        SPORTBOOK
                    </span>
                </div>
                <div class="filter-right">
                    <div class="input-group">
                        <span class="input-group-text">
                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                        </span>
                        <input (keyup.enter)="filterSearchChange($event)" type="text" class="form-control"
                            placeholder="Search..">
                    </div>
                </div>
            </div>
            }
            @if(filterCategory === true) {
            <div class="filter">
                <div class="filter-left">
                    <span href="#" [ngClass]="{'active': sort === 'name' }" (click)="filterSortClick('name')">
                        ALL
                    </span>
                    <span href="#" [ngClass]="{'active': sort === 'new' }" (click)="filterSortClick('new')">
                        NEW
                    </span>
                    <span href="#" [ngClass]="{'active': sort === 'popular' }" (click)="filterSortClick('popular')">
                        POPULAR
                    </span>
                </div>
                <div class="filter-right">
                    <div class="filter-right-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{providersLabel}}
                            </button>
                            <div ngbDropdownMenu>
                                @for (item of providerLists; track item;) {
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="providers.includes(item.code)"
                                        (click)="filterProviderClick(item.code)">{{item.name}}</mat-checkbox>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="filter-right-item">
                        <div class="input-group">
                            <span class="input-group-text">
                                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                            </span>
                            <input (keyup.enter)="filterSearchChange($event)" type="text" class="form-control"
                                placeholder="Search..">
                        </div>
                    </div>
                </div>
            </div>
            }
            @if(filterSearch === true) {
            <div class="filter filter-search">
                <div class="filter-left">
                    <div class="filter-left-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{gameTypesLabel}}
                            </button>
                            <div ngbDropdownMenu>
                                @for (item of typeLists; track item;) {
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="gameTypes.includes(item.code_small)"
                                        (click)="filterTypeClick(item.code_small)">{{item.name}}</mat-checkbox>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="filter-left-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{tagsLabel}}
                            </button>
                            <div ngbDropdownMenu>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('Jackpot')"
                                        (click)="filterTagClick('JACKPOT')">Jackpot</mat-checkbox>
                                </div>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('Megawheel')"
                                        (click)="filterTagClick('MEGAWHEEL')">Megawheel</mat-checkbox>
                                </div>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('Buy In')"
                                        (click)="filterTagClick('BUY IN')">Buy In</mat-checkbox>
                                </div>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('Bonanza')"
                                        (click)="filterTagClick('BONANZA')">Bonanza</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="filter-left-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{providersLabel}}
                            </button>
                            <div ngbDropdownMenu>
                                @for (item of providerListsAll; track item;) {
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="providers.includes(item.code)"
                                        (click)="filterProviderClick(item.code)">{{item.name}}</mat-checkbox>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="filter-left-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{sortByLabel}}
                            </button>
                            <div ngbDropdownMenu>
                                <div class="sort-item" (click)="filterSortClick('new')"
                                    [class.active]="sortBy === 'created_at'">Latest</div>
                                <div class="sort-item" (click)="filterSortClick('popular')"
                                    [class.active]="sortBy === 'popularity_point'">Popular</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filter-search-item">
                    <div class="filter-right-item">
                        <div class="input-group">
                            <input (keyup.enter)="filterSearchChange($event)" type="text" class="form-control"
                                placeholder="Search" [defaultValue]="searchValue">
                            <span class="input-group-text">
                                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div class="all-games-inner">
                @for (item of data; track item.id;) {
                <div class="all-games-inner-item">
                    <app-card-game [image]="item.image" [gacor]="item.gacor" [favourite]="item.favourite"
                        [name]="item.title" [provider]="item.provider_name" [percent]="item.percent"
                        [pattern]="item.pattern" [check1]="item.check1" [check2]="item.check2" [check3]="item.check3"
                        [dc]="item.dc" />
                </div>
                }
            </div>
            @if(seeAll === true) {
            <div class="see-all text-center">
                <a routerLink="/search">
                    SEE ALL <fa-icon [icon]="faAnglesRight"></fa-icon>
                </a>
            </div>
            }
            @if(loadMore === true && page < totalPages) { <div class="load-more text-center" (click)="loadMoreClick()">
                LOAD MORE <fa-icon [icon]="faAnglesRight"></fa-icon>
        </div>
        }
    </div>
</div>
</div>