import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { FooterComponent } from '@template/green/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleQuestion, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCircleCheck, faCircleChevronRight, faCircleInfo, faMagnifyingGlass, faShield, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel } from 'swiper/modules';
import { SwiperDirective } from '@componentpath/lib/swiper.directive';
import { DepositManualComponent } from '@template/green/parts/deposit-manual/deposit-manual.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DepositMobileComponent } from '@template/green/mobile/deposit-mobile/deposit-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { QRCodeModule } from 'angularx-qrcode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';

@Component({
  selector: 'app-deposit-desktop',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, HeaderSimpleComponent, FooterComponent, QRCodeModule, ReactiveFormsModule, FormsModule, FontAwesomeModule, NgbDropdownModule, CurrencyPipe, SwiperDirective, DepositManualComponent, DepositMobileComponent],
  templateUrl: './deposit-desktop.component.html',
  styleUrl: './deposit-desktop.component.scss'
})
export class DepositDesktopComponent {

  subscription: Subscription = new Subscription();

  faCircleQuestion = faCircleQuestion;
  faCircleChevronRight = faCircleChevronRight;
  faTrashCan = faTrashCan;
  faCircleInfo = faCircleInfo;
  faMagnifyingGlass = faMagnifyingGlass;
  faCircleCheck = faCircleCheck;
  faCheck = faCheck;
  faShield = faShield;
  faXmark = faXmark;

  userProfile: any;
  currentOptionPayment: string = 'auto';
  currentMethodPayment: string = 'vacc';
  nextStepOption: boolean = false;
  showFormPaymentAuto: boolean = false;
  showFormPaymentManual: boolean = false;
  showConfirmPayment: boolean = false;
  successDeposit: boolean = false;
  hideSwitchMenu: boolean = false;
  inputAmount: any = null;
  currentNominal: number = 0;
  userGroupData: Record<string, any> = {};
  bankCompanyList: Record<string, any> = {};
  depositConfig: Record<string, any> = {};
  loader: boolean = true;

  currentSelectPaymentType: string = 'all';
  balance: any = undefined;
  promoList: Record<string, any>[] = [];
  searchPromo = new FormControl('', []);
  promoSearch: Record<string, any>[] = [];
  currentPromo: Record<string, any> = {};
  selectedPromo: Record<string, any> = {};
  showPromo: boolean = false;
  showNotifText: string = '';
  showNotif: boolean = false;
  showError: boolean = false;
  disabledButton: boolean = true;
  disabledButtonSub: boolean = false;
  inProgress: boolean = false;
  autoData: any;
  qrCodeDownloadLink: SafeUrl = '';
  websiteJSONData: any = this.data.websiteJSONData;
  timeCountSecond: number = 5;
  isCorrectValue: number = 0;
  intervalStatus: any;


  public minutes: number = 0;
  public seconds: number = 0;
  public minutesShow: string = '';
  public secondsShow: string = '';
  private intervalId: any;

  arrayNominal: Array<number> = [10000, 20000, 50000, 100000, 500000, 1000000, 5000000, 10000000];

  public config: SwiperOptions = {
    init: true,
    modules: [A11y, Mousewheel, Grid],
    slidesPerView: 6,
    grabCursor: false,
    loop: false,
    mousewheel: true,
    freeMode: true,

  };

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private requestService: RequestService,
    private router: Router,
    private route: ActivatedRoute,
    private request: RequestService,
    private snackBar: MatSnackBar
  ) {}

  nextButtonOption() {
    if (this.currentOptionPayment === 'auto') {
      if (this.depositConfig['virtual_account'] && this.depositConfig['qris']) {
        this.nextStepOption = true;
      } else {
        // this.checkActiveDepositAuto();
        this.showFormPaymentAuto = true;
      }
    } else {
      this.showFormPaymentManual = true;
    }
  }

  nextButtonMethod() {
    this.showFormPaymentAuto = true;
  }

  selectOptionPayment(val: string) {
    this.currentOptionPayment = val;
    this.showConfirmPayment = false;
  }

  selectMethodPayment(val: string) {
    this.currentMethodPayment = val;
    this.showConfirmPayment = false;
    // this.checkActiveDepositAuto();
  }

  setNominalChip(val: number) {
    this.currentNominal = val;
    this.inputAmount = this.currentNominal;
    this.disabledButton = false;
    this.isCorrectValue = val % 1000;
  }

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    this.isCorrectValue = this.inputAmount % 1000;
    this.setNominalChip(this.inputAmount);
    if (Number.isNaN(this.inputAmount)) {
      console.log(this.inputAmount)
      this.disabledButton = true;
      this.isCorrectValue = 0;
    } else {
      if(this.inputAmount < 10000 || this.inputAmount > 10000000){
        this.isCorrectValue = 1;
        this.disabledButton = true;
      } else{
        this.disabledButton = false;
      }
    }
  }

  confirmDeposit() {
    this.setDepositAuto();
  }

  selectPromo() {
    this.showNotif = false;
    this.showPromo = false;
    if (this.selectedPromo['_id']) {
      this.currentPromo = this.selectedPromo;
    }
  }

  openPromoSearch() {
    this.showNotif = false;
    this.showPromo = true;
  }

  resetPromo() {
    this.selectedPromo = {};
    this.currentPromo = {};
  }

  resetPromoBack() {
    if (this.currentPromo['_id']) {
      this.showPromo = false;
    } else {
      this.selectedPromo = {};
      this.showPromo = false;
    }
  }

  getPromoList() {
    this.requestService.getPromoList().subscribe({
      next: (v) => {
        // console.log(v);
        const value = v as Record<string, any>;
        if (value['data']) {
          this.promoList = value['data'];
          this.promoSearch = this.promoList;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  inputSearchPromo() {
    let codePromo = this.searchPromo.value;
    if (codePromo !== '' && codePromo !== undefined && codePromo !== null) {
      this.requestService.getPromoCode(codePromo).subscribe({
        next: (v) => {
          // console.log(v);
          const value = v as Record<string, any>;
          this.promoSearch = [];
          this.promoSearch.push(value['data']);
        },
        error: (e) => {
          console.log(e);
          this.showNotif = true;
          this.showNotifText = e.error.messages[0].message;
        },
        complete: () => { }
      })
    } else {
      this.promoSearch = this.promoList;
    }
  }

  getBankCompany() {
    this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
      next: (val) => {
        // console.log(val);
        if (val['code'] === 200) {
          if (val['data'][this.userGroupData['rank']] !== undefined) {
            this.bankCompanyList = val['data'][this.userGroupData['rank']];
          } else {
            this.bankCompanyList = val['data'][1];
          }
          // console.log(this.bankCompanyList);
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          let valTrunc = Math.floor(v);
          this.balance = valTrunc;
        },
      })
    );
    if (this.currentScreenSize === 'desktop') {
      this.subscription.add(this.route.queryParams.subscribe((params) => {
        if (params['p'] === '2') {
          this.hideSwitchMenu = true;
          if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
            this.router.navigate(['/deposit'])
          }
        } else {
          this.hideSwitchMenu = false;
          if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
            this.router.navigate(['/deposit'])
          }
        }
      }));
    }
  }

  getDepositConfig() {
    this.request.getDepositConfig(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.depositConfig = val['data'] as Record<string, any>;
          // this.depositConfig['status'] = false;
          // this.depositConfig['qris'] = true;
          // this.depositConfig['virtual_account'] = true;
          if (this.depositConfig['status'] === false) {
            this.showFormPaymentManual = true;
            this.loader = false;
            this.currentOptionPayment = "manual";
          } else {
            this.checkActiveDepositAuto();
          }
          if ((this.depositConfig['virtual_account'] && !this.depositConfig['qris'])) {
            this.currentMethodPayment = 'vacc';

          }
          if (!this.depositConfig['virtual_account'] && this.depositConfig['qris']) {
            this.currentMethodPayment = 'qris'

          }
          // this.loader =false;
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  setDepositAuto() {
    if (this.isCorrectValue === 0 && this.inputAmount >= 10000 && this.inputAmount <= 10000000 ) {
      this.disabledButton = true;
      this.inProgress = true;
      let promoID = '';
      let promoType = '';
      if (this.currentPromo['_id']) {
        promoID = this.currentPromo['_id'];
        promoType = this.currentPromo['type'];
      }
      this.request.setTransactionAutoDeposit(this.auth.sessionValue, 'qris', this.inputAmount, promoType, promoID).subscribe({
        next: (val) => {
          if (val['code'] === 200) {
            console.log(val);
            this.autoData = val['data'];
            this.autoData['amount'] = parseInt(this.autoData['amount'])
            this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);
            this.showConfirmPayment = true;
            this.countTime();
            this.checkIntervalStatusDeposit();
            this.inProgress = false;
          }
        },
        error: (e) => {
          console.log(e);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: e.error.messages[0].message, type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
          this.disabledButton = false;
          this.inProgress = false;
        },
        complete: () => { }
      })
    } else {
      let messageError = 'Nominal Harus Kelipatan 1.000';
      if(this.inputAmount < 10000){
        messageError = "Minimal Jumlah Deposit Rp 10.000";
      }
      if(this.inputAmount > 10000000){
        messageError = "Maksimum Jumlah Deposit Rp 10.000.000";
      }
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: messageError, type: 'error' },
        duration: 3000,
        verticalPosition: 'top',
        panelClass: "notification-error"
      });
    }
  }

  backToMainDeposit() {
    this.inputAmount = undefined;
    this.currentNominal = 0;
    this.nextStepOption = false
    this.showFormPaymentAuto = false;
    this.showFormPaymentManual = false;
    this.showError = false;
    this.showPromo = false;
  }

  cancelAutoPayment() {
    this.disabledButtonSub = true;
    this.request.cancelTransactionAutoDetail(this.auth.sessionValue, this.autoData['_id']).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          // this.checkActiveDepositAuto();
          this.backToMainDeposit();
          this.checkActiveDepositAuto();
          this.disabledButtonSub = false;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  checkActiveDepositAuto() {
    this.loader = true;
    this.hideSwitchMenu = true;
    if (this.intervalStatus) {
      clearInterval(this.intervalStatus)
    }
    this.request.checkActiveTransactionAuto(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          if (val['data']) {
            this.autoData = val['data'];
            this.autoData['amount'] = parseInt(this.autoData['amount']);
            this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);

            this.showFormPaymentAuto = true;
            this.showConfirmPayment = true;
            this.checkIntervalStatusDeposit();
            this.countTime();
          } else {
            this.showConfirmPayment = false;
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
          }
          this.hideSwitchMenu = false;
          this.loader = false;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
      },
      complete: () => { }
    })
  }

  checkStatusDepositAuto() {
    this.disabledButtonSub = true;
    this.request.checkTransactionAuto(this.auth.sessionValue, this.autoData['_id']).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.disabledButtonSub = false
          if (val['data']['status'] === 2) {
            this.successDeposit = true;
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
            this.countDown();
          }
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  countDown() {
    if (isPlatformBrowser(this.platformId)) {

      var x = setInterval(() => {
        this.timeCountSecond = this.timeCountSecond - 1;

        if (this.timeCountSecond < 0) {
          this.timeCountSecond = 0;
          clearInterval(x);
          this.timeCountSecond = 5;
          this.router.navigate(['/transaction'])
        }
      }, 1000);
    }
  }

  checkIntervalStatusDeposit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intervalStatus = setInterval(() => {
        this.request.checkTransactionAuto(this.auth.sessionValue, this.autoData['_id']).subscribe({
          next: (val) => {
            if (val['code'] === 200) {
              // console.log(val);
              if (val['data']['status'] === 2) {
                this.successDeposit = true;
                if (this.intervalStatus) {
                  clearInterval(this.intervalStatus);
                }
                this.countDown();
              }
            }
          },
          error: (e) => {
            console.log(e);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: e.error.messages[0].message, type: 'error' },
              duration: 3000,
              verticalPosition: 'top',
              panelClass: "notification-error"
            });
            this.disabledButtonSub = false
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
          },
          complete: () => { }
        })
      }, 15000);
    }
  }

  goToTransaction() {
    this.router.navigate(['/transaction']);
  }

  countTime() {
    dayjs.extend(utc);
    const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
    const time2 = dayjs(this.autoData.paymentgateway_expired_at, 'YYYY-MM-DD HH:mm:ss');
    const diffInSeconds = time2.diff(time1, 'second');
    this.minutes = Math.floor(diffInSeconds / 60);
    this.seconds = diffInSeconds % 60;
    this.startCountdown();
  }

  checkCountdown() {
    this.minutesShow = String(this.minutes).padStart(2, '0');
    this.secondsShow = String(this.seconds).padStart(2, '0');
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      dayjs.extend(utc);
      const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
      const time2 = dayjs(this.autoData.paymentgateway_expired_at, 'YYYY-MM-DD HH:mm:ss');
      const diffInSeconds = time2.diff(time1, 'second');
      this.minutes = Math.floor(diffInSeconds / 60);
      this.seconds = diffInSeconds % 60;

      if (this.seconds <= 0) {
        if (this.minutes <= 0) {
          this.stopCountdown();
        } else {
          this.minutes--;
          this.seconds = 59;
          this.checkCountdown();
        }
      } else {
        this.seconds--;
        this.checkCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    this.minutesShow = "00";
    this.secondsShow = "00";
    this.showError = true;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.intervalStatus) {
      clearInterval(this.intervalStatus);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getBankCompany();
      this.subscriptionData();
      this.getDepositConfig();
      this.getPromoList();
      // console.log(this.auth.userGorupData);
      this.userGroupData = this.auth.userGorupData;
      this.userProfile = this.auth.userProfileData;
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      this.stopCountdown();
    }
  }
}
