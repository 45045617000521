import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {Component, Input, PLATFORM_ID, Inject, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faMagnifyingGlass, faAnglesRight, faCaretDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '../card-game/card-game.component';
import { Params, QueryParamsHandling, RouterModule } from '@angular/router';
import { db } from '@componentpath/db/db';
import { RequestService } from '@componentpath/services/request.service';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FilterMobileComponent } from '@template/green/mobile/filter-mobile/filter-mobile.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';

@Component({
  selector: 'app-all-games',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CardGameComponent,
    NgbDropdownModule,
    MatCheckboxModule,
    FormsModule,
    NgbCollapseModule,
    SlugNamePipe
  ],
  templateUrl: './all-games.component.html',
  styleUrl: './all-games.component.scss',
})
export class AllGamesComponent {
  @ViewChild('scrollAnchor', { static: false }) scrollAnchor!: ElementRef;
  subscription: Subscription = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.checkScreen();
  }

  @Input() filterAll: boolean = true;
  @Input() filterCategory: boolean = false;
  @Input() filterSearch: boolean = false;
  @Input() seeAll: boolean = true;
  @Input() loadMore: boolean = false;
  @Input() lobby: boolean = false;
  @Input() minHeight: boolean = false;

  @Input() page: number = 1;
  @Input() perPage: number = 30;
  @Input() providers: Array<any> = [];
  @Input() provider: boolean = false;
  @Input() gameTypes: Array<any> = [];
  @Input() tags: Array<any> = [];
  @Input() searchValue: string = '';
  @Input() data: Array<any> = [];

  qParams: Params = {};
  gameTypesLabel: string = '';
  tagsLabel: string = 'All Features';
  providersLabel: string = '';
  sortByLabel: string = '';

  currentPath: string = '';
  totalPages: number = 0;
  type: string = '';
  typeLists: Array<Record<string, any>> = [];
  sortBy: string = 'popularity';
  categoryproviderJSONData: any = [];
  providerLists: Array<any> = [];
  providerListsAll: Array<any> = [];
  websiteJSONData: any = this.dataService.websiteJSONData;

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faAnglesRight: IconProp = faAnglesRight;
  faCircleXmark: IconProp = faCircleXmark;

  selectedOptions: any[] = [];
  searchedOptions: any[] = [];
  searchGameInput: string = '';
  searchGameInputCategories: string = '';
  selectedOption: string = '';
  selectedFeature: string = '';

  searchInputProvider: string = '';
  searchGameInputProvider: string = '';
  selectedOptionsProvider: any[] = [];
  searchedOptionsProvider: any[] = [];

  selectedFilter: any;
  listFilter: any[] = [
    {
      name: 'ALL FEATURES',
      code: 'all',
      bg_url: '../../../../template/green/assets/images/all-feat.png',
    },
    {
      name: 'JACKPOT GAMES',
      code: 'jackpot',
      bg_url: '../../../../template/green/assets/images/all-feat.png',
    },
    {
      name: 'BUY-IN GAMES',
      code: 'buy',
      bg_url: '../../../../template/green/assets/images/all-feat.png',
    },
    {
      name: 'BONANZA GAMES',
      code: 'bonanza',
      bg_url: '../../../../template/green/assets/images/all-feat.png',
    },
    {
      name: 'MEGAWAYS GAMES',
      code: 'megaways',
      bg_url: '../../../../template/green/assets/images/all-feat.png',
    },
  ];
  preload: boolean = true;

  currentScreenSize: string = 'desktop';

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      [Breakpoints.Small, 'small'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  clickFilter(selected: any) {
    if (this.selectedFilter === selected) {
      this.selectedFilter = null;
    } else {
      this.selectedFilter = selected;
    }
  }

  clearCategory(){
    // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "categories":null, filter: null }, queryParamsHandling: 'merge' });
    this.router.navigate(['/search'], { queryParams: { "categories":null, filter: null }, queryParamsHandling: 'merge' });
  }
  clearTag(){
    // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "tags":null, filter: null }, queryParamsHandling: 'merge' });
    this.router.navigate(['/search'], { queryParams: { "tags":null, filter: null }, queryParamsHandling: 'merge' });
  }
  clearProvider(){
    // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "providers":null, filter: null }, queryParamsHandling: 'merge' });
    this.router.navigate(['/search'], { queryParams: { "providers":null, filter: null }, queryParamsHandling: 'merge' });
  }

  openFilter(): void {

    let providers: Array<any> = this.searchedOptionsProvider.map(u => ({ ...u}));
    let categories: Array<any> = this.searchedOptions.map(u => ({ ...u }));
    let feature: Array<any> = this.tags;

    let dataFilter = {
      type: 'list-games',
      data: {
        providers:providers,
        categories: categories,
        feature:feature,
        sortBy: this.sortBy
      }
    }
    this.bottomSheet.open(FilterMobileComponent, {
      data:dataFilter,
      scrollStrategy: new NoopScrollStrategy()
    }).afterDismissed().subscribe((v) => {
      if (v !== undefined) {
        // console.log(v)
        // console.log(v.categories[0])
        // console.log(v.providers[0])
        // console.log(v.feature)
        // console.log(v.dataFeatureTrue)
        // console.log(v.sortBy)
        if (v.dataFeatureTrue.length > 0){
          this.selectedFeature = v.dataFeatureTrue[0].name;
        }
        // if(v.feature.length === 0){
        //   v.feature = ['']
        // }
        // console.log(v.feature)
        if(v.categories[0]==='' && v.providers[0]==='' &&  v.feature[0]==='' && v.sortBy === ''){
          this.router.navigate(['/search']);
        }else {
          let arrayProvidersString = encodeURIComponent(JSON.stringify(v.providers));
          let arrayCategoriesString = encodeURIComponent(JSON.stringify(v.categories));
          let arrayFeatureString = encodeURIComponent(JSON.stringify(v.feature));
          // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "providers": arrayProvidersString, "categories": arrayCategoriesString, "tags": arrayFeatureString, "sort":v.sortBy}, queryParamsHandling: 'merge' });
          this.router.navigate(['/search'], { queryParams: { "providers": arrayProvidersString, "categories": arrayCategoriesString, "tags": arrayFeatureString, "sort":v.sortBy}, queryParamsHandling: 'merge' });
        }
      }
    })
  }

  // showAllGames() {
  //   if (this.isCollapsed) {
  //     this.isCollapsedProvider = true;
  //     this.isCollapsedFilter = true;
  //   }
  //   this.isCollapsed = !this.isCollapsed;
  // }

  // showAllProvider() {
  //   if (this.isCollapsedProvider) {
  //     this.isCollapsed = true;
  //     this.isCollapsedFilter = true;
  //   }
  //   this.isCollapsedProvider = !this.isCollapsedProvider;
  // }

  // showFilter() {
  //   if (this.isCollapsedFilter) {
  //     this.isCollapsed = true;
  //     this.isCollapsedProvider = true;
  //   }
  //   this.isCollapsedFilter = !this.isCollapsedFilter;
  // }

  // searchItemProvider() {
  //   if (this.searchInputProvider) {
  //     this.router.navigate(['/search'], {
  //       queryParams: { searchValue: this.searchInputProvider },
  //     });
  //   } else {
  //     this.router.navigate(['/search']);
  //   }
  // }

  onSearchDropdownValue(event: Event): void {
    const input = this.searchGameInputCategories.toLowerCase();
    this.searchedOptions = this.typeLists.filter((option) =>
      option['name'].toLowerCase().includes(input)
    );

    if (!input) {
      this.searchedOptions = [...this.typeLists];
    }
  }

  onSelectDropdownValue(e: any): void {
    let categoryCode = e.target.id as string;
    let arrayCategories: Array<string> = [];
    if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'provider' || this.route.snapshot.url[0].path === 'real-money') {
      if (this.route.snapshot.paramMap.get('type')) {
        let type = this.route.snapshot.paramMap.get('type')
        if (type === 'livecasino' || type === 'live-casino') {
          type = 'LC';
        } else if (type === 'shooting' || type === 'arcade') {
          type = 'FH'
        } else if (type === 'sportbook') {
          type = 'SB'
        } else if (type === 'esport') {
          type = 'ES'
        } else if (type === 'crash' || type == 'crash-game') {
          type = 'CR'
        } else {
          type = 'SL'
        }
        arrayCategories.push(type)
      }
    }
    if (this.qParams['categories']) {
      let currentCategoriesVal = JSON.parse(decodeURIComponent(this.qParams['categories']));
      for (let i = 0; i < currentCategoriesVal.length; i++) {
        arrayCategories.push(currentCategoriesVal[i]);
      }
    }

    let paramHandling: QueryParamsHandling = "merge";

    if (e.target.checked) {
      arrayCategories.push(categoryCode);
      let arrayCategoriesString = encodeURIComponent(JSON.stringify(arrayCategories));
      let valueParams = {};
      if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'provider' || this.route.snapshot.url[0].path === 'real-money') {
        if (this.route.snapshot.paramMap.get('name')) {
          let slugProvider = new SlugNamePipe();
          // console.log(this.providerListsAll)
          let providerIndex = this.providerListsAll.findIndex((x: any) => slugProvider.transform(x.name) === this.route.snapshot.paramMap.get('name'));
          // console.log(this.providerListsAll[providerIndex])
          // this.providers = [this.providerListsAll[providerIndex]['code']];
          valueParams = {
            "providers": encodeURIComponent(JSON.stringify([this.providerListsAll[providerIndex]['code']])),
            "categories": arrayCategoriesString,
            filter: null
          }
        } else {
          valueParams = {
            "categories": arrayCategoriesString,
            filter: null
          }
        }
      } else {
        valueParams = {
          "categories": arrayCategoriesString,
          filter: null
        }
      }
      // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "categories": arrayCategoriesString, filter: null }, queryParamsHandling: paramHandling });
      this.router.navigate(['/search'], { queryParams: valueParams, queryParamsHandling: paramHandling });
    } else {
      const index = arrayCategories.indexOf(categoryCode);
      if (index > -1) {
        arrayCategories.splice(index, 1);
        // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "categories": encodeURIComponent(JSON.stringify(arrayCategories)), filter: null }, queryParamsHandling: paramHandling });
        this.router.navigate(['/search'], { queryParams: { "categories": encodeURIComponent(JSON.stringify(arrayCategories)), filter: null }, queryParamsHandling: paramHandling });
      }
    }
  }

  setCheckCategories() {
    if (this.qParams['categories']) {
      let currentCategoriesVal = JSON.parse(decodeURIComponent(this.qParams['categories']))

      this.selectedOptions = currentCategoriesVal;
      for (let i = 0; i < this.searchedOptions.length; i++) {
        this.searchedOptions[i]["checked"] = false;
      }
      if (currentCategoriesVal.length > 0) {
        for (let i = 0; i < currentCategoriesVal.length; i++) {
          let index = this.searchedOptions.findIndex((x: any) => x.code_small === currentCategoriesVal[i]);
          this.gameTypesLabel = this.searchedOptions[index]["title"]["en"];
          this.searchedOptions[index]["checked"] = true;
        }

      } else {
        for (let i = 0; i < this.searchedOptions.length; i++) {
          this.searchedOptions[i]["checked"] = false;
        }
      }
    } else {
      this.selectedOptions = [];
      for (let i = 0; i < this.searchedOptions.length; i++) {
        this.searchedOptions[i]["checked"] = false;
      }
    }
  }

  onSearchDropdownValueProvider(event: Event): void {
    const input = this.searchGameInputProvider.toLowerCase();
    if (!input) {
      this.searchedOptionsProvider = this.providerListsAll;
    } else {
      this.searchedOptionsProvider = this.providerListsAll.filter((option) =>
        option.name.toLowerCase().includes(input)
      );
    }
  }

  onSelectDropdownValueProvider(e: any): void {
    const providersID = e.target.id as string;
    let arrayProviders: Array<string> = [];
    // console.log(this.route.snapshot.url)
    // console.log(this.route.snapshot.paramMap.get('type'));
    // console.log(this.route.snapshot.paramMap.get('name'));
    if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'provider' || this.route.snapshot.url[0].path === 'real-money') {
      if (this.route.snapshot.paramMap.get('name')) {
        let slugProvider = new SlugNamePipe();
        // console.log(this.providerListsAll)
        let providerIndex = this.providerListsAll.findIndex((x: any) => slugProvider.transform(x.name) === this.route.snapshot.paramMap.get('name'));
        // console.log(this.providerListsAll[providerIndex])
        // this.providers = [this.providerListsAll[providerIndex]['code']];
        arrayProviders.push(this.providerListsAll[providerIndex]['code'])
      }
    }

    if (this.qParams['providers']) {
      let currentProvidersVal = JSON.parse(decodeURIComponent(this.qParams['providers']));
      for (let i = 0; i < currentProvidersVal.length; i++) {
        arrayProviders.push(currentProvidersVal[i]);
      }
    }

    let paramHandling: QueryParamsHandling = "merge";

    if (e.target.checked) {
      arrayProviders.push(providersID);
      let arrayProvidersString = encodeURIComponent(JSON.stringify(arrayProviders));
      let valueParams = {};
      // console.log(this.route.snapshot.url[0].path)
      if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'provider' || this.route.snapshot.url[0].path === 'real-money') {
        if (this.route.snapshot.paramMap.get('type')) {
          let type = this.route.snapshot.paramMap.get('type')
          if (type === 'livecasino' || type === 'live-casino') {
            type = 'LC';
          } else if (type === 'shooting' || type === 'arcade') {
            type = 'FH'
          } else if (type === 'sportbook') {
            type = 'SB'
          } else if (type === 'esport') {
            type = 'ES'
          } else if (type === 'crash' || type == 'crash-game') {
            type = 'CR'
          } else {
            type = 'SL'
          }
          valueParams = {
            "categories": encodeURIComponent(JSON.stringify([type])),
            "providers": arrayProvidersString
          }
        }else {
          valueParams = {
            "providers": arrayProvidersString
          }
        }
      }else {
        valueParams = {
          "providers": arrayProvidersString
        }
      }
      // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "providers": arrayProvidersString }, queryParamsHandling: paramHandling });
      this.router.navigate(['/search'], { queryParams: valueParams, queryParamsHandling: paramHandling });
    } else {
      const index = arrayProviders.indexOf(providersID);
      if (index > -1) {
        arrayProviders.splice(index, 1);
        // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "providers": encodeURIComponent(JSON.stringify(arrayProviders)) }, queryParamsHandling: paramHandling });
        this.router.navigate(['/search'], { queryParams: { "providers": encodeURIComponent(JSON.stringify(arrayProviders)) }, queryParamsHandling: paramHandling });
      }
    }
  }

  setCheckProviders() {
    if (this.qParams['providers']) {
      let currentProvidersVal = JSON.parse(decodeURIComponent(this.qParams['providers']));
      this.selectedOptionsProvider = currentProvidersVal;
      for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
        this.searchedOptionsProvider[i]["checked"] = false;
      }
      if (currentProvidersVal.length > 0) {
        for (let i = 0; i < currentProvidersVal.length; i++) {
          let index = this.searchedOptionsProvider.findIndex((x: any) => x.code === currentProvidersVal[i]);
          this.providersLabel = this.searchedOptionsProvider[index]["name"];
          this.searchedOptionsProvider[index]["checked"] = true;
        }
      } else {
        // console.log('providers')
        for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
          this.searchedOptionsProvider[i]["checked"] = false;
        }
      }
    } else {
      this.selectedOptionsProvider = [];
      for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
        this.searchedOptionsProvider[i]["checked"] = false;
      }
    }
  }

  // updateQueryParams() {
  //   if (this.currentPath === 'search') {
  //     let queryParams: any = {};
  //     if (this.gameTypes.length > 0 && this.gameTypes[0] !== '') {
  //       queryParams['type'] = JSON.stringify(this.gameTypes);
  //     }
  //     if (this.tags.length > 0 && this.tags[0] !== '') {
  //       queryParams['tags'] = JSON.stringify(this.tags);
  //     }
  //     if (this.providers.length > 0 && this.providers[0] !== '') {
  //       queryParams['provider'] = JSON.stringify(this.providers);
  //     }
  //     if (this.sortBy !== '') {
  //       queryParams['sort'] = this.sortBy;
  //     }
  //     if (this.searchValue !== '') {
  //       queryParams['searchValue'] = this.searchValue;
  //     }
  //     this.router.navigate(['/search'], { queryParams: queryParams });
  //   }
  //   this.updateLabel();
  // }

  updateLabel() {
    if (this.sortBy === 'created_at' || this.sortBy === 'latest') {
      this.sortByLabel = 'Latest';
    } else {
      this.sortByLabel = 'Popularity';
    }
  }

  getGames(
    reset: boolean = false,
    providers: Array<any>,
    searchValue: string,
    gameTypes: Array<string>,
    tags: Array<string>
  ) {
    // db.dbGamesExists().then((e) => {});
    if (isPlatformBrowser(this.platformId)) {
      let providerList = [''];
      let valueSort: string = '' ;

      if(this.sortBy === 'latest'){
        valueSort = 'created_at'
      } else if(this.sortBy === 'popularity'){
        valueSort = 'popularity_point'
      } else {
        valueSort = 'popularity_point'
      }

      for (const value of providers) {
        for (const value2 of this.providerListsAll) {
          if (value === value2.code) {
            if (providerList[0] === '') {
              providerList = [];
            }
            providerList.push(value2._id);
            break;
          }
        }
      }

      if(reset){
        this.page = 1;
      }

      this.request.gamesJSON(
          this.page,
          this.perPage,
          providerList.length ? providerList : [''],
          gameTypes.length ? gameTypes : [''],
          tags.length ? tags : [''],
          searchValue,
          valueSort,
          'desc'
        )
        .subscribe({
          next: (v) => {
            if (reset) {
              this.data = v['items'];
            } else {
              this.data = this.data.concat(v['items']);
            }
            this.totalPages = v['totalPages'];
            // db.addGames(v['items']);
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {
            this.preload = false;
          },
        });
    }
  }

  filterSortClick(value: string) {
    this.sortBy = value;
    let valueParams :any = {};
    if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'provider' || this.route.snapshot.url[0].path === 'real-money'){
      if (this.route.snapshot.paramMap.get('type')) {
        let type = this.route.snapshot.paramMap.get('type')
        if (type === 'livecasino' || type === 'live-casino') {
          type = 'LC';
        } else if (type === 'shooting' || type === 'arcade') {
          type = 'FH'
        } else if (type === 'sportbook') {
          type = 'SB'
        } else if (type === 'esport') {
          type = 'ES'
        } else if (type === 'crash' || type == 'crash-game') {
          type = 'CR'
        } else {
          type = 'SL'
        }
        valueParams.categories = encodeURIComponent(JSON.stringify([type]))
      }
    
    
    if (this.route.snapshot.paramMap.get('name')){
      let slugProvider = new SlugNamePipe();
      // console.log(this.providerListsAll)
      let providerIndex = this.providerListsAll.findIndex((x: any) => slugProvider.transform(x.name) === this.route.snapshot.paramMap.get('name'));
      // console.log(this.providerListsAll[providerIndex])
      this.providers = [this.providerListsAll[providerIndex]['code']];
      valueParams.providers = encodeURIComponent(JSON.stringify([this.providerListsAll[providerIndex]['code']]));
      }
    }
    valueParams.sort = value.toLowerCase();
    this.router.navigate(['/search'], { queryParams: valueParams, queryParamsHandling: 'merge' });
    // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "sort": value.toLowerCase() }, queryParamsHandling: 'merge' });
  }

  filterSearchChange(event: any) {
    this.searchValue = this.searchGameInput;
    this.router.navigate(['search'], { queryParams: { "value": this.searchValue.toLowerCase() }, queryParamsHandling: 'merge' });
    // this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "value": this.searchValue.toLowerCase() }, queryParamsHandling: 'merge' });
  }

  private updateFiltersFromQueryParams(params: any): void {
    if(params['categories']){
      this.gameTypes = JSON.parse(decodeURIComponent(params['categories']));
    } else {
      this.gameTypes = [''];
    }
    if(params['tags']){
      this.tags = JSON.parse(decodeURIComponent(params['tags']));
    } else {
      this.tags = ['']
    }
    if (params['providers']){
      this.providers = JSON.parse(decodeURIComponent(params['providers']));
    } else {
      this.providers = ['']
    }
    if(params['sort']){
      this.sortBy = params['sort'];
    } else {
      this.sortBy = 'popularity'
    }
    if (params['value']){
      this.searchValue = params['value'];
      this.searchGameInput = params['value'];
    } else{
      this.searchValue = '';
      this.searchGameInput = '';
    }

    this.getGames(true, this.providers, this.searchValue, this.gameTypes, this.tags);
  }

  getParamsValue(){
    // console.log(this.route.snapshot.url)
    // console.log(this.route.snapshot.paramMap.get('type'));
    // console.log(this.route.snapshot.paramMap.get('name'));
    if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'provider' || this.route.snapshot.url[0].path === 'real-money'){
      if (this.route.snapshot.paramMap.get('type')) {
        let type = this.route.snapshot.paramMap.get('type')
        if (type === 'livecasino' || type === 'live-casino') {
          type = 'LC';
        } else if (type === 'shooting' || type === 'arcade') {
          type = 'FH'
        } else if (type === 'sportbook') {
          type = 'SB'
        } else if (type === 'esport') {
          type = 'ES'
        } else if (type === 'crash' || type == 'crash-game') {
          type = 'CR'
        } else {
          type = 'SL'
        }
        this.gameTypes = [type];
        this.checkTypeParam(type);
      }
      
      this.searchValue = '';
      this.searchGameInput = '';
      this.tags = [''];
      this.sortBy = 'popularity';
      
      if (this.route.snapshot.paramMap.get('name')){
        let slugProvider = new SlugNamePipe();
        // console.log(this.providerListsAll)
        let providerIndex = this.providerListsAll.findIndex((x: any) => slugProvider.transform(x.name) === this.route.snapshot.paramMap.get('name'));
        // console.log(this.providerListsAll[providerIndex])
        this.providers = [this.providerListsAll[providerIndex]['code']];
        this.checkProvidersParam(this.providerListsAll[providerIndex]['code']);
      }

      this.updateLabel();
      this.getGames(true, this.providers, this.searchValue, this.gameTypes, this.tags);
    }
  }

  checkProvidersParam(name:any){
    if (name) {
      let currentProvidersVal = [name];
      this.selectedOptionsProvider = currentProvidersVal;
      for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
        this.searchedOptionsProvider[i]["checked"] = false;
      }
      if (currentProvidersVal.length > 0) {
        for (let i = 0; i < currentProvidersVal.length; i++) {
          let index = this.searchedOptionsProvider.findIndex((x: any) => x.code === currentProvidersVal[i]);
          this.providersLabel = this.searchedOptionsProvider[index]["name"];
          this.searchedOptionsProvider[index]["checked"] = true;
        }
      } else {
        // console.log('providers')
        for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
          this.searchedOptionsProvider[i]["checked"] = false;
        }
      }
    } else {
      this.selectedOptionsProvider = [];
      for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
        this.searchedOptionsProvider[i]["checked"] = false;
      }
    }
  }

  checkTypeParam(type:any){
    if (type) {
      let currentCategoriesVal = [type]

      this.selectedOptions = currentCategoriesVal;
      for (let i = 0; i < this.searchedOptions.length; i++) {
        this.searchedOptions[i]["checked"] = false;
      }
      if (currentCategoriesVal.length > 0) {
        for (let i = 0; i < currentCategoriesVal.length; i++) {
          let index = this.searchedOptions.findIndex((x: any) => x.code_small === currentCategoriesVal[i]);
          this.gameTypesLabel = this.searchedOptions[index]["title"]["en"];
          this.searchedOptions[index]["checked"] = true;
        }

      } else {
        for (let i = 0; i < this.searchedOptions.length; i++) {
          this.searchedOptions[i]["checked"] = false;
        }
      }
    } else {
      this.selectedOptions = [];
      for (let i = 0; i < this.searchedOptions.length; i++) {
        this.searchedOptions[i]["checked"] = false;
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // console.log(this.tags.length)
      const typeList: any = this.dataService.categoryproviderJSONData;
      this.typeLists = typeList;
      this.searchedOptions = [...this.typeLists];

      this.providerListsAll = this.dataService.providerJSONData['records'] || [];
      // console.log(this.providerListsAll);
      this.searchedOptionsProvider = [...this.providerListsAll];

      this.categoryproviderJSONData = this.dataService.categoryproviderJSONData || [];
      const firstGameType = this.gameTypes[0];
      const selectedCategory = this.categoryproviderJSONData.find(
        (value: any) => value.code_small === firstGameType
      );
      if (selectedCategory) {
        this.providerLists = selectedCategory.providers || [];
      }

      const url: any = this.route.url;
      if (url._value.length > 0) {
        this.currentPath = url._value[0].path;
      }

      this.subscription.add(this.route.queryParams.subscribe((params) => {
        this.qParams = params;

        if (this.currentPath === 'search') {

          this.updateFiltersFromQueryParams(params);

          this.updateLabel();
          this.setCheckProviders();
          this.setCheckCategories();
        }
      }));

      this.getParamsValue();
    }
  }

  ngAfterViewInit(): void {
    if(this.provider){
      this.preload = false;
    }
    if (isPlatformBrowser(this.platformId)) {
      const observer = new IntersectionObserver(
        (entries) => {
          this.page = this.page + 1;
          if (entries[0].isIntersecting && this.page <= this.totalPages) {
            this.getGames(
              false,
              this.providers,
              this.searchValue,
              this.gameTypes,
              this.tags
            );
          }
        },
        { threshold: 1 }
      );

      observer.observe(this.scrollAnchor.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
