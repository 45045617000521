import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { afterNextRender, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '../card-game/card-game.component';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { register } from 'swiper/element/bundle';
import { Swiper, SwiperOptions } from 'swiper/types';
import { EncodeUriPipe, SlugNamePipe, TagNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { AuthService } from '@componentpath/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { faArrowRight, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FilterMobileComponent } from '@template/green/mobile/filter-mobile/filter-mobile.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { MatDialog } from '@angular/material/dialog';
import { PopupAppComponent } from '../popup-app/popup-app.component';

@Component({
  selector: 'app-games-widget',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CardGameComponent,
    NgbDropdownModule,
    MatCheckboxModule,
    FormsModule,
    TagNamePipe,
    SlugNamePipe,
    EncodeUriPipe,
    NgOptimizedImage,
    FilterMobileComponent],
  templateUrl: './games-widget.component.html',
  styleUrl: './games-widget.component.scss'
})
export class GamesWidgetComponent implements OnInit, AfterViewInit {

  @ViewChild('cardTitleWidget') cardTitleWidget!: ElementRef;

  @ViewChild('swiperSlotGames') swiperSlotGames!: ElementRef;
  @ViewChild('swiperSlotGamesM') swiperSlotGamesM!: ElementRef;
  @ViewChild('swiperArcadeGames') swiperArcadeGames!: ElementRef;
  @ViewChild('swiperArcadeGamesM') swiperArcadeGamesM!: ElementRef;
  @ViewChild('swiperShowGames') swiperShowGames!: ElementRef;
  @ViewChild('swiperShowGamesM') swiperShowGamesM!: ElementRef;
  @ViewChild('swiperShowGamesThumbM') swiperShowGamesThumbM!: ElementRef;
  @ViewChild('swiperContainerWidget') swiperContainerWidget!: ElementRef;
  @ViewChild('swiperGamesEvent') swiperGamesEvent!: ElementRef;

  public configSwiperSlot: SwiperOptions = {};
  public configSwiperArcade: SwiperOptions = {};
  public configSwiperArcadeM: SwiperOptions = {};
  public configSwiperShow: SwiperOptions = {};
  public configSwiperShowM: SwiperOptions = {};
  public configSwiperShowThumbM: SwiperOptions = {};
  public configSwiperWidget: SwiperOptions = {};
  public configSwiperSlotM: SwiperOptions = {};
  public configSwiperGamesEvent: SwiperOptions = {};

  slotProviders : any = [];
  slotIndex: number = 0;
  listGamesSlot: any = [];

  arcadeProviders : any = [];
  arcadeIndex: number = 0;
  listGamesArcade: any =[];
  listGameCrash: any =[];
  crashGame: Record<string, any> = {}
  currentSlotProvider: any = [];
  currentSlotIndex: any = 0;

  listGameShow: any =[];
  listGameEvent: any =[];


  cardTitleWidth: number = 0;
  progressArcade: number = 0;
  currentScreenSize: string = '';

  faArrowRight: IconProp = faArrowRight;
  faCaretDown: IconProp = faCaretDown;

  sourceAPK: any = 'desktop';
  websiteJSONData: any = this.dataService.websiteJSONData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private cookie: SsrCookieService
  ){
    if (cookie.get('source')) {
      this.sourceAPK = cookie.get('source');
    }
    this.swiperRender();
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 699.98px)", 'smallOne'],
      ["(min-width: 700px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 699.98px)",
      "(min-width: 700px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  swiperRender() {
    afterNextRender((): void => {
      this.configSwiperSlot = {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      this.configSwiperSlotM = {
        loop: false,
        scrollbar: false,
        mousewheel: true,
        slidesPerView: 3.1,
        slidesPerGroup: 3,
        grid: {
          fill:'column',
          rows: 3,
        },
        spaceBetween: 0,
        freeMode: false,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      this.configSwiperWidget = {
        loop: false,
        autoplay: false,
        mousewheel: false,
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      this.configSwiperArcade = {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperArcadeM = {
        loop: false,
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        centeredSlides: false,
        slidesOffsetBefore: 150,
        slidesOffsetAfter: 10,
        freeMode: false,
        pagination: false,
        navigation: false,
        resistanceRatio: 0,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
          progress(swiper, progress) {
            let htmlEl = document.getElementById('arcadeIcon');
            if(htmlEl){
              htmlEl.style.opacity = ((1-progress*8) <= 0.3 ? 0.3 : (1-progress*8)).toString();
              htmlEl.style.left = (progress * -300 <= -10 ? -10 : progress * -300 > 0 ? 0 : progress * -300)+'px'.toString();
            }
          }
        },
      };
      this.configSwiperShowM = {
        mousewheel: false,
        slidesPerView: 'auto',
        spaceBetween: 20,
        freeMode: false,
        pagination: false,
        navigation: false,
        nested: true,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperShowThumbM = {
        init: true,
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        freeMode: false,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperShow = {
        loop: false,
        scrollbar: false,
        mousewheel: true,
        slidesPerView: 2,
        slidesPerGroup: 6,
        grid:{
          rows: 2,
          fill: 'row'
        },
        spaceBetween: 0,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperGamesEvent = {
        init: true,
        effect: 'coverflow',
        mousewheel: true,
        centeredSlides: true,
        initialSlide: 0,
        slidesPerView: 3,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        coverflowEffect: {
          rotate: 20,
          stretch: 20,
          depth: 220,
          modifier: 1,
          slideShadows: true,
        },
        pagination: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      if (this.swiperArcadeGames) {
        Object.assign(this.swiperArcadeGames.nativeElement, this.configSwiperArcade); // Add parameters to the Swiper
        this.swiperArcadeGames.nativeElement.initialize(); // Init Swiper
      }
      if (this.swiperArcadeGamesM) {
        Object.assign(this.swiperArcadeGamesM.nativeElement, this.configSwiperArcadeM); // Add parameters to the Swiper
        this.swiperArcadeGamesM.nativeElement.initialize(); // Init Swiper
      }

      if (this.swiperShowGames){
        Object.assign(this.swiperShowGames.nativeElement, this.configSwiperShow); // Add parameters to the Swiper
        this.swiperShowGames.nativeElement.initialize(); // Init Swiper
      }

      
      if (this.swiperShowGamesM){
        Object.assign(this.swiperShowGamesM.nativeElement, this.configSwiperShowM); // Add parameters to the Swiper
        this.swiperShowGamesM.nativeElement.initialize(); // Init Swiper
      }

      
      if (this.swiperShowGamesThumbM){
        Object.assign(this.swiperShowGamesThumbM.nativeElement, this.configSwiperShowThumbM); // Add parameters to the Swiper
        this.swiperShowGamesThumbM.nativeElement.initialize(); // Init Swiper
      }


      if (this.swiperSlotGames){
        Object.assign(this.swiperSlotGames.nativeElement, this.configSwiperSlot); // Add parameters to the Swiper
        this.swiperSlotGames.nativeElement.initialize(); // Init Swiper
      }

      if (this.swiperSlotGamesM) {
        Object.assign(this.swiperSlotGamesM.nativeElement, this.configSwiperSlotM); // Add parameters to the Swiper
        this.swiperSlotGamesM.nativeElement.initialize(); // Init Swiper
        // this.swiperSlotGamesM.nativeElement.addEventListener("swiperprogress", (e:any) => {
        //   const [swiper, progress] = e.detail;
        // });
      }

      if (this.swiperContainerWidget){
        Object.assign(this.swiperContainerWidget.nativeElement, this.configSwiperWidget); // Add parameters to the Swiper
        this.swiperContainerWidget.nativeElement.initialize(); // Init Swiper
      }
      if (this.swiperGamesEvent){
        Object.assign(this.swiperGamesEvent.nativeElement, this.configSwiperGamesEvent); // Add parameters to the Swiper
        this.swiperGamesEvent.nativeElement.initialize(); // Init Swiper
      }
    });
  }

  readonly dialog = inject(MatDialog);
  openDialog(): void {
    const dialogRef = this.dialog.open(PopupAppComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }

  filterProvider(){
    console.log(this.slotProviders);
    let dataFilter = {
      type: 'list-providers',
      data: {
        currentFilter: this.currentSlotProvider,
        listArray: this.slotProviders.providers
      }
    }
    this.bottomSheet.open(FilterMobileComponent, {
      data: dataFilter
    }).afterDismissed().subscribe((v) => {
      // console.log(v)
      if (v !== undefined) {
        if (this.swiperSlotGamesM) {
          let swiper = this.swiperSlotGamesM.nativeElement.swiper as Swiper;
          swiper.slideTo(0, 0);
        }
        this.currentSlotProvider = v;
        this.getGames(27, 'SL', v['_id'], '');
        // this.selectedCreateBank(v);
      }
    })
  }

  clickPlay(info:any) {
    let slugProvider = new SlugNamePipe();
    this.router.navigate([`/play/${slugProvider.transform(info.provider_name)}/${slugProvider.transform(info.title)}`], { queryParams: { id: info.game_id } });
    // if (this.auth.sessionValue) {
    //   this.router.navigate([`/play/${slugProvider.transform(info.title)}`], { queryParams: { id: info.game_id } });
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }
  clickPlayCustom(info:any) {
    let slugProvider = new SlugNamePipe();
    this.router.navigate([`/play/${slugProvider.transform(info.provider_name)}/${slugProvider.transform(info.game_name)}`], { queryParams: { id: info.game_id } });
    // if (this.auth.sessionValue) {
    //   this.router.navigate([`/play/${slugProvider.transform(info.game_name)}`], { queryParams: { id: info.game_id } });
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }

  getCrashGames(){
    if (isPlatformBrowser(this.platformId)) {
      this.request.crashGamesJSON().subscribe({
        next: (v) => {
          this.listGameCrash = v;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        },
      });
    }
  }
  getEventGames(){
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesEvent().subscribe({
        next: (v) => {
          for (let i = 0; i < v.providers.length; i++) {
            const element = v.providers[i];
            this.listGameEvent.push.apply(this.listGameEvent, element.games);
          }
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        },
      });
    }
  }

  getGameShowGames(){
    if (isPlatformBrowser(this.platformId)) {
      this.request.gameshowGamesJSON().subscribe({
        next: (v) => {
          this.listGameShow = v;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        },
      });
    }
  }

  getGames(total: number, type:string, provider: string, tag: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, total, [provider], [type], [tag], '', 'popularity_point','desc').subscribe({
          next: (v) => {
            // console.log(v)
            if(type === 'SL'){
              this.listGamesSlot = v['items'];
            }
            if(type === 'FH'){
              this.listGamesArcade = v['items'];
            }
            if(type === 'LC'){
              // this.listGameShow = v['items'];
            }
            if(tag === 'CRASH'){
              if (v['items'].length > 0){
                // this.listGameCrash = v['items'];
              }
            }
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {
          },
        });
    }
  }

  prevSlot(){
    if (this.slotIndex <= 0){
      this.slotIndex = 0
    } else{
      this.slotIndex =  this.slotIndex - 1;
    }
    this.getGames(27, 'SL', this.slotProviders.providers[this.slotIndex]['_id'],'');
  };

  nextSlot(){
    if (this.slotIndex >= (this.slotProviders.providers.length - 1)) {
      this.slotIndex = (this.slotProviders.providers.length - 1)
    } else {
      this.slotIndex = this.slotIndex + 1;
    }
    this.getGames(27, 'SL', this.slotProviders.providers[this.slotIndex]['_id'], '');
  };

  prevArcade(){
    if (this.arcadeIndex <= 0){
      this.arcadeIndex = 0
    } else{
      this.arcadeIndex = this.arcadeIndex - 1;
    }
    this.getGames(27, 'FH', this.arcadeProviders.providers[this.arcadeIndex]['_id'],'');
  };

  nextArcade(){
    if (this.arcadeIndex >= (this.arcadeProviders.providers.length - 1)) {
      this.arcadeIndex = (this.arcadeProviders.providers.length - 1)
    } else {
      this.arcadeIndex = this.arcadeIndex + 1;
    }
    this.getGames(27, 'FH', this.arcadeProviders.providers[this.arcadeIndex]['_id'], '');
  };


  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['source']) {
        this.sourceAPK = (params['source']).toLowerCase();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.slotProviders = (this.dataService.categoryproviderJSONData as Array<any>).find(
        (value: any) => value.code_small === 'SL'
      );
      this.currentSlotProvider = this.slotProviders.providers[0];
      this.getGames(27, 'SL', this.slotProviders.providers[this.slotIndex]['_id'], '');
      
      this.arcadeProviders = (this.dataService.categoryproviderJSONData as Array<any>).find(
        (value: any) => value.code_small === 'FH'
      );
      this.getGames(27, 'FH', this.arcadeProviders.providers[this.slotIndex]['_id'], '');
      this.getGameShowGames();
      this.getCrashGames();
      this.getEventGames();
      // this.getGames(10, 'LC', '', 'GAMESHOW');
      // this.getGames(10, '', '', 'CRASH');
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (this.cardTitleWidget && this.listGamesSlot.length > 0 && this.listGamesArcade.length > 0) {
          // console.log(this.cardTitleWidget.nativeElement.clientWidth);
          this.cardTitleWidth = this.cardTitleWidget.nativeElement.clientWidth;
          this.cdr.detectChanges();
        }
      }, 1000);
      register();
    }
  }
}
