import { AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import {
  faClock,
  faCircleXmark,
  faCircleCheck,
} from '@fortawesome/free-regular-svg-icons';
import { CommonModule, DOCUMENT, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { AuthService } from '@componentpath/services/auth.service';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DataService } from '@componentpath/services/data.service';
import { SlugNamePipe, TagNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { SwiperDirective } from '@componentpath/lib/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';

@Component({
  selector: 'app-card-game',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [FontAwesomeModule, CommonModule, TagNamePipe, SwiperDirective, NgOptimizedImage],
  templateUrl: './card-game.component.html',
  styleUrl: './card-game.component.scss',
})
export class CardGameComponent implements AfterViewInit {
  @ViewChild('cardTitle') cardTitle!: ElementRef;
  @ViewChild('cardTitleText') cardTitleText!: ElementRef;
  @ViewChild('cardTitleMobile') cardTitleMobile!: ElementRef;

  @Input() info: any;
  @Input() image: string = '';
  @Input() imagesProvider: Array<any> = [];
  @Input() gacor: string = '';
  @Input() favourite: boolean = false;
  @Input() name: string = '';
  @Input() providername: string = '';
  @Input() percent: number = 0;
  @Input() pattern: string = '';
  @Input() dc: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() provider: boolean = false;

  public config: SwiperOptions = {
    init: false,
    modules: [Navigation, Pagination, A11y, Mousewheel, Grid],
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: true,
  };

  providerJSONData: any = [];
  error: boolean = false;
  gameURL!: SafeResourceUrl;
  preload: boolean = true;
  tags: Array<any> = [];
  cardTitleWidth: any = 0;
  cardTitleTextWidth: any = 0;
  cardTitleWidthMobile: any = 0;

  websiteJSONData: any = this.data.websiteJSONData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: Document,
    private cdr: ChangeDetectorRef,
    public data: DataService,
    public auth: AuthService,
    private router: Router,
  ) {
    
    // this.tags = this.info['tags'];
  }
  faStar: IconProp = faStar;
  faClock: IconProp = faClock;
  faCircleXmark: IconProp = faCircleXmark;
  faCircleCheck: IconProp = faCircleCheck;

  clickPlay() {
    let slugProvider = new SlugNamePipe();
    this.router.navigate([`/play/${slugProvider.transform(this.info.provider_name)}/${slugProvider.transform(this.info.title)}`], { queryParams: { id: this.info.game_id } });
    // if (this.auth.sessionValue) {
    //   this.router.navigate([`/play/${slugProvider.transform(this.info.provider_name)}/${slugProvider.transform(this.info.title)}`], { queryParams: { id: this.info.game_id } });
    // } else {
    //   this.router.navigate(['/login']);
    // }
  }

  clickProvider() {
    let slugProvider = new SlugNamePipe();
    this.router.navigate(['/provider/'+slugProvider.transform(this.info['name'])]);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      setTimeout(() => {
        if(this.cardTitle){
          this.cardTitleWidth = this.doc.querySelector("#cardTitle")?.clientWidth;
          this.cdr.detectChanges();
        }
        if (this.cardTitleText){
          this.cardTitleTextWidth = this.cardTitleText.nativeElement.clientWidth;
          // this.cardTitleTextWidth = this.doc.querySelector("#cardTitleText")?.clientWidth;
          this.cdr.detectChanges();
        }
        if(this.cardTitleMobile){
          this.cardTitleWidthMobile = this.cardTitleMobile.nativeElement.clientWidth;
          this.cdr.detectChanges();
        }
      }, 1000);
    }
    
  }
}
