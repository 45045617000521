<div>
  <div class="d-none d-md-flex all-games-inner w-100">
    @for (item of data; track item.id;) {
    <app-card-game
      [info]="item"
      [image]="item.image"
      [gacor]="item.gacor"
      [favourite]="item.favourite"
      [name]="item.title"
      [providername]="item.provider_name"
      [percent]="item.rtp"
      [pattern]="item.pattern"
      [check1]="item.check1"
      [check2]="item.check2"
      [check3]="item.check3"
      [dc]="item.dc"
    />
    }
  </div>
</div>
