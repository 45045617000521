<div class="page-inner-promotion h-100 py-2 mt-4">
          <div class="profile-container row px-4">
            <div class="information-container w-100 p3">
              <div class="heading-container row p-2">
                <div class="title-section col-12">
                  <p class="font-weight-bold">INFORMASI PRIBADI</p>
                  <p class="opacity-50">
                    Kelola nama dan informasi akun Anda. Detail pribadi ini
                    bersifat pribadi dan tidak akan ditampilkan kepada pengguna
                    lain.
                  </p>
                </div>
              </div>
                <div class="edit-profile row">
                  @if(successMessage !== '') {
                  <div class="alert alert-success p-2" role="alert">
                      {{successMessage}}
                  </div>
                  }
                  @if(errorMessage !== '') {
                  <div class="alert alert-danger p-2" role="alert">
                      {{errorMessage}}
                  </div>
                  }
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="Please Enter First Name"  [(ngModel)]="first_name"/>
                    <mat-icon matSuffix>person_pin</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Please Enter Last Name" [(ngModel)]="last_name"/>
                    <mat-icon matSuffix>person_pin</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Please Enter Email" [(ngModel)]="email"/>
                    <mat-icon matSuffix>mail_outline</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Nomor Telepon</mat-label>
                    <input matInput placeholder="Please Enter Phone Number" [(ngModel)]="phone_number"/>
                    <mat-icon matSuffix>phone</mat-icon>
                  </mat-form-field>
                </div>
                <div>
                  <button  class="btn submit btn btn-primary btn-lg btn-detail save-button float-end w-20" [disabled]="isSubmit" (click)="editProfileSubmit()">
                @if(isSubmit) {
                <div class="spinner-border p-2 spinner-border-sm"></div>
                } @else {
                SAVE
                }
            </button>
                </div>
              </div>

            </div>
          </div>
        </div>
