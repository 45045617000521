import {
  Component,
  Inject,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faEyeSlash,
  faEye,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { ToastComponent } from '@componentpath/parts/toast/toast.component';
import { ToastService } from '@componentpath/services/toast.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { RouterExtService } from '@componentpath/services/scripts.service';

@Component({
  selector: 'app-login-input',
  standalone: true,
  templateUrl: './login-input.component.html',
  styleUrl: './login-input.component.scss',
  imports: [FontAwesomeModule, FormsModule, ToastComponent, CommonModule],
})
export class LoginInputComponent {
  faUser: IconProp = faUser;
  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  faLock: IconProp = faLock;
  subscription: Subscription = new Subscription();

  username = '';
  password = '';
  isPasswordVisible = false;

  currentScreenSize: string = '';
  disabledLoginButton: boolean = false;

  prevURL: string = '';

  @ViewChild('errorToastTpl') errorToastTpl!: TemplateRef<any>;
  @ViewChild('defaultTemplate') defaultTemplate!: TemplateRef<any>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
    private toastService: ToastService,
    private breakpointObserver: BreakpointObserver,
    private routerExtService: RouterExtService,
  ) {
    this.prevURL = this.routerExtService.getPreviousUrl();
    // console.log(this.prevURL)
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toUppercase(event: string): void {
    this.username = event.toUpperCase();
  }

  login(): void {
    this.disabledLoginButton = true;

    if (isPlatformBrowser(this.platformId)) {
      if (!this.username || !this.password) {
        this.toastService.show({
          template: this.errorToastTpl,
          classname: 'bg-danger text-light',
          delay: 8000,
          context: { message: 'Please complete all required fields.' },
        });
        this.disabledLoginButton = false;
        return;
      }
      this.request.login(this.username, this.password).subscribe({
        next: (response) => {
          if (response.code === 200) {
            console.log(this.prevURL);
            this.auth.setLogin(response.data, this.prevURL);
          } else {
            console.error('Login failed:', response.message);
            const message = response.message || 'Login failed';
            this.toastService.show({
              template: this.errorToastTpl,
              classname: 'bg-danger text-light',
              context: { message },
              delay: 8000,
            });
          }
        },
        error: (error) => {
          this.disabledLoginButton = false;
          const errorMessage =
            error.error.messages[0].message || 'Login failed';
          this.toastService.show({
            template: this.errorToastTpl,
            classname: 'bg-danger text-light',
            context: { message: errorMessage },
            delay: 8000,
          });
        },
        complete: () => {
          // this.router.navigate(['/']);
          // console.log(this.auth.sessionValue);
        },
      });
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          // console.log('login', v);
        },
      })
    );
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.auth.getUserData();
    }
    // this.promo();
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
