import { isPlatformBrowser,CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, PLATFORM_ID,Renderer2, ViewChild, ElementRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormatDateIdPipe } from '@componentpath/pipes/format-date-id.pipe';
import { FormatTimeIdPipe } from '@componentpath/pipes/format-time-id.pipe';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faComments, faGift, faHouse, faMoneyBill, faRightToBracket, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FooterComponent } from '@template/green/footer/footer.component';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';
import { GamesWidgetComponent } from '@template/green/parts/games-widget/games-widget.component';
import { JackpotComponent } from '@template/green/parts/jackpot/jackpot.component';
import { ListProvidersComponent } from '@template/green/parts/list-providers/list-providers.component';
import { SlideshowHomeComponent } from '@template/green/parts/slideshow-home/slideshow-home.component';
import { SlideshowPromotionComponent } from '@template/green/parts/slideshow-promotion/slideshow-promotion.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-mobile',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule,FormatDateIdPipe, FormatTimeIdPipe, FontAwesomeModule, RouterModule, HeaderMobileComponent, JackpotComponent, ListProvidersComponent, GamesWidgetComponent, SlideshowPromotionComponent, SlideshowHomeComponent, FooterComponent],
  templateUrl: './home-mobile.component.html',
  styleUrl: './home-mobile.component.scss'
})
export class HomeMobileComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public dataService: DataService,
    private requestService: RequestService,
    private auth: AuthService,
    private renderer: Renderer2
  ){}


  @ViewChild('shakeImage', { static: false }) shakeImage: ElementRef | undefined;
  @ViewChild('shakeSpan', { static: false }) shakeSpan: ElementRef | undefined;
  private livescoreIntervalId: any;
  private shakeIntervalId: any;
  subscription: Subscription = new Subscription();
  slideshowJSONData: Array<any> = [];
  promoJSONData: any = [];
  footerJSONData: any = this.dataService.footerJSONData;
  providersData: any = this.dataService.providerJSONData;
  footerData: any = [];
  popularGamesData: any = [];
  faAngleRight: IconProp = faAngleRight;
  faMoneyBill: IconProp = faMoneyBill;
  faWallet: IconProp = faWallet;
  faAndroid: IconProp = faAndroid;
  faGift: IconProp = faGift;
  faHouse: IconProp = faHouse;
  faComments: IconProp = faComments;
  faRightToBracket: IconProp = faRightToBracket;
  faUser: IconProp = faUser;
  websiteJSONData: any = this.dataService.websiteJSONData;
  isLoggedin: boolean = false;
  hideLiveScore: boolean = true;

  livechatURL: string = '';
  betList: any = [
    {
      team1: 'M.City',
      team2: 'M.Utd',
      winner: 'M.Utd',
      totalBet: 20000,
    },
    {
      team1: 'B.Munich',
      team2: 'BVB',
      winner: 'B.Munich',
      totalBet: 20000,
    },
    {
      team1: 'Liv',
      team2: 'Ars',
      winner: 'Liv',
      totalBet: 20000,
    },
  ];
  sabaBetList: any = [
    {
      team1: 'Che',
      team2: 'Tot',
      winner: 'Che',
      totalBet: 20000,
    },
    {
      team1: 'Barca',
      team2: 'A.Madrid',
      winner: 'Barca',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
  ];
  bigMatchData: any = {
    live: true,
    phase : 1,
    match_start_at : "2024-08-02T14:00:00Z",
    league : "UEFA Champions League",
    league_name : "UEFA Champions League",
    home_team: '',
    home_team_name: 'Real Madrid',
    home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
    home_score: 1,
    away_team: '',
    away_team_name: 'Rayo Vallecano',
    away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
    away_score: 0,
  }

  otherMatchData: any = [
    {
      live: false,
      phase : 1,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 2,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 3,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 4,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 5,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 6,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 7,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 9,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },

  ];
  listPromo: any = [
    {
      id: 1,
      title: 'Banner 1',
      image: '../assets/orianna/images/banner1.png',
    },
    {
      id: 2,
      title: 'Banner 2',
      image: '../assets/orianna/images/banner2.png',
    },
    {
      id: 3,
      title: 'Banner 3',
      image: '../assets/orianna/images/banner3.png',
    },
    {
      id: 4,
      title: 'Banner 4',
      image: '../assets/orianna/images/banner4.png',
    },
  ];



  formatDayDate(value : string){
   return  new Date(value).toLocaleDateString('id-ID', {
      weekday: 'short',
      day: 'numeric',
    });
  }

  formatMonthYear(value : string){
    return  new Date(value).toLocaleDateString('id-ID', {
      month: 'short',
      year: '2-digit',
    });
  }

  getOpacity(homeScore: number, awayScore: number, team: 'home' | 'away'): string {
    if (team === 'home' && homeScore < awayScore) {
      return '0.5';
    } else if (team === 'away' && awayScore < homeScore) {
      return '0.5';
    }
    return '1';
  }

  shakeElements(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.shakeImage && this.shakeSpan) {
        this.renderer.addClass(this.shakeImage.nativeElement, 'shake');
        this.renderer.addClass(this.shakeSpan.nativeElement, 'shake');

        setTimeout(() => {
          this.renderer.removeClass(this.shakeImage!.nativeElement, 'shake');
          this.renderer.removeClass(this.shakeSpan!.nativeElement, 'shake');
        }, 500);
      }
  }
  }

  getMinuteDifference(targetTime: string): number {
    const targetDate = new Date(targetTime);
    const currentDate = new Date();

    const diffInMs = targetDate.getTime() - currentDate.getTime();
    const diffInMinutes = Math.round(diffInMs / (1000 * 60));
    return diffInMinutes;
  }

  public setLivechatURL() {
    const livechatData = this.websiteJSONData['contact']['livechat'];
    let engineName = (livechatData['engine']).toLowerCase();
    if (engineName === 1 || engineName === 'livechatinc' || engineName === 'livechat' || engineName === 'livechat.com') {
      if (livechatData['code']) {
        this.livechatURL = "https://direct.lc.chat/" + livechatData['code'] + "/" + livechatData['group'];
      }
    }
    if (engineName === 2 || engineName === 'purechat') {
      this.livechatURL = "https://app.purechat.com/w/" + livechatData['pagename'];
    }
    if (engineName === 3 || engineName === 'tawk.to') {
      if (livechatData['pagename']) {
        this.livechatURL = "https://tawk.to/" + livechatData['pagename'];
      } else {
        this.livechatURL = "https://tawk.to/chat/" + livechatData['code'];
      }
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          this.isLoggedin = v;
        },
      })
    );
  }

  public filterPromotion() {
    if (this.dataService.promoJSONData['general']) {
      for (const value of this.dataService.promoJSONData['general'] as any[]) {
        if (!value.global && this.promoJSONData.length < 4) {
          // console.log(value)
          this.promoJSONData.push(value);
        }
      }
    }

    // console.log(this.promoJSONData);
  }

  // public filterSlideshow() {
  //   for (const value of this.dataService.slideshowJSONData['homepage'] as Array<any>) {
  //     let tagsValue = value.tag as Array<string>;
  //     if(tagsValue){
  //       if (!value.global && tagsValue.includes('HOMEPAGE')) {
  //         this.slideshowJSONData.push(value);
  //       }
  //     }
  //   }
  // }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.setLivechatURL();
      this.filterPromotion();
      this.slideshowJSONData = this.dataService.categoryslideshowJSONData['homepage'];
      // console.log(this.dataService.promoJSONData['general'])
      // this.filterSlideshow();
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      // window.removeEventListener('scroll', this.scroll, true);
    }
  }

}
