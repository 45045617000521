import { Component, Input } from '@angular/core';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-all-providers',
  standalone: true,
  imports: [RouterModule, FontAwesomeModule],
  templateUrl: './all-providers.component.html',
  styleUrl: './all-providers.component.scss'
})
export class AllProvidersComponent {
  @Input() data: any;
  faAnglesRight: IconProp = faAnglesRight;
}
