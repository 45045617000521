@if(currentScreenSize === 'xSmall'){
    <app-header-mobile></app-header-mobile>
} @else{
    <app-header-simple></app-header-simple>
}
<div class="page-landing">
    @if(dataGameEvent){
        <div class="container-xxl p-0">
            <div class="container-landing">
                <div class="image-landing">
                    <img [src]="dataGameEvent.content.image">
                </div>
                <div class="content">
                    <div class="title" [innerHTML]="dataGameEvent.content.article.title"></div>
                    <div class="paragraph" [innerHTML]="dataGameEvent.content.article.paragraph"></div>
                </div>
                <div class="providers">
                    @for (item of dataGameEvent.providers; track $index) {
                        <a [routerLink]="['/landing/'+ (item.name | slugName)]">
                            <div class="provider-name">
                                {{item.name}}
                            </div>
                            <div class="image-providers">
                                <img [src]="item.content.image">
                            </div>
                        </a>
                    }
                </div>
            </div>
        </div>
    }
</div>
