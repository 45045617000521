<div class="promotion-mobile-container">
  <div class="header-container">
    <fa-icon [icon]="faArrowLeft" class="back-icon" (click)="goBack()"></fa-icon>
    <p class="title">Profile</p>
  </div>
  @if(part === 'default'){
    <div class="promotion-body">
      <div class="profile-section">
        <div class="header">
          <div class="title-container">
            <div class="title">INFORMASI PRIBADI</div>
            <div class="subtitle">
              Detail data pribadi ini bersifat pribadi dan terjaga keamanan data
              pribadi anda.
            </div>
          </div>
          <div class="edit-button" (click)="changePart('profile')">
            <div>Edit</div>
          </div>
        </div>
        <div class="input-container">
          <div class="input-item">
            <div class="content-left">
              <div class="title">First Name</div>
              <div class="value">
                {{ profile.first_name }}
              </div>
            </div>
            <div class="content-right">
              <img
                src="../../template/green/assets/images/mobile/person.webp"
                class="img-fluid input-icon"
              />
            </div>
          </div>
          <div class="input-item">
            <div class="content-left">
              <div class="title">Last Name</div>
              <div class="value">
                {{ profile.last_name }}
              </div>
            </div>
            <div class="content-right">
              <img
                src="../../template/green/assets/images/mobile/person.webp"
                class="img-fluid input-icon"
              />
            </div>
          </div>
          <div class="input-item">
            <div class="content-left">
              <div class="title">Email</div>
              <div class="value">
                {{ profile.email }}
              </div>
            </div>
            <div class="content-right">
              <img
                src="../../template/green/assets/images/mobile/email.webp"
                class="img-fluid input-icon"
              />
            </div>
          </div>
          <div class="input-item">
            <div class="content-left">
              <div class="title">Nomor Telepon</div>
              <div class="value">
                {{ profile.mobile_phone_number }}
              </div>
            </div>
            <div class="content-right">
              <img
                src="../../template/green/assets/images/mobile/phone.webp"
                class="img-fluid input-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="profile-section mt-4">
        <div class="header">
          <div class="title-container">
            <div class="title">PASSWORD</div>
            <div class="subtitle">
              Gunakan kata sandi untuk dapat login kedalam akun anda
            </div>
          </div>
          <div class="edit-button" (click)="changePart('password')">
            <div>Change</div>
          </div>
        </div>
        <div class="input-container">
          <div class="input-item">
            <div class="content-left">
              <div class="title">Password</div>
              <div class="value ">
                **********
              </div>
            </div>
            <div class="content-right">
              <img
                src="../../template/green/assets/images/mobile/eye.webp"
                class="img-fluid input-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="profile-section mt-4">
        <div class="header">
          <div class="title-container">
            <div class="title">PIN KEAMANAN</div>
            <div class="subtitle">
              Gunakan PIN Keamanan unduk veirfikasi login kedalam akun anda
            </div>
          </div>
          <div class="edit-button" (click)="changePart('pin')">
            <div>Change</div>
          </div>
        </div>
        <div class="input-container">
          <div class="input-item">
            <div class="content-left">
              <div class="title">PIN</div>
              <div class="value ">
                ******
              </div>
            </div>
            <div class="content-right">
              <img
                src="../../template/green/assets/images/mobile/eye.webp"
                class="img-fluid input-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if(part === 'profile') {
    <app-profile-information-mobile></app-profile-information-mobile>
  }
  @if(part === 'password') {
    <app-password></app-password>
  }
  @if (part === 'pin') {
    <app-pin></app-pin>
  }

</div>


