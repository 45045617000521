import { isPlatformBrowser } from '@angular/common';
import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  faMagnifyingGlass,
  faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '@componentpath/parts/card-game/card-game.component';
import { RouterModule } from '@angular/router';
import { db } from '@componentpath/db/db';
import { RequestService } from '@componentpath/services/request.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-all-games',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CardGameComponent,
    NgbDropdownModule,
    MatCheckboxModule,
  ],
  templateUrl: './all-games.component.html',
  styleUrl: './all-games.component.scss',
})
export class AllGamesComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @Input() filterAll: boolean = true;
  @Input() filterCategory: boolean = false;
  @Input() filterSearch: boolean = false;
  @Input() seeAll: boolean = true;
  @Input() loadMore: boolean = false;
  @Input() lobby: boolean = false;
  @Input() minHeight: boolean = false;

  @Input() page: number = 1;
  @Input() perPage: number = 10;
  @Input() providers: Array<any> = [''];
  @Input() gameTypes: Array<any> = [''];
  @Input() tags: Array<any> = [''];
  @Input() searchValue: string = '';
  @Input() data: Array<any> = [];

  gameTypesLabel: string = 'CATEGORY';
  tagsLabel: string = 'TYPE';
  providersLabel: string = 'PROVIDER';
  sortByLabel: string = 'SORT BY';

  currentPath: string = '';
  totalPages: number = 0;

  type: string = '';
  typeLists: Array<any> = [];
  sort: string = '';
  sortBy: string = 'created_at';
  categoryproviderJSONData: any = [];
  providerLists: Array<any> = [];
  providerListsAll: Array<any> = [];

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faAnglesRight: IconProp = faAnglesRight;

  updateQueryParams() {
    if (this.currentPath === 'search') {
      let queryParams: any = {};
      if (this.gameTypes.length > 0 && this.gameTypes[0] !== '') {
        queryParams['category'] = this.gameTypes;
      }
      if (this.tags.length > 0 && this.tags[0] !== '') {
        queryParams['type'] = this.tags;
      }
      if (this.providers.length > 0 && this.providers[0] !== '') {
        queryParams['provider'] = this.providers;
      }
      if (this.sortBy !== '') {
        queryParams['sort'] = this.sortBy;
      }
      if (this.searchValue !== '') {
        queryParams['search'] = this.searchValue;
      }
      this.router.navigate(['/search'], { queryParams: queryParams });
    }
    this.updateLabel();
  }

  updateLabel() {
    if (this.gameTypes.length === 1 && this.gameTypes[0] === '') {
      this.gameTypesLabel = 'CATEGORY';
    } else if (this.gameTypes.length === 1 && this.gameTypes[0] !== '') {
      for (const value of this.typeLists) {
        if (value.code_small === this.gameTypes[0]) {
          this.gameTypesLabel = value.name;
          break;
        }
      }
    } else {
      this.gameTypesLabel = this.gameTypes.length + ' CATEGORIES';
    }

    if (this.tags.length === 1 && this.tags[0] === '') {
      this.tagsLabel = 'TYPE';
    } else if (this.tags.length === 1 && this.tags[0] !== '') {
      this.tagsLabel = this.tags[0];
    } else {
      this.tagsLabel = this.tags.length + ' TYPES';
    }

    if (this.providers.length === 1 && this.providers[0] === '') {
      this.providersLabel = 'PROVIDER';
    } else if (this.providers.length === 1 && this.providers[0] !== '') {
      for (const value of this.providerListsAll) {
        if (value.code === this.providers[0]) {
          this.providersLabel = value.name;
          break;
        }
      }
    } else {
      this.providersLabel = this.providers.length + ' PROVIDERS';
    }

    if (this.sortBy === 'created_at') {
      this.sortByLabel = 'Latest';
    } else {
      this.sortByLabel = 'Popular';
    }
  }

  getGames(reset: boolean = false) {
    // db.dbGamesExists().then((e) => {});
    if (isPlatformBrowser(this.platformId)) {
      let providers = [''];
      for (const value of this.providers) {
        for (const value2 of this.providerListsAll) {
          if (value === value2.code) {
            if (providers[0] === '') {
              providers = [];
            }
            providers.push(value2._id);
            break;
          }
        }
      }

      this.request
        .gamesJSON(
          this.page,
          this.perPage,
          providers,
          this.gameTypes,
          this.tags,
          this.searchValue,
          this.sortBy,
          'desc'
        )
        .subscribe({
          next: (v) => {
            if (reset) {
              this.data = v['items'];
            } else {
              this.data = this.data.concat(v['items']);
            }
            this.totalPages = v['totalPages'];
            // db.addGames(v['items']);
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => { },
        });
    }
  }

  lobbyClick(value: string) {
    if (this.tags[0] === value) {
      this.tags = [''];
    } else {
      this.tags = [value];
    }

    this.page = 1;
    this.getGames(true);
  }

  filterAllClick(value: string) {
    this.page = 1;
    if (value === '') {
      this.gameTypes = [''];
    } else if (value === 'slots') {
      this.gameTypes = ['SL'];
    } else if (value === 'live-casino') {
      this.gameTypes = ['LC'];
    } else if (value === 'fish-hunter') {
      this.gameTypes = ['FH'];
    } else if (value === 'sportbook') {
      this.gameTypes = ['SB'];
    }

    this.type = value;
    this.getGames(true);
  }

  filterSortClick(value: string) {
    this.page = 1;
    if (value === 'name') {
      this.sortBy = 'name';
    } else if (value === 'new') {
      this.sortBy = 'created_at';
    } else if (value === 'popular') {
      this.sortBy = 'popularity_point';
    }

    this.sort = value;
    this.getGames(true);
    this.updateQueryParams();
  }

  filterTypeClick(value: string) {
    this.page = 1;
    if (this.gameTypes.indexOf(value) > -1) {
      this.gameTypes.splice(this.gameTypes.indexOf(value), 1);
      if (this.gameTypes.length === 0) {
        this.gameTypes = [''];
      }
    } else {
      if (this.gameTypes[0] === '') {
        this.gameTypes.splice(0, 1);
      }
      this.gameTypes.push(value);
    }

    this.getGames(true);
    this.updateQueryParams();
  }

  filterTagClick(value: string) {
    this.page = 1;
    if (this.tags.indexOf(value) > -1) {
      this.tags.splice(this.tags.indexOf(value), 1);
      if (this.tags.length === 0) {
        this.tags = [''];
      }
    } else {
      if (this.tags[0] === '') {
        this.tags.splice(0, 1);
      }
      this.tags.push(value);
    }

    this.getGames(true);
    this.updateQueryParams();
  }

  filterProviderClick(value: string) {
    this.page = 1;
    if (this.providers.indexOf(value) > -1) {
      this.providers.splice(this.providers.indexOf(value), 1);
      if (this.providers.length === 0) {
        this.providers = [''];
      }
    } else {
      if (this.providers[0] === '') {
        this.providers.splice(0, 1);
      }
      this.providers.push(value);
    }

    this.getGames(true);
    this.updateQueryParams();
  }

  filterSearchChange(event: any) {
    this.page = 1;
    this.searchValue = event.target.value;
    this.getGames(true);

    this.updateQueryParams();
  }

  loadMoreClick() {
    this.page = this.page + 1;

    this.getGames(false);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const url: any = this.route.url;
      if (url._value.length > 0) {
        this.currentPath = url._value[0].path;
      }

      this.route.queryParams.subscribe((params) => {
        if (this.currentPath === 'search') {
          if (params['category'] !== undefined) {
            if (typeof params['category'] !== 'string') {
              this.gameTypes = params['category'];
            } else {
              this.gameTypes = [params['category']];
            }
          }
          if (params['type'] !== undefined) {
            if (typeof params['type'] !== 'string') {
              this.tags = params['type'];
            } else {
              this.tags = [params['type']];
            }
          }
          if (params['provider'] !== undefined) {
            if (typeof params['provider'] !== 'string') {
              this.providers = params['provider'];
            } else {
              this.providers = [params['provider']];
            }
          }
          if (params['sort'] !== undefined) {
            this.sortBy = params['sort'];
          }
          if (params['search'] !== undefined) {
            this.searchValue = params['search'];
          }
          this.getGames(true);
          this.updateLabel();
        }
      });

      const categoryproviderJSONData: any =
        this.dataService.categoryproviderJSONData;
      this.typeLists = categoryproviderJSONData;

      const providerJSONData: any = this.dataService.providerJSONData;
      this.providerListsAll = providerJSONData.records;

      if (this.data.length === 0) {
        this.getGames(true);
      }

      this.categoryproviderJSONData = this.dataService.categoryproviderJSONData;
      for (const value of this.categoryproviderJSONData) {
        if (value.code_small === this.gameTypes[0]) {
          this.providerLists = value.providers;
          break;
        }
      }

      this.updateLabel();
    }
  }
}
