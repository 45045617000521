import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pin',
  standalone: true,
  imports: [MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './pin.component.html',
  styleUrl: './pin.component.scss'
})
export class PinComponent {
  hideOldPin = true;
  hideNewPin = true;
  hideConfirmationPin = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
  ) { }

  isSubmit: boolean = false;
  userSecurityData: any = null;

  successMessage: string = '';
  errorMessage: string = '';
  pin: Array<any> = ['', '', ''];

  public pinInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]+/g, '');
  }

  public editPin(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.pin[0] || !this.pin[1] || !this.pin[2]) {
        this.errorMessage = 'PIN or New PIN or Confirm PIN is Required.';
        this.isSubmit = false;
        return;
      } else {
        if (this.pin[1] !== this.pin[2]) {
          this.errorMessage = 'New PIN & Confirm PIN is Not Same.';
          this.isSubmit = false;
          return;
        } else {
          const userProfileData: any = this.auth.userProfileData;
          this.request.editPIN(this.auth.sessionValue, userProfileData._id, this.pin[1], this.pin[0]).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.auth.setUserData(v.data);
                this.isSubmit = false;
                this.router.navigate(['/profile'],{ queryParams: { part: 'default' } });
                this.successMessage = 'Edit PIN is Success.';
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
            }
          })
        }
      }
    }
  }

  ngOnInit(): void {

  }
}
