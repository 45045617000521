import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimeId',
  standalone: true,
})
export class FormatTimeIdPipe implements PipeTransform {
  transform(value: number | string): string {
    return new Date(value).toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}
