<div class="page-inner-promotion h-100 py-2 mt-4">
          <div class="password-container row px-4">
            <div class="information-container w-100 p3">
              <div class="heading-container row p-2">
                <div class="title-section col-12">
                  <p class="font-weight-bold">PASSWORD & PIN KEAMANAN</p>
                  <p class="opacity-50">
                    Demi keamanan Anda, kami sangat menyarankan Anda memilih kata sandi yang unik yang tidak Anda gunakan untuk akun lainnya.
                  </p>
                </div>
              </div>
              <div class="edit-profile row">
                @if(successMessage !== '') {
                  <div class="alert alert-success p-2" role="alert">
                      {{successMessage}}
                  </div>
                  }
                  @if(errorMessage !== '') {
                  <div class="alert alert-danger p-2" role="alert">
                      {{errorMessage}}
                  </div>
                  }
                <div class="col-12">
                  <mat-form-field class="w-100">
                      <mat-label>Masukan Password Lama Anda</mat-label>
                      <input matInput [type]="hideOldPassword ? 'password' : 'text'" [(ngModel)]="password[0]"/>
                      <button
                        mat-icon-button
                        matSuffix
                        (click)="hideOldPassword = !hideOldPassword"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideOldPassword"
                      >
                        <mat-icon>{{
                          hideOldPassword ? "visibility_off" : "visibility"
                        }}</mat-icon>
                      </button>
                    </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field class="w-100">
                      <mat-label>Masukan Password Baru Anda</mat-label>
                      <input matInput [type]="hideNewPassword ? 'password' : 'text'" [(ngModel)]="password[1]"/>
                      <button
                        mat-icon-button
                        matSuffix
                        (click)="hideNewPassword = !hideNewPassword"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideNewPassword"
                      >
                        <mat-icon>{{
                          hideNewPassword ? "visibility_off" : "visibility"
                        }}</mat-icon>
                      </button>
                    </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-form-field class="w-100">
                      <mat-label>Konfirmasi Password Baru</mat-label>
                      <input matInput [type]="hideConfirmationPassword ? 'password' : 'text'" [(ngModel)]="password[2]"/>
                      <button
                        mat-icon-button
                        matSuffix
                        (click)="hideConfirmationPassword = !hideConfirmationPassword"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideConfirmationPassword"
                      >
                        <mat-icon>{{
                          hideConfirmationPassword ? "visibility_off" : "visibility"
                        }}</mat-icon>
                      </button>
                    </mat-form-field>
                </div>
                <div>
                  <button  class="btn submit btn btn-primary btn-lg btn-detail save-button float-end w-20" [disabled]="isSubmit" (click)="editPassword()">
                @if(isSubmit) {
                <div class="spinner-border p-2 spinner-border-sm"></div>
                } @else {
                SAVE
                }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
