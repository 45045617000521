import { Component } from '@angular/core';
import { HeaderComponent } from '@template/green/header/header.component';
import { RegisterInputComponent } from '@componentpath/parts/register-input/register-input.component';
import { FooterComponent } from '@template/green/footer/footer.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { RegisterMobileComponent } from '@template/green//mobile/register-mobile/register-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';

@Component({
  selector: 'app-register',
  standalone: true,
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  imports: [HeaderSimpleComponent, RegisterInputComponent, FooterComponent, RegisterMobileComponent],
})
export class RegisterComponent {
  currentScreenSize: string = '';

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }
  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }
}
