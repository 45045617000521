import {
  Component,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
  PLATFORM_ID,
  Inject,
  Input,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';

@Component({
  selector: 'app-slideshow-bank',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [],
  templateUrl: './slideshow-bank.component.html',
  styleUrl: './slideshow-bank.component.scss',
})
export class SlideshowBankComponent implements OnInit {
  @Input() data: any;

  public config: SwiperOptions = {
    init: false,
    modules: [Pagination, Navigation, A11y, Mousewheel, Grid],
    slidesPerView: 3,
    mousewheel: true,
    loop: true,
    autoplay: {
      delay: 1000,
    },
    grid: {
      fill: 'row',
      rows: 3,
    },
    spaceBetween: 8,
    breakpoints: {
      575: {
        slidesPerView: 4,
        spaceBetween: 16,
        grid: {
          fill: 'row',
          rows: 4,
        },
      },
      767: {
        slidesPerView: 8,
        spaceBetween: 20,

        grid: {
          fill: 'row',
          rows: 8,
        },
      },
    },
  };
  bankList: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      register();
      this.bankList = this.data.filter((li: any) => li.type === 1);
    }
  }
}
