<div class="profile-body">
  <div class="promotion-body">
    <div class="profile-section">
      <div class="header">
        <div class="title-container">
          <div class="title">INFORMASI PRIBADI</div>
          <div class="subtitle">
            Detail data pribadi ini bersifat pribadi dan terjaga keamanan data
            pribadi anda.
          </div>
        </div>
      </div>
      <div class="input-container">
        <div class="input-item">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Please Enter First Name"  [(ngModel)]="first_name"/>
            <mat-icon matSuffix>person_pin</mat-icon>
          </mat-form-field>
        </div>
        <div class="input-item">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Please Enter Last Name" [(ngModel)]="last_name"/>
            <mat-icon matSuffix>person_pin</mat-icon>
          </mat-form-field>
        </div>
        <div class="input-item">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Please Enter Email" [(ngModel)]="email"/>
            <mat-icon matSuffix>mail_outline</mat-icon>
          </mat-form-field>
        </div>
        <div class="input-item">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nomor Telepon</mat-label>
            <input matInput placeholder="Please Enter Phone Number" [(ngModel)]="phone_number"/>
            <mat-icon matSuffix>phone</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="save-button"
      [class.disabled-div]="isSubmit"
      (click)="editProfileSubmit()"
      [ngClass]="{ 'disabled-div': isSubmit }">
          @if(isSubmit) {
          <div class="spinner-border p-2 spinner-border-sm"></div>
          } @else {
          SAVE
          }
      </div>
</div>

