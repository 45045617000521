import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, PLATFORM_ID, Inject, Input, AfterViewInit, ViewChild, ElementRef, afterNextRender, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { SwiperOptions } from 'swiper/types';
import { A11y, Autoplay, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { register } from 'swiper/element/bundle';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-newsticker',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, NgOptimizedImage, RouterModule],
  templateUrl: './newsticker.component.html',
  styleUrl: './newsticker.component.scss'
})
export class NewstickerComponent implements OnInit, AfterViewInit{
  @ViewChild('swiperNewsTicker') swiperNewsTickerRef!: ElementRef;
  // @ViewChild('cardTitleNews') cardTitleNews!: ElementRef;
  // @ViewChild('cardTitleTextNews') cardTitleTextNews!: ElementRef;
  @Input() data: any;

  cardTitleNewsWidth: any = 0;
  cardTitleTextNewsWidth: any = 0;

  public configNewstickerHome: SwiperOptions = {};
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private doc: Document, private cdr: ChangeDetectorRef) {
    this.swiperRender();
  }

  swiperRender(){
    afterNextRender((): void => {
      this.configNewstickerHome = {
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false,
        },
        direction: 'vertical',
        pagination: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      if (this.swiperNewsTickerRef){
        Object.assign(this.swiperNewsTickerRef.nativeElement, this.configNewstickerHome);
        this.swiperNewsTickerRef.nativeElement.initialize();
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log(this.data);
    }
  }
  ngAfterViewInit(): void {
    register();
    if (isPlatformBrowser(this.platformId)) {

      // setTimeout(() => {
      //   if (this.cardTitleNews) {
      //     this.cardTitleNewsWidth = this.doc.querySelector("#cardTitleNews")?.clientWidth;
      //     this.cdr.detectChanges();
      //   }
      //   if (this.cardTitleTextNews) {
      //     this.cardTitleTextNewsWidth = this.cardTitleTextNews.nativeElement.clientWidth;
      //     this.cdr.detectChanges();
      //   }
      // }, 1000);
    }
  }
}
