import { Component, Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { TransactionMobileComponent } from '@template/green/mobile/transaction-mobile/transaction-mobile.component';
import dayjs from 'dayjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretLeft, faCaretRight, faWallet } from '@fortawesome/free-solid-svg-icons';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule,DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
@Component({
  selector: 'app-transaction',
  standalone: true,
  imports: [TransactionMobileComponent,HeaderSimpleComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, MatFormFieldModule, MatSelectModule, CommonModule,MatPaginatorModule],
  templateUrl: './transaction.component.html',
  styleUrl: './transaction.component.scss'
})
export class TransactionComponent {


  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
   this.checkScreen();
  }

  currentScreenSize: string = '';

  faCaretLeft: IconProp = faCaretLeft;
  faCaretRight: IconProp = faCaretRight;
  faWallet: IconProp = faWallet;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  transaction: any = null;
  transactionDetail: any = null;
  transactionDetailLoading: any = null;

  type: any = '0';
  status: any = '';
  created_at: any = '';
  filter_created_at : any = '';

  part : string = 'default';

  //table variable
  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50];
  showPageSizeOptions = true;

  pageEvent!: PageEvent;

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else{
        this.currentScreenSize = 'desktop';
      }
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageIndex = e.pageIndex + 1;
    this.getData();
  }

  gotoDeposit(){
    this.router.navigate(['/deposit']);
  }

  clickBack() {
    this.transactionDetailLoading = null;
    this.transactionDetail = null;
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'default' } });
  }

  IDRFormat(value: number) {
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);
    return Intl.NumberFormat('id-ID').format(roundedValue);
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.doc.body.scrollTo(0, 0)
    }, 200);
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD');
  }

  hourFormat(value: string) {
    return dayjs(value + 'Z').format('HH:mm:ss');
  }

  pageChange(page: number) {
    this.pageIndex = page;
    this.getData();
  }

  typeChange(event: any) {
    this.transactionDetailLoading = null;
    this.pageIndex = 1;
    this.type = event.value;
    this.getData();
  }

  dateChange(event: any) {
    this.transactionDetailLoading = null;
    this.pageIndex = 1;
    this.filter_created_at = event.value;
    if (event.value === '') {
      this.created_at = '';
    } else if (event.value === 'today') {
      this.created_at = {
        start: dayjs().format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.value === 'yesterday') {
      this.created_at = {
        start: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      };
    } else if (event.value === 'monthNow') {
      this.created_at = {
        start: dayjs().startOf('month').format('YYYY-MM-DD'),
        end: dayjs().endOf('month').format('YYYY-MM-DD')
      };
    } else if (event.value === 'weekNow') {
      this.created_at = {
        start: dayjs().subtract(dayjs().day(), 'day').format('YYYY-MM-DD'),
        end: dayjs().add(6 - (dayjs().day()), 'day').format('YYYY-MM-DD')
      };
    } else if (event.value === 'weekBefore') {
      this.created_at = {
        start: dayjs().subtract((dayjs().day() + 7), 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(((6 - (dayjs().day())) + 7), 'day').format('YYYY-MM-DD')
      };
    }
    this.getData();
  }

  statusChange(event: any) {
    this.transactionDetailLoading = null;
    this.pageIndex = 1;
    this.status = event.value;
    this.getData();
  }

  typeLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "0": "All",
      "1": "Deposit",
      "2": "Withdrawal",
      "3": "Manual Adjustment In",
      "4": "Manual Adjustment Out",
      "5": "Auto Deposit"
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  statusLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "3": 'Rejected',
      "2": 'Approved',
      "1": 'On Hold',
      "0": 'Pending',
      "" : 'All'
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  getData() {
    this.scrollToTop();
    this.transaction = null;
    let query: any = {
      type: this.type,
      date: this.created_at
    }
    if (this.status !== '') {
      query['status'] = this.status;
    }
    this.request.getTransaction(this.auth.sessionValue, query, { page: this.pageIndex, limit: this.pageSize }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.length = v.data.total_records;
          this.transaction = v;
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  copyToClipboard(id: string): void {
    navigator.clipboard.writeText(id).then(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Berhasil Copy ID', type : 'default' },
        duration: 3000,
        panelClass: "notification-default"
      });
    }).catch(err => {
      console.error('Failed to copy ID to clipboard', err);
    });
  }

  clickDetail(id: string) {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'detail' } });
    this.transactionDetailLoading = true;
    this.request.getTransactionById(this.auth.sessionValue, id).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.scrollToTop();
          this.transactionDetailLoading = false;
          this.transactionDetail = v;
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'default'){
        this.part = 'default';
        this.transactionDetailLoading = null;
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.getData();

      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
