import { CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderComponent } from '@componentpath/header/header.component';
import { FooterComponent } from '@componentpath/footer/footer.component';
import { AllGamesComponent } from '@componentpath/parts/all-games/all-games.component';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { db } from '@componentpath/db/db';
import { CardGameComponent } from '@componentpath/parts/card-game/card-game.component';
import { RouterModule } from '@angular/router';
import {
  faAnglesRight,
  faMaximize,
  faMinimize,
  faMoneyBillTransfer,
  faStar,
  faWallet,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ListGamesComponent } from '@template/green/parts/list-games/list-games.component';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';

@Component({
  selector: 'app-game',
  standalone: true,
  imports: [
    HeaderSimpleComponent,
    FooterComponent,
    AllGamesComponent,
    CardGameComponent,
    RouterModule,
    FontAwesomeModule,
    CurrencyPipe,
    SlugNamePipe,
    ListGamesComponent,
  ],
  templateUrl: './game.component.html',
  styleUrl: './game.component.scss',
})
export class GameComponent {

  currentScreenSize: string = '';
  websiteJSONData: any = this.dataService.websiteJSONData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private request: RequestService,
    private auth: AuthService,
    private dataService: DataService,
    private _Activatedroute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
        this.showFirstOverlay = false;
      }
      else {
        this.currentScreenSize = 'desktop';
      }
      // console.log(this.currentScreenSize)
    });
  }

  faAnglesRight: IconProp = faAnglesRight;
  faMaximize: IconProp = faMaximize;
  faMinimize: IconProp = faMinimize;
  faStar: IconProp = faStar;
  faXmark: IconProp = faXmark;
  faWallet: IconProp = faWallet;
  faMoneyBillTransfer: IconProp = faMoneyBillTransfer;

  id: any = '';
  username: any = '';
  balance: any = undefined;
  subscription: Subscription = new Subscription();
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  timeCountSecond:number = 5;

  error: boolean = false;
  game: any = null;

  popularGamesData: any = null;

  providerJSONData: any = [];
  provider: any = '';
  showFirstOverlay: boolean = false;
  showMenu: boolean = false;

  gameURL!: SafeResourceUrl;
  preload: boolean = true;
  isFullscreen: boolean = false;
  promoJSONData: any = [];
  userGroup:any;

  getGames(gameType: string, sortBy: string) {
    if (isPlatformBrowser(this.platformId)) {
      let index = this.providerJSONData.findIndex((x: any) => x._id === this.game.provider);
      this.provider = this.providerJSONData[index];
      let prov = '';
      if (gameType === 'SL' || gameType === 'FH' || gameType === 'LC') {
        prov = this.game.provider;
      }
      this.request
        .gamesJSON(1, 10, [prov], [gameType], [''], '', sortBy, 'desc')
        .subscribe({
          next: (v) => {
            // console.log(v['items']);
            this.popularGamesData = [];
            let count = 0;
            for (const value of v['items']) {
              if (count === 5) {
                break;
              }
              if (value.game_id !== this.id) {
                count = count + 1;
                this.popularGamesData.push(value);
              }
            }
            // db.addGames(v['items']);
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {},
        });
    }
  }

  goBackHistory(){
    if (isPlatformBrowser(this.platformId)) {
      history.back();
    }
  }

  launchGames(): void {
    let isMobile = false;
    if(this.currentScreenSize === 'mobile'){
      isMobile = true;
    }
    this.request
      .launchGame(
        this.auth.sessionValue,
        this.game.provider,
        window.location.origin,
        this.id,
        isMobile
      )
      .subscribe({
        next: (v) => {
          // console.log(v);
          if (v.data === null) {
            this.error = true;
          } else {
            if (this.provider.publisher_code !== 'pg') {
              this.gameURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
                v.data.url as string
              );
            } else {
              const domParser = new DOMParser();
              let htmlElement = domParser.parseFromString(
                v.data.url as string,
                'text/html'
              );
              const headTag = htmlElement.getElementsByTagName('head')[0];
              let descriptionWebsiteString =
                '<meta http-equiv="cache-control" content="no-cache, no-store, must-revalidate" />';
              let descriptionWebsite = domParser.parseFromString(
                descriptionWebsiteString,
                'text/xml'
              );
              headTag.prepend(descriptionWebsite.documentElement);
              this.gameURL = this.domSanitizer.bypassSecurityTrustHtml(
                htmlElement.documentElement.outerHTML
              );
              // console.log(this.gameURL);
            }
            // this.testIframe(v.data.url);
            this.preload = false;
            this.showFirstOverlay = true;
            this.countDown();
          }
        },
        error: (e) => {
          this.error = true;
          console.log(e);
        },
        complete: () => {},
      });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      this.showFirstOverlay = false;
      this.showMenu = true;
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          let valTrunc = Math.floor(v);
          // var parts = valTrunc.toString().split('.');
          // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          // this.balance = parts.join('.');
          this.balance = valTrunc;
        },
      })
    );
  }

  //get only global false
  public filterPromotion() {
    if (this.data.promoJSONData['general']){
      for (const value of this.data.promoJSONData['general'] as any[]) {
        if (!value.global && this.promoJSONData.length < 2) {
          this.promoJSONData.push(value);
        }
      }
    }

  }

  testIframe(gameURL:string){
    const iframe = document.createElement("iframe");

    iframe.id = "iframe";
    if (this.provider.publisher_code !== 'pg') {
      iframe.src = gameURL;
    }
    else{
      iframe.srcdoc = gameURL;
    }
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.bottom = "0";
    iframe.style.width = "100vw";
    iframe.style.height = "100vh";
    iframe.style.border = "none";
    iframe.onload = function () {
      if (iframe.contentDocument){
        iframe.contentDocument.body.addEventListener('mouseup', Handler);
        function Handler() {
          alert('works');
        }
        iframe.contentDocument.oncontextmenu = ()=>{
          return false;
        }
      }
    };
    if(this.provider.publisher_code !== 'pg'){
      let iframeDiv = document.getElementById('iframeGame');
      if(iframeDiv){
        iframeDiv.appendChild(iframe);
      }

    }else {
      let iframeDiv = document.getElementById('iframeGameDoc');
      if (iframeDiv) {
        iframeDiv.appendChild(iframe);
      }
    }
  }

  countDown(){
    if (isPlatformBrowser(this.platformId)) {

      var x = setInterval( () => {
        this.timeCountSecond = this.timeCountSecond - 1;

        if (this.timeCountSecond < 0) {
          this.timeCountSecond = 0;
          clearInterval(x);
          this.showFirstOverlay = false;
          this.timeCountSecond = 5;
        }
      }, 1000);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.filterPromotion();
      // console.log(window.frames)
      this.subscription = this._Activatedroute.queryParams.subscribe((params) => {
        this.id = params['id'];
        this.providerJSONData = this.data.providerJSONData['records'];

        this.request.getGameDetails(this.auth.sessionValue, this.id).subscribe({
          next: (v) => {
            // console.log(v);
            this.game = v.data;
            this.launchGames();

            if (this.game.game_type !== 'ES') {
              this.getGames(this.game.game_type, 'popularity_point');
            } else if (this.game.game_type === 'ES') {
              this.getGames(this.game.game_type, 'created_at');
            }
          },
          error: (e) => {
            this.error = true;
            console.log(e);
          },
          complete: () => {},
        });
      });

      this.username = this.auth.userProfileData['login_id'];
      this.userGroup = this.auth.userGorupData;
      this.subscriptionData();
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
