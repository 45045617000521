@if(currentScreenSize === 'xSmall'){
<app-header-mobile></app-header-mobile>
} @else{
<app-header-simple></app-header-simple>
}
<div class="page-landing">
    @if(dataGameEvent){
    <div class="container-xxl p-0">
        <div class="container-landing">
            <div class="image-landing">
                <img [src]="dataGameEvent.content.image">
            </div>
            <div class="content">
                <div class="title" [innerHTML]="dataGameEvent.content.article.title"></div>
                <div class="paragraph" [innerHTML]="dataGameEvent.content.article.paragraph"></div>
            </div>
            <div class="games">
                <div class="title">Mainkan Sekarang</div>
                <div class="list-games">
                    @for (item of dataGameEvent.games; track $index) {
                    <div class="container-card-game">
                        <div class="card card-game" (click)="clickPlayCustom(item)">
                            <div class="card-game-top">
                                <div class="ratio ratio-1x1">
                                    @if(item['game_image'] !== '' && item['game_image'] !== undefined) {
                                    <img [src]="item['game_image']" [alt]="item['game_name']" class="card-img-top" />
                                    } @else() {
                                    <div class="no-image">No Image</div>
                                    }
                                </div>
                            </div>
                            <div class="card-title-card" id="{{item.game_id}}">
                                {{ item['game_name'] }}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    }
</div>