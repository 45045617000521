<div class="provider">
    <div class="container-xxl m-auto">
        <div class="button-title">
            PROVIDER
        </div>
        <div class="provider-inner">
            @for (item of data; track item._id;) {
            <div class="provider-inner-item">
                <div class="image">
                    <img [src]="item.image_url" [alt]="item.name">
                </div>
                <div class="info">
                    <div class="info-inner">
                        <div class="name">
                            {{item.name}}
                        </div>
                        <div class="play">
                            <a routerLink="/search" [queryParams]="{ provider: item._id }">
                                Play
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        <div class="see-all text-center">
            <a href="#">
                SEE ALL <fa-icon [icon]="faAnglesRight"></fa-icon>
            </a>
        </div>
    </div>
</div>