
  <div class="sidebar-wrapper sidebar-menu rounded-start py-2 h-100" id="sidebar-container" [ngClass]="{ 'sidebar-collapse': !showSidebar }">
    @if(showSidebar == false){
      <div id ="sidebar-mobile-container">
      <div class="icon-wrapper">
        <div class="icon-mobile icon-menu" >
          <img src="../template/green/assets/images/sidebar/menu.webp" class="img-fluid icon" width="22px"
          height="22px"/>
        </div>
        @if(isLoggedin){
          <img
          src="../../../template/green/assets/images/status-gold.png"
          class="img-fluid icon icon-mobile"
          width="25px"
          height="25px"
        />
        }
        <div class="icon-mobile" [class.active]="active === 'statement'">
          <!-- <img src="../template/green/assets/images/sidebar/Statement.webp" class="img-fluid icon" /> -->
          <fa-icon class="icon" [icon]="faCalendarMinus"></fa-icon>
        </div>
        <div class="icon-mobile" [class.active]="active === 'transaction'">
          <!-- <img src="../template/green/assets/images/sidebar/Transaksi.webp" class="img-fluid icon"/> -->
          <fa-icon class="icon" [icon]="faChartPie"></fa-icon>
        </div>
        <div class="mobile-separator">
          <div class="icon-mobile" [class.active]="active === 'saldo'">
            <!-- <img src="../template/green/assets/images/sidebar/Saldo.webp" class="img-fluid icon "/> -->
            <fa-icon class="icon" [icon]="faSackDollar"></fa-icon>
          </div>
        </div>
          <div class="icon-mobile" [class.active]="active === 'promotion'">
            <!-- <img src="../template/green/assets/images/sidebar/Promosi.webp" class="img-fluid icon"/> -->
            <fa-icon class="icon" [icon]="faGift"></fa-icon>
          </div>
        <div class="icon-mobile" [class.active]="active === 'event'">
          <!-- <img src="../template/green/assets/images/sidebar/Event.webp" class="img-fluid icon"/> -->
          <fa-icon class="icon" [icon]="faTrophy"></fa-icon>
        </div>
        <div class="mobile-separator">
          <div class="icon-mobil" [class.active]="active === 'referral'">
            <!-- <img src="../template/green/assets/images/sidebar/Referral.webp" class="img-fluid icon "/> -->
            <fa-icon class="icon" [icon]="faUserGroup"></fa-icon>
          </div>
        </div>
        <div class="icon-mobile" [class.active]="active === 'contact'">
          <!-- <img src="../template/green/assets/images/sidebar/Contact.webp" class="img-fluid icon"/> -->
          <fa-icon class="icon" [icon]="faPhone"></fa-icon>
        </div>
        <div class="icon-mobile" >
          <!-- <img src="../template/green/assets/images/sidebar/Livechat.webp" class="img-fluid icon"/> -->
          <fa-icon class="icon" [icon]="faHeadset"></fa-icon>
        </div>
      </div>
    </div>
    } @else {
      <div class="sidebar-menu-item m-3 menu-tablet" (click)="hideSidebar()">
        <img src="../template/green/assets/images/sidebar/menu.webp" class="img-fluid icon" width="22px"
        height="22px"/>
        <p class="sidebar-text">Menu</p>
      </div>
      @if(isLoggedin){
        <div class="tablet-container">
          <div class="profile-item m-3" >
            <a [routerLink]="['/profile']" [queryParams]="{ part: 'default' }">
          <div class="profile-tablet" >
              <div class="" >
                <div class="user-info align-items-center">
                  <img
                    src="../../../template/green/assets/images/status-gold.png"
                    width="85px"
                    height="80px"
                  />
                  <div class="detail-info ">
                    <div>
                      <p class="username">{{ username }}</p>
                      <p class="balance" *ngIf="balance === -1">Loading...</p>
                      <p class="balance" *ngIf="balance !== -1">IDR {{ balance }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        </div>
        <div class="desktop-container">
          <div class="profile-item m-3" >
            <a [routerLink]="['/profile']" [queryParams]="{ part: 'default' }">
          <div class="d-flex  w-100" >
              <div class="" >
                <div class="user-info align-items-center">
                  <img
                    src="../../../template/green/assets/images/status-gold.png"
                    width="85px"
                    height="80px"
                  />
                  <div class="detail-info ">
                    <img
                      src="../../../template/green/assets/images/rank/{{(userGroup.name).toLowerCase()}}.webp"
                      width="40px"
                      height="12px"
                    />
                    <div>
                      <p class="username">{{ username }}</p>
                      <p class="balance" *ngIf="balance === -1">Loading...</p>
                      <p class="balance" *ngIf="balance !== -1">IDR {{ balance }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="separator">
              <a  class="edit-profile-container d-flex justify-content-evenly align-items-center">
                <div class="d-flex align-items-center w-100">
                  <fa-icon class="icon mx-2" [icon]="faPenToSquare" size="lg"></fa-icon>
                  <p class="edit-profile text-wrap">Edit Profile</p>
                </div>
              </a>
            </div>
          </a>
        </div>
        </div>
      }

      <a [routerLink]="['/statement']" [queryParams]="{ part: 'monthly' }">
        <div class="sidebar-menu-item m-3" [class.active]="active === 'statement'">
          <!-- <img src="../template/green/assets/images/sidebar/Statement.webp" class="img-fluid icon"/> -->
          <fa-icon class="icon" [icon]="faCalendarMinus" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Statement</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
      <a [routerLink]="['/transaction']" [queryParams]="{ part: 'default' }">
        <div
          class="sidebar-menu-item m-3"
          [class.active]="active === 'transaction'"
        >
          <!-- <img src="../template/green/assets/images/sidebar/Transaksi.webp" class="img-fluid icon"/> -->
          <fa-icon class="icon" [icon]="faChartPie" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Transaksi</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
      <a [routerLink]="['/saldo']" [queryParams]="{ part: 'default' }">
        <div class="sidebar-menu-item m-3" [class.active]="active === 'saldo'">
          <!-- <img src="../template/green/assets/images/sidebar/Saldo.webp" class="img-fluid icon"/> -->
           <fa-icon class="icon" [icon]="faSackDollar" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Saldo</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
      <div class="divider mb-3"></div>
      <a [routerLink]="['/promotion']" [queryParams]="{ part: 'default' }">
        <div class="sidebar-menu-item m-3" [class.active]="active === 'promotion'">
          <!-- <img src="../template/green/assets/images/sidebar/Promosi.webp" class="img-fluid icon"/> -->
           <fa-icon class="icon" [icon]="faGift" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Promosi</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
      <a [routerLink]="['/event']" [queryParams]="{ part: 'default' }">
        <div class="sidebar-menu-item m-3" [class.active]="active === 'event'">
          <!-- <img src="../template/green/assets/images/sidebar/Event.webp" class="img-fluid icon"/> -->
           <fa-icon class="icon" [icon]="faTrophy" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Event</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
      <a [routerLink]="['/referral']" [queryParams]="{ part: 'default' }">
        <div class="sidebar-menu-item m-3" [class.active]="active === 'referral'">
          <!-- <img src="../template/green/assets/images/sidebar/Referral.webp" class="img-fluid icon"/> -->
           <fa-icon class="icon" [icon]="faUserGroup" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Referral</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
      <div class="divider mb-3"></div>
      <a routerLink="/contact">
        <div class="sidebar-menu-item m-3" [class.active]="active === 'contact'">
          <!-- <img src="../template/green/assets/images/sidebar/Contact.webp" class="img-fluid icon"/> -->
           <fa-icon class="icon" [icon]="faPhone" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Hubungi Kami</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
        </a>
        <a href="{{livechatURL}}" target="_blank">
        <div class="sidebar-menu-item m-3">
          <!-- <img src="../template/green/assets/images/sidebar/Livechat.webp" class="img-fluid icon"/> -->
           <fa-icon class="icon" [icon]="faHeadset" [size]="'lg'"></fa-icon>
          <p class="sidebar-text">Live chat</p>
          <!-- <img src="../template/green/assets/images/sidebar/Selected.webp" class="img-fluid arrow-right"/> -->
          <fa-icon class="arrow-right" [icon]="faCircleChevronRight" [size]="'sm'"></fa-icon>
        </div>
      </a>
    }
  </div>

