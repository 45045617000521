import {
  Component,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
  PLATFORM_ID,
  Inject,
  Input,
  afterNextRender,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { SwiperOptions } from 'swiper/types';
import { A11y, Autoplay, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { DataService } from '@componentpath/services/data.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-list-providers',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, RouterModule, SlugNamePipe, NgOptimizedImage, FontAwesomeModule],
  templateUrl: './list-providers.component.html',
  styleUrl: './list-providers.component.scss',
})
export class ListProvidersComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @ViewChild('swiperProviders') swiperProviders!: ElementRef;

  websiteJSONData: any = this.dataService.websiteJSONData;
  faArrowRight: IconProp = faArrowRight;

  public configSwiperProvider: SwiperOptions = { };
  providerList: any;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
  ) {
    this.swiperRender();
  }

  swiperRender() {
    afterNextRender((): void => {
      this.configSwiperProvider = {
        mousewheel: true,
        loop: true,
        modules:[Autoplay],
        speed: 1500,
        slidesPerView: 'auto',
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        spaceBetween: 10,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      Object.assign(this.swiperProviders.nativeElement, this.configSwiperProvider); // Add parameters to the Swiper
      this.swiperProviders.nativeElement.initialize(); // Init Swiper
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // this.bankList = this.data.filter((li: any) => li.type === 1);
      // console.log(this.data);
      this.providerList = this.data.records;
      this.cdr.detectChanges();
    }
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      register();
    }
  }
}
