import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[appSwiper]',
  standalone: true
})
export class SwiperDirective implements AfterViewInit {

  private readonly swiperElement: SwiperContainer;

  @Input('config')
  config?: SwiperOptions;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef<SwiperContainer>
  ) {
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    if(isPlatformBrowser(this.platformId)) {
      Object.assign(this.swiperElement, this.config);
      this.swiperElement.initialize();
    }
  }
}
