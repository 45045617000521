<div class="promotion-mobile-container">
  <div class="header-container">
    <fa-icon [icon]="faArrowLeft" class="back-icon" (click)="goBack()"></fa-icon>
    <div class="title">TRANSAKSI</div>
  </div>
  <div class="promotion-body">
    @if(this.transactionDetailLoading === null){
      <div class="promotion-content">
        <div class="filter-container">
          <div class="filter-left" (click)="openFilter()">
            <img src="../../template/green/assets/images/mobile/Filter.webp" class="img-fluid filter-icon"/>
              <div class="filter-text">Filter</div>
          </div>
          <div class="filter-right">
            <div class="filter-group">
              <div class="filter-name">{{typeLabel(type)}}</div>
              <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearType()"></fa-icon>
            </div>
            <div class="filter-group">
              <div class="filter-name">{{getValueFromDateList(selectedDate)}}</div>
              <fa-icon [icon]="faCircleXmark" class="close-button" (click)="clearDate()"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="promotion-table" id="scrollableTable" (scroll)="onScroll($event)">
        @if(transaction !== null ){
          <div class="mt-2"></div>
          @if(transaction.length != 0){
            @for (item of transaction; track item;) {
              <div class="transaction-list-container mt-2">
                <div class="list-header">
                  <div class="text" [ngClass]="{
                    'negative-amount': item.balance < 0,
                    'positive-amount': item.balance > 0
                  }"><img src="../../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(item._id)"/>{{ typeLabel(item.type) }}</div>
                  <div class="show-detail" (click)="clickDetail(item._id)">
                    Show Detail <fa-icon [icon]="faCaretRight"></fa-icon>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-item">
                    <dl>
                      <dt>Tanggal Transaksi</dt>
                      <dd><span class="colon">:</span>{{ dateFormat(item.created_at) }}</dd>

                      <dt>Jumlah</dt>
                      <dd><span class="colon">:</span><span>IDR {{ IDRFormat(item.amount) }}</span></dd>

                      <dt>Status</dt>
                      <dd><span class="colon">:</span><span [ngClass]="{
                        'negative-amount': item.status == 3,
                        'positive-amount': item.status == 2,
                        'pending-status-color': item.status == 0
                      }">{{ statusLabel(item.status) }}</span></dd>
                    </dl>
                  </div>
                </div>
              </div>
            }
            @if(pageIndex+1 <= maxPage){
              <div class="d-flex justify-content-center align-content-center">
                @if(isLoading){
                  <div class="spinner-border"></div>
                }
              </div>
            }
          } @else {
            <div class="no-data-content">
              <img src="../../template/green/assets/images/mobile/no_data.webp" class="img-fluid no-data-icon"/>
              <div class="no-data-title">
                No Data Available
              </div>
              <div class="no-data-subtitle">
                There is no data to show you right now
              </div>
            </div>
          }
        } @else {
          <div class="d-flex justify-content-center align-content-center">
              <div class="spinner-border m-auto"></div>
          </div>
        }
      </div>
    } @else {
      @if(this.transactionDetailLoading === true) {
        <div class="row mb-4 mx-2 p-4">
          <div class="spinner-border m-auto"></div>
      </div>
      } @else {
        <div class="promotion-content">
          <div class="detail">
            @if(transactionDetail.data.status == 3 && transactionDetail.data.processing_note != null){
              <div class="reject-reason" >
                <span class="reason-text"><img src="../../template/green/assets/images/mobile/warning-sign.webp" class="img-fluid reject-icon"/>{{transactionDetail.data.processing_note}}</span>
              </div>
            }

            <div class="status-container">
              <div class="text" ><img src="../../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(transactionDetail.data._id)"/>{{ typeLabel(transactionDetail.data.type) }}</div>
              <div class="status-box" [ngClass]="{
                'warning': transactionDetail.data.status == 3,
                'success': transactionDetail.data.status == 2,
                'pending': transactionDetail.data.status == 0
              }">
                <span class="status-text">{{ statusLabel(transactionDetail.data.status) }}</span>
              </div>
            </div>

            <div class="transaksi-content">
              @if(transactionDetail.data.type == 2){
                <div class="rekening-penerima">Rekening Penerima</div>
                <div class="list-item">
                  <dl>
                    <dt>Nama Rekening</dt>
                    <dd><span class="colon">:</span><span class="value">@if(this.transactionDetail.data.to_bank.account_name){
                      {{this.transactionDetail.data.to_bank.account_name}}
                    }</span></dd>

                    <dt>Bank Rekening</dt>
                    <dd><span class="colon">:</span><span class="value">{{this.transactionDetail.data.to_bank.system_bank.name}}</span></dd>

                    <dt>Nomor Rekening</dt>
                    <dd><span class="colon">:</span><span class="value">{{this.transactionDetail.data.to_bank.account_number}}</span></dd>
                  </dl>
                </div>
              } @else if(transactionDetail.data.type == 1){
                  @if(this.transactionDetail.data.to_bank){
                    <div class="bank-container">
                      <div class="bank-title">Rekening Tujuan</div>
                      <div class="list-item">
                        <dl>
                          <dt>Nama Rekening</dt>
                          <dd><span class="colon">:</span><span class="value">@if(this.transactionDetail.data.to_bank.account_name){
                            {{this.transactionDetail.data.to_bank.account_name}}
                          }</span></dd>

                          <dt>Bank Rekening</dt>
                          <dd><span class="colon">:</span><span class="value">{{this.transactionDetail.data.to_bank.system_bank.name}}</span></dd>

                          <dt>Nomor Rekening</dt>
                          <dd><span class="colon">:</span><span class="value">{{(this.transactionDetail.data.to_bank.account_number)}}</span></dd>
                        </dl>
                      </div>
                    </div>
                  }
                  @if(this.transactionDetail.data.from_bank){
                    <div class="bank-container">
                      <div class="bank-title">Rekening Pengirim</div>
                      <div class="list-item">
                        <dl>
                          <dt>Nama Rekening</dt>
                          <dd><span class="colon">:</span><span class="value">{{this.transactionDetail.data.from_bank.account_name}}
                          </span></dd>

                          <dt>Bank Rekening</dt>
                          <dd><span class="colon">:</span><span class="value">{{this.transactionDetail.data.from_bank.system_bank.name}}</span></dd>

                          <dt>Nomor Rekening</dt>
                          <dd><span class="colon">:</span><span class="value">{{(this.transactionDetail.data.from_bank.account_number)}}</span></dd>
                        </dl>
                      </div>
                    </div>
                  }
              } @else{
                <div class="rekening-penerima">Rekening Tujuan</div>
                <div class="list-item">
                  <dl>
                    <dt>Login ID</dt>
                    <dd><span class="colon">:</span><span class="value">@if(this.transactionDetail.data.to_login_id){
                        {{this.transactionDetail.data.to_login_id}}
                        }</span></dd>
                
                    <dt>Type</dt>
                    <dd><span class="colon">:</span><span class="value">Auto Deposit</span>
                    </dd>
                  </dl>
                </div>
              }
            </div>
            <div class="col-12">
              <div class="transaction-box d-flex justify-content-end">
                <div class=" payment-info">
                  <table class="text-right">
                    <tr>
                      <td>Jumlah :</td>
                      <td>Rp {{IDRFormat(this.transactionDetail.data.amount)}}</td>
                    </tr>
                    @if(this.transactionDetail.data.transaction_fee != 0){
                      <tr>
                        <td>Biaya Transaksi :</td>
                        <td [ngClass]="{
                          'negative-amount': this.transactionDetail.data.transaction_fee < 0,
                          'positive-amount': this.transactionDetail.data.transaction_fee > 0
                        }">{{customIDRFormat(this.transactionDetail.data.transaction_fee)}}</td>
                      </tr>
                    }
                    <tr>
                      <td class="total-text">Total Diterima :</td>
                      <td class="total-text">Rp {{IDRFormat(this.transactionDetail.data.amount -
                        this.transactionDetail.data.transaction_fee)}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="riwayat-container">
              <div class="riwayat-text">
                Riwayat
              </div>
              <div class="table-head">
                <div class="col-4">Status</div>
                <div class="col-4">Tanggal</div>
                <div class="col-4">IP Address</div>
              </div>
              @for (item of this.transactionDetail.data.histories; track item;) {
                <div class="riwayat-item">
                  <span [ngClass]="{
                    'negative-amount': item.status == 3,
                    'positive-amount': item.status == 2,
                    'pending-status-color': item.status == 0
                  }" class="col-4">{{ statusLabel(item.status) }}</span>
                  <span class="col-4">{{ dateFormat(item.created_at) }} <br> {{ hourFormat(item.created_at) }}</span>
                  <span class="col-4 custom-wrap">{{ item.ip }}</span>
                </div>
              }
            </div>
          </div>
        </div>
      }
    }

  </div>
</div>
