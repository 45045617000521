import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'formatDateId',
  standalone: true,
})
export class FormatDateIdPipe implements PipeTransform {
  transform(value: number | string): string {
    return new Date(value).toLocaleDateString('id-ID', {
      weekday: 'short',
      year: '2-digit',
      month: 'short',
      day: 'numeric',
    });
  }
}

@Pipe({
  name: 'formatDateFullId',
  standalone: true,
})
export class FormatDateFullIdPipe implements PipeTransform {
  transform(value: number | string): string {
    return dayjs(value + 'Z').format('DD/MMM/YYYY hh:mm A');
  }
}

@Pipe({
  name: 'formatDateFull2Id',
  standalone: true,
})
export class FormatDateFull2IdPipe implements PipeTransform {
  transform(value: number | string): string {
    return dayjs(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MMM/YYYY HH:mm:ss');
  }
}

@Pipe({
  name: 'formatDateFull3Id',
  standalone: true,
})
export class FormatDateFull3IdPipe implements PipeTransform {
  transform(value: number | string): string {
    return dayjs(value, 'YYYY-MM-DD').format('DD/MMM/YYYY');
  }
}