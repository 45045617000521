import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumberId',
  standalone: true
})
export class FormatNumberIdPipe implements PipeTransform {

  transform(value: number | string): string {
    return new Intl.NumberFormat('id-ID').format(Number(value));
  }

}

@Pipe({ name: 'splitNumber', standalone: true })
export class SplitNumberPipe implements PipeTransform {
  constructor() { }
  transform(value: string): string {
    var parts: any = value.toString();
    parts = parts.match(/.{1,4}/g);
    return parts.join(" - ");
  }
}
