import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  PLATFORM_ID,
  Inject,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  faMagnifyingGlass,
  faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '../card-game/card-game.component';
import { RouterModule } from '@angular/router';
import { db } from '@componentpath/db/db';
import { RequestService } from '@componentpath/services/request.service';
import {
  NgbDropdownModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-list-games',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CardGameComponent,
    NgbDropdownModule,
    MatCheckboxModule,
    FormsModule,
    NgbCollapseModule,
  ],
  templateUrl: './list-games.component.html',
  styleUrl: './list-games.component.scss',
})
export class ListGamesComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  @Input() loadMore: boolean = false;
  @Input() minHeight: boolean = false;

  @Input() page: number = 1;
  @Input() perPage: number = 10;
  @Input() tags: Array<any> = [''];
  @Input() data: Array<any> = [];

  totalPages: number = 0;
  tagLists: Array<any> = [
    {
      name: 'ALL GAMES',
      type: '',
    },
    {
      name: 'CRASH GAMES',
      type: 'CRASH',
    },
    {
      name: 'SLOTS',
      type: 'SLOT',
    },
    {
      name: 'LIVE GAME SHOWS',
      type: 'LIVE-GAME',
    },
    {
      name: 'ARCADE',
      type: 'ARCADE',
    },
    {
      name: 'SPORTBOOK',
      type: 'SPORTBOOK',
    },
    {
      name: 'JACKPOT SLOTS',
      type: 'JACKPOT',
    },
    {
      name: 'BONANZA GAMES',
      type: 'BONANZA',
    },
  ];

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faAnglesRight: IconProp = faAnglesRight;
}
