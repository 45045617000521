import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DataService } from '@componentpath/services/data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-popup-app',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterModule, MatDialogModule, QRCodeModule],
  templateUrl: './popup-app.component.html',
  styleUrl: './popup-app.component.scss'
})
export class PopupAppComponent {
  faXmark: IconProp= faXmark;
  qrCodeDownloadLink: SafeUrl = '';
  websiteJSONData: any = this.dataService.websiteJSONData;

  constructor(
    private dataService: DataService
  ){

  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }
}
