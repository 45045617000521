import { Component, inject, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-referral-popup-claim',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './referral-popup-claim.component.html',
  styleUrl: './referral-popup-claim.component.scss'
})
export class ReferralPopupClaimComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService
  ) { }

  getReferralPlayer: any = null;
  modal = inject(NgbActiveModal);
  popupClose() {
    const userProfileData: any = this.auth.userProfileData;
    this.request.getReferralPlayer(this.auth.sessionValue, userProfileData._id).subscribe({
      next: (v) => {
        this.modal.close(v);
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    });
  }

  @Input() max: number = 0;
  total: number = 0;

  loginSubmit: boolean = false;

  successMessage: string = '';
  errorMessage: string = '';

  claimedReferral: any = null;
  page: number = 1;
  pagination: any = [];

  pageChange(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.request.claimedReferral(this.auth.sessionValue, { page: this.page.toString(), limit: '20' }).subscribe({
      next: (v) => {
        this.claimedReferral = v;
        this.pagination = [];
        for (let i = 1; i <= this.claimedReferral.data.max_page; i++) {
          this.pagination.push(i);
        }
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    });
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('DD/MMM/YYYY hh:mm A');
  }

  IDRFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(value);
  }

  checkMax(): void {
    if (this.total > this.max) {
      this.total = this.max;
    }
  }

  clickMax(): void {
    this.total = this.max;
  }

  claimReferralSubmit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loginSubmit = true;
      this.successMessage = '';
      this.errorMessage = '';
      if (this.total === 0) {
        this.errorMessage = 'Total must be greater than 0.';
      } else {
        const userProfileData: any = this.auth.userProfileData;
        this.request.claimReferral(this.auth.sessionValue, this.total.toString(), userProfileData._id).subscribe({
          next: (v) => {
            if (v.code == 200) {
              this.loginSubmit = false;

              this.successMessage = 'Claim is Success.';

              const userProfileData: any = this.auth.userProfileData;
              setTimeout(() => {
                this.popupClose();
              }, 2000);
            }
          },
          error: (e) => {
            this.loginSubmit = false;
            this.errorMessage = e.error.messages[0].message;
          }
        });
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getData();
    }
  }
}
