import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeId from '@angular/common/locales/id';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeId);

export const appConfig: ApplicationConfig = {
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'id-ID' }, { provide: LOCALE_ID, useValue: 'id-ID' }, provideRouter(routes), provideClientHydration(), provideHttpClient(withFetch()), provideAnimationsAsync('animations')]
};
