<div class="page-search">
    @if(currentScreenSize !== 'xSmall'){
    <div class="side-menu" (click)="checkParams()">
        @if(currentScreenSize === 'smallOne'){
        <div class="container-side-menu content-small" [ngClass]="!showSideBar ? 'd-block':'d-none'"
            (click)="showClick()">
            <div class="d-flex flex-column px-3 container-menu-logo">
                <div class="section-content">
                    <div class="d-flex align-items-center gap-3">
                        <img [src]="websiteJSONData.header.favicon" class="section-img" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column px-3">
                <div class="section-content">
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsCat === 'SL' }">
                        <div class="active-sub-menu {{currentParamsCat}}"
                            [ngClass]="currentParamsCat === 'SL'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-slots.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'JACKPOT' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'JACKPOT'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-jackpot.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'CRASH' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'CRASH'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-crash.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'BONANZA' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'BONANZA'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-bonanza.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'MEGAWAY' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'MEGAWAY'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-megaway.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'BUYIN' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'BUYIN'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-buyin.png" class="section-img" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column my-4 px-3">
                <div class="section-content">
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsCat === 'LC' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsCat === 'LC'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-casino.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'GAMESHOW' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'GAMESHOW'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-gameshow.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'BACCARAT' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'BACCARAT'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-baccarat.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'BLACKJACK' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'BLACKJACK'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-blackjack.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsTag === 'ROULETTE' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsTag === 'ROULETTE'? 'd-block':'d-none'">
                        </div>
                        <img src="../template/green/assets/images/menu-roulette.png" class="section-img" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column px-3">
                <div class="section-content">
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsCat === 'FH' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsCat === 'FH'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-arcade.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsCat === 'SB' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsCat === 'SB'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-sportbook.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3"
                        [ngClass]="{ 'active-section': currentParamsCat === 'ES' }">
                        <div class="active-sub-menu" [ngClass]="currentParamsCat === 'ES'? 'd-block':'d-none'"></div>
                        <img src="../template/green/assets/images/menu-esports.png" class="section-img" />
                    </div>
                </div>
            </div>
        </div>
        <app-sidebar class="d-block {{currentScreenSize}}" [ngClass]="showSideBar ? 'd-block':'d-none'"
            (click)="hideClick()"></app-sidebar>
        } @else{
        <app-sidebar class="d-block {{currentScreenSize}}"></app-sidebar>
        }
    </div>
    }
    <div class="content-area">
        @if(currentScreenSize === 'smallOne'){
        <div class="overlay" [ngClass]="showSideBar ? 'd-block':'d-none'" (click)="hideClick()"></div>
        }
        @if(currentScreenSize === 'xSmall'){
        <app-header-mobile [hideNews]="true"></app-header-mobile>
        <app-all-games [seeAll]="false" [provider]="true" />
        }@else{
        <app-header-search />
        <app-slideshow1 [data]="slideshowData" />
        <app-all-games [seeAll]="false" [provider]="true" />
        }
        <app-footer />
    </div>
    <!-- <mat-drawer-container class="sidenav" (backdropClick)="backdropClick()">
    @if(currentScreenSize !== 'xSmall' && currentScreenSize !==''){
        <mat-drawer #drawer class="sidenav-content" [ngClass]="currentScreenSize === 'smallOne' ? 'sidenav-content-small': currentScreenSize  === 'desktop' ? '' : 'sidenav-content-medium' " [mode]="currentSideBarMode" [opened]="true" [disableClose]="true" (openedStart)="openedSideNav()" (closedStart)="closedSideNav()">
          @if(currentScreenSize === 'desktop'){
            <app-sidebar class="d-block {{currentScreenSize}}"></app-sidebar>
          } @else{
            @if(isSideNavOpened){
              @if(currentSideBarMode === 'side'){
                <app-sidebar class="d-block {{currentSideBarMode}}"></app-sidebar>
              }@else{
                <app-sidebar class="d-block {{currentSideBarMode}}" (click)="backdropClick()"></app-sidebar>
              }
            } 
            @if(!isSideNavOpened) {
              <div class="container-side-menu" (click)="showClick()">
              
                <div class="d-flex flex-column my-4 px-3">
                  @if(currentScreenSize !== "desktop"){
                  <div class="section-content">
                    <div class="d-flex align-items-center gap-3">
                      <img [src]="websiteJSONData.header.favicon" class="section-img" />
                    </div>
                  </div>
                  }
                </div>
                <div class="d-flex flex-column px-3">
                  <div class="section-content">
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-slots.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-jackpot.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-crash.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-bonanza.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-megaway.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-buyin.png" class="section-img" />
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column my-4 px-3">
                  <div class="section-content">
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-casino.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-gameshow.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-baccarat.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-blackjack.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-roulette.png" class="section-img" />
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column px-3">
                  <div class="section-content">
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-arcade.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-sportbook.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-esports.png" class="section-img" />
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <img src="../template/green/assets/images/menu-sportbook.png" class="section-img" />
                    </div>
                  </div>
                </div>
              </div>
            }
          }
        </mat-drawer>
      }
    <mat-drawer-content>
        @if(currentScreenSize === 'xSmall'){
          <app-header-mobile [hideNews]="true"></app-header-mobile>
          <app-all-games [seeAll]="false" />
        }@else{
          <app-header-search />
          <app-slideshow1 [data]="slideshowData" />
          <app-all-games [seeAll]="false" />
        }
    </mat-drawer-content>
  </mat-drawer-container> -->
</div>