@if(currentScreenSize != 'mobile'){
<div class="page-promotion">
  <app-header-simple />
  <div class="page-inner">
    <div class="container-xxl row m-auto">
      <div class="promotion-body">
        <div class="sidebar-column">
          <app-sidebar-menu [active]="'event'" />
        </div>
        <div class="col-9 pl-0 promotion-container content-container" id="promotion-container">
          <div class=" promotion-inner h-100">
            <div class="promotion-title-custom">
            @if(promo == null){
              <div class="title-promotion">Event</div>
            } @else {
              <div class="title-promotion"><span class="custom-opacity">Event ></span> Detail Event</div>
            }
            </div>
            <div class="page-inner-container">
              @if(promo !== null) {

                <div class="promotion-container pt-2">
                  <img
                    class="my-4 rounded"
                    [src]="promo.banner_url"
                    [alt]="promo.title"
                  />
                  <div class="promotion-container-description p-4">
                    <div class="title">
                      {{ promo.title }}
                    </div>
                    <div class="content" [innerHTML]="promo.content | safeHtml"></div>
                    <div class="row">
                      <div class="col-12 pt-4">
                        <button class="btn btn-back text-white m-0" (click)="clickBack()">
                          LIHAT SEMUA PROMO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                } @else {
                <div class="promotion-container row pt-4">
                  @for (item of promoJSONData; track item._id;) {
                  @if(item !== undefined && item.thumbnail_url !== '') {
                  <div class="col-6 mb-4">
                    <img
                      [src]="item.thumbnail_url"
                      [alt]="item.title"
                      (click)="promoDetail(item._id)"
                      class="rounded"
                    />
                  </div>
                  } }
                </div>
                }
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <app-footer [data]="footerData" />
  </div>
</div>
} @else {
<app-event-mobile />
}
