import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { ProfileInformationComponent } from "@template/green/parts/profile-information/profile-information.component";
import { PinComponent } from "@template/green/parts/pin/pin.component";
import { PasswordComponent } from "@template/green/parts/password/password.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthService } from '@componentpath/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ProfileMobileComponent } from '@template/green/mobile/profile-mobile/profile-mobile.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ProfileMobileComponent,CommonModule, HeaderSimpleComponent, ProfileInformationComponent, PinComponent, PasswordComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';


  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  websiteJSONData: any = this.data.websiteJSONData;
  hide = true;
  hidePin = true;
  part: string = 'default';

  subscription: Subscription = new Subscription();
  profile: any = null;
  security:any = null;

  isEditable:boolean = false;

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else{
        this.currentScreenSize = 'desktop';
      }
    });
  }

  changePart(part: string) {
    this.part = part;
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: this.part } });
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.profile = this.auth.userProfileData;
        this.security = this.auth.userSecurityData;
      }
    }));
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.profile = this.auth.userProfileData;
      }
    }));

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.part = params['part'] || 'default';
      this.profile = this.auth.userProfileData;
    });
    if (isPlatformBrowser(this.platformId)) {
      this.isEditable = this.data.websiteConfigData.editProfile as boolean;
      for (const value of this.footerJSONData.records) {
        this.subscriptionData();
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}


