import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-password',
  standalone: true,
  imports: [MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss'
})
export class PasswordComponent {
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmationPassword = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
  ) { }

  isSubmit: boolean = false;
  userSecurityData: any = null;

  successMessage: string = '';
  errorMessage: string = '';
  password: Array<any> = ['', '', ''];

  editPassword(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.password[0] || !this.password[1] || !this.password[2]) {
        this.errorMessage = 'Password or New Password or Confirm Password is Required.';
        this.isSubmit = false;
        return;
      } else {
        if (this.password[1] !== this.password[2]) {
          this.errorMessage = 'New Password & Confirm Password is Not Same.';
          this.isSubmit = false;
          return;
        } else {
          const userProfileData: any = this.auth.userProfileData;
          this.request.editPassword(this.auth.sessionValue, userProfileData._id, this.password[1], this.password[0]).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.router.navigate(['/profile'],{ queryParams: { part: 'default' } });
                this.successMessage = 'Edit Password is Success.';
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
            }
          })
        }
      }
    }
  }
}
