<div class="page-home">
    <app-header-mobile></app-header-mobile>
    <!-- <a [routerLink]="['/event']" class="event-bar">
        <div class="event-button">
            <img class="trophy" src="../template/green/assets/images/header/trophy-uno.gif" />
            @if(websiteJSONData.misc.event_image === 'ironman'){
                <img class="event" src="../template/green/assets/images/header/event_bg_ironman_mobile.webp" />
            }@else{
                <img class="event" src="../template/green/assets/images/header/event section mobile.png" />
            }
        </div>
    </a> -->
    <app-slideshow-home [data]="slideshowJSONData"></app-slideshow-home>
    <div class="home-middle-mobile d-md-none">
        @if(!hideLiveScore){
            <div class="big-match my-4">
                <div class="big-match-header">BIG MATCH</div>
                <div class="latest-news">
                    <div class="latest-news-left">
                        <img src="../template/green/assets/images/ic-bullhorn.png" width="12" height="10" />
                    </div>
                    <div class="latest-news-right">
                        <div class="latest-news-right-inner">
                            <span class="newsticker-item">Contrary to popular belief, Lorem Ipsum is not simply random
                                text. It has roots in a piece of classical Latin literature from
                                45 BC, making it over 2000 years old. Richard McClintock, a Latin
                                professor at Hampden-Sydney College in Virginia, looked up one of
                                the more obscure Latin words, consectetur, from a Lorem Ipsum
                                passage, and going through the cites of the word in classical
                                literature, discovered the undoubtable source. Lorem Ipsum comes
                                from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                                Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
                                BC. This book is a treatise on the theory of ethics, very popular
                                during the Renaissance. The first line of Lorem Ipsum, "Lorem
                                ipsum dolor sit amet..", comes from a line in section
                                1.10.32.</span>
                            <span class="marquee-sep"></span>
                        </div>
                    </div>
                </div>
                <div class="big-match-body">
                    <p class="title">{{ bigMatchData.league_name }}</p>
                    <div class="d-flex align-items-center justify-space-between">
                        <div>
                            <img [src]="bigMatchData.home_team_image" width="90" height="90" />
                            <p class="big-match-team-name">{{ bigMatchData.home_team_name }}</p>
                        </div>
                        <div class="w-25 d-flex flex-column align-items-center mx-2">
                            <span class="d-flex align-items-center">
                              @if(!bigMatchData.live){ VS } @else {
                                <p
                                  class="big-match-score"
                                  [ngStyle]="{
                                    opacity: getOpacity(
                                      bigMatchData.home_score,
                                      bigMatchData.away_score,
                                      'home'
                                    )
                                  }"
                                >
                                  {{ bigMatchData.home_score }}
                                </p>
                                <p class="big-match-score mx-2">:</p>
                                <p
                                  class="big-match-score"
                                  [ngStyle]="{
                                    opacity: getOpacity(
                                      bigMatchData.home_score,
                                      bigMatchData.away_score,
                                      'away'
                                    )
                                  }"
                                >
                                  {{ bigMatchData.away_score }}
                                </p>
                                }
                            </span>
    
                            <div class="d-flex align-items-center text-white my-2">
                                <img src="../template/green/assets/images/ic-timer.png" width="8" height="9" />
                                @if(bigMatchData.phase == 7){
                                  <span class="min-live">Penalty</span>
                                  } @else if(bigMatchData.phase == 9){
                                  <span class="min-live">Fulltime</span>
                                  } @else if(bigMatchData.phase == 3 || bigMatchData.phase == 5){
                                  <span class="min-live">Halftime</span>
                                  }@else {
                                  <span #shakeSpan class="min-live"
                                    >{{ getMinuteDifference(bigMatchData.match_start_at) }}’</span
                                  >
                                  }
                            </div>
                            <img src="../../../template/green/assets/images/Live.png" width="40" height="10" />
                        </div>
                        <div>
                            <img [src]="bigMatchData.away_team_image" width="92" height="92" />
                            <p class="big-match-team-name">{{ bigMatchData.away_team_name }}</p>
                        </div>
                    </div>
                    <button class="w-100 place-bet">BET NOW</button>
                    <div class="d-flex justify-content-center align-items-center mt-1 big-match-list-logo">
                        <img src="../../../template/green/assets/images/SBOBET_Logo.png" width="66" height="16" />
                        <img src="../../../template/green/assets/images/saba_sport.png" width="42" height="16" />
                        <img src="../../../template/green/assets/images/w_entertainment.png" width="81" height="31" />
                    </div>
                </div>
            </div>
        }

        <app-jackpot [value]="5000000" />

        <app-list-providers [data]="providersData"></app-list-providers>

        @if(!hideLiveScore){
            <div class="other-match-mobile mt-4">
                <div class="other-match-mobile-header d-flex align-items-center justify-content-between">
                    <p class="mb-0">Other Match</p>
                    <button>
                        12 Running Bets
                        <img src="../../../template/green/assets/images/ic-caret-circle-right.png" width="10" />
                    </button>
                </div>
                <div class="other-match-mobile-body">
                    @for (item of otherMatchData; track item;) {
                    <div>
                        <div class="d-flex text-white align-items-center justify-content-between other-match-list-item-header">
                            <p>{{ item.league_name }}</p>
                            <span class="d-flex">
                              <p class="border-right border-white">
                                @if(item.live){
                                  <img src="../../../template/green/assets/images/Live.png" width="41" height="16"
                                  class="is-live pl-auto ml-auto" />
                                }
                              </p>
                            </span>
                        </div>
                        <div class="other-match-list-item-body">
                            <div class="d-flex">
                                <div class="w-50 gap-1 teams">
                                    <p>{{ item.home_team_name }}</p>
                                    <p>{{ item.away_team_name }}</p>
                                </div>
                                <div class="w-25 gap-1 scores">
                                    <p class="text-end">{{ item.home_score }}</p>
                                    <p class="text-end">{{ item.away_score }}</p>
                                </div>
                                <div class="info-live">
                                    @if (item.live) {
                                      <div class="timer-live-score">
                                        <img
                                        #shakeImage
                                        src="../template/green/assets/images/ic-timer.png"
                                        width="11"
                                        height="12"
                                        class="mr-2"
                                      />
                                      <span #shakeSpan class="min-live text-end"
                                          >{{ getMinuteDifference(item.match_start_at) }}’</span
                                        >
                                      </div>
                                      @if(item.phase == 7){
                                        <span class="phase-live text-end">Penalty</span>
                                        } @else if(item.phase == 9){
                                        <span class="phase-live text-end">Fulltime</span>
                                        } @else if(item.phase == 3 || item.phase == 5){
                                        <span class="phase-live text-end">Halftime</span>
                                        }@else {
                                          <span class="phase-live text-end">Halftime</span>
                                        }
    
    
                                    } @if (!item.live) {
                                    <p class="mb-0 info-date">
                                        {{ formatDayDate(item.match_start_at)  }}
                                    </p>
                                    <p class="mb-0 info-date">
                                      {{ formatMonthYear(item.match_start_at) }}
                                  </p>
                                    }
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-end mt-1">
                                <p class="desc">Match 20 of 32</p>
    
                                <div class="btn-place-bet">
                                    <button class="place-bet">Place Bet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div class="other-match-mobile-footer">
                    <button>SHOW ALL MATCHES</button>
                </div>
            </div>
        }


        <div class="list-promotion">
            <app-slideshow-promotion [data]="promoJSONData" />
        </div>
    </div>
    <app-games-widget></app-games-widget>
    <app-footer [data]="footerData" />

    <div class="curve-container">
        <div class="curve-left"></div>
        <div class="curve-right"></div>
    </div>
    <div class="footer-last">
        <div class="d-md-none d-flex align-items-center justify-content-between">
            @if(websiteJSONData['configuration']['apk_url'] !== ''){
                <a [href]="websiteJSONData.configuration.apk_url" class="foot-mobile-nav">
                    <fa-icon class="icon" [icon]="faAndroid" [size]="'xl'"></fa-icon>
                    <p>APK</p>
                </a>
            }@else{
                <a [routerLink]="['/profile']" class="foot-mobile-nav">
                    <fa-icon class="icon" [icon]="faUser" [size]="'xl'"></fa-icon>
                    <p>Profil</p>
                </a>
            }
            <a [routerLink]="['/promotion']" class="foot-mobile-nav">
                <fa-icon class="icon" [icon]="faGift" [size]="'xl'"></fa-icon>
                <!-- <img src="../../../template/green/assets/images/ic-promosi.png" /> -->
                <p>Promosi</p>
            </a>
            <a [routerLink]="['/']" class="foot-mobile-nav">
                <fa-icon class="icon" [icon]="faHouse" [size]="'xl'"></fa-icon>
                <!-- <img src="../../../template/green/assets/images/ic-beranda.png" /> -->
                <p>Beranda</p>
            </a>
            @if(websiteJSONData['contact']['livechat']){
                <a [href]="livechatURL" class="foot-mobile-nav">
                    <fa-icon class="icon" [icon]="faComments" [size]="'xl'"></fa-icon>
                    <!-- <img src="../../../template/green/assets/images/ic-livechat.png" /> -->
                    <p>Livechat</p>
                </a>
            }@else {
                <a [routerLink]="['/contact']" class="foot-mobile-nav">
                    <fa-icon class="icon" [icon]="faComments" [size]="'xl'"></fa-icon>
                    <!-- <img src="../../../template/green/assets/images/ic-livechat.png" /> -->
                    <p>Contact</p>
                </a>
            }
            @if(isLoggedin) {
                <a [routerLink]="['/deposit']" class="foot-mobile-nav">
                    <fa-icon class="icon" [icon]="faWallet" [size]="'xl'"></fa-icon>
                    <p>Deposit</p>
                </a>
            }@else {
                <a [routerLink]="['/login']" class="foot-mobile-nav">
                    <fa-icon class="icon" [icon]="faRightToBracket" [size]="'xl'"></fa-icon>
                    <!-- <img src="../../../template/green/assets/images/ic-login.png" /> -->
                    <p>Masuk</p>
                </a>
            }
        </div>
    </div>
</div>
