<div class="promotion-mobile-container">
  <div class="header-container">
    <fa-icon [icon]="faArrowLeft" class="back-icon" (click)="goBack()"></fa-icon>
    <div class="title">PROMOTION</div>
  </div>
  <div class="promotion-body">
    @if(selectedPromo == null){
      <div class="promotion-content">
        @for (item of promoJSONData; track item._id;) {
          @if(item !== undefined && item.banner_url !== '') {
          <div class="promotion-list">
            <img
              [src]="item.banner_url"
              [alt]="item.title"
              (click)="promoDetail(item._id)"
              class="promotion-thumbnail"
            />
          </div>
          } }
      </div>
    } @else {
      <div class="promotion-content">
        <img
              [src]="selectedPromo.thumbnail_url"
              [alt]="selectedPromo.title"
              class="promotion-thumbnail"
            />
        <div class="promotion-container">
          <div class="promotion-container-description">
            <div class="title pb-2">
              {{ selectedPromo.title }}
            </div>
            <div class="content" [innerHTML]="selectedPromo.content | safeHtml"></div>
          </div>
        </div>
      </div>

    }
  </div>
</div>
