import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleXmark, faCircleCheck } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-card-game',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './card-game.component.html',
  styleUrl: './card-game.component.scss'
})
export class CardGameComponent {
  @Input() image: string = '';
  @Input() gacor: string = '';
  @Input() favourite: boolean = false;
  @Input() name: string = '';
  @Input() provider: string = '';
  @Input() percent: number = 0;
  @Input() pattern: string = '';
  @Input() check1: boolean = false;
  @Input() check2: boolean = false;
  @Input() check3: boolean = false;
  @Input() dc: boolean = false;

  faStar: IconProp = faStar;
  faClock: IconProp = faClock;
  faCircleXmark: IconProp = faCircleXmark;
  faCircleCheck: IconProp = faCircleCheck;
}
