import {
    Component,
    OnInit,
    CUSTOM_ELEMENTS_SCHEMA,
    PLATFORM_ID,
    Inject,
    AfterViewInit,
    inject,
    model
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle,
} from '@angular/material/dialog';
import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { SafeHtmlPipe, SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { DataService } from '@componentpath/services/data.service';
import { faCaretLeft, faCaretRight, faCirclePlay, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-popup',
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule, RouterModule, SlugNamePipe, NgOptimizedImage, FontAwesomeModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, SafeHtmlPipe],
    templateUrl: './popup.component.html',
    styleUrl: './popup.component.scss',
})
export class PopupComponent implements OnInit, AfterViewInit {
    faCaretRight: IconProp = faCaretRight;
    faCaretLeft: IconProp = faCaretLeft;
    faCirclePlay: IconProp = faCirclePlay;
    faXmark: IconProp = faXmark;

    readonly dialogRef = inject(MatDialogRef<PopupComponent>);
    readonly data = inject<any>(MAT_DIALOG_DATA);
    currrentIndex: number = 0;
    filterData:any;
    currentScreenSize: string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private breakpointObserver: BreakpointObserver,
    ) {
        this.checkScreen();
    }

    checkScreen() {
        let displayNameMap = new Map([
            [Breakpoints.XSmall, 'xSmall'],
            [Breakpoints.Small, 'small'],
            ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
        ]);
        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            "(min-width: 960px) and (max-width: 1365.98px)",
            "(min-width: 1366px)"
        ]).subscribe(result => {
            // console.log(result)
            for (const query of Object.keys(result.breakpoints)) {
                if (result.breakpoints[query]) {
                    this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
                }
            }
        });
    }

    seeMore(data:any): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(data, '_blank')
        }
        this.dialogRef.close();
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            console.log(this.data.records)
            if(this.data.records){
                // this.filterData = this.data.records.filter((li: any) => li.keyword.length !== 1)
                this.filterData = this.data.records;
            }
            // console.log(this.filterData)
        }
    }
    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
        }
    }
}
