import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
@Component({
  selector: 'app-password',
  standalone: true,
  imports: [CommonModule,MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss'
})
export class PasswordComponent {
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmationPassword = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  isSubmit: boolean = false;
  userSecurityData: any = null;

  successMessage: string = '';
  errorMessage: string = '';
  password: Array<any> = ['', '', ''];

  editPassword(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.password[0] || !this.password[1] || !this.password[2]) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'Semua kolom harus diisi', type : 'mobile-error' },
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "notification-error"
        });
        this.isSubmit = false;
        return;
      } else {
        if (this.password[1] !== this.password[2]) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: 'Konfirmasi password tidak sama', type : 'mobile-error' },
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: "notification-error"
          });
          this.isSubmit = false;
          return;
        } else {
          const userProfileData: any = this.auth.userProfileData;
          this.request.editPassword(this.auth.sessionValue, userProfileData._id, this.password[1], this.password[0]).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.router.navigate(['/profile'],{ queryParams: { part: 'default' } });
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: { message: 'Berhasil ubah password', type : 'mobile-success' },
                  duration: 3000,
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: "notification-success"
                });
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: { message: e.error.messages[0].message, type : 'mobile-error' },
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: "notification-error"
              });
            }
          })
        }
      }
    }
  }
}

