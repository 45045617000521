<div class="promotion-body">
  <div class="profile-section">
    <div class="header">
      <div class="title-container">
        <div class="title">PASSWORD</div>
        <div class="subtitle">
          Gunakan kata sandi untuk dapat login kedalam akun anda
        </div>
      </div>
    </div>
    <div class="input-container">
      <div class="input-item">
        <mat-form-field class="w-100">
          <mat-label>Masukan Password Lama Anda</mat-label>
          <input matInput [type]="hideOldPassword ? 'password' : 'text'" [(ngModel)]="password[0]"/>
          <button
            mat-icon-button
            matSuffix
            (click)="hideOldPassword = !hideOldPassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideOldPassword"
          >
            <mat-icon>{{
              hideOldPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="input-item">
        <mat-form-field class="w-100">
          <mat-label>Masukan Password Baru Anda</mat-label>
          <input matInput [type]="hideNewPassword ? 'password' : 'text'" [(ngModel)]="password[1]"/>
          <button
            mat-icon-button
            matSuffix
            (click)="hideNewPassword = !hideNewPassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideNewPassword"
          >
            <mat-icon>{{
              hideNewPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="input-item">
        <mat-form-field class="w-100">
          <mat-label>Konfirmasi Password Baru</mat-label>
          <input matInput [type]="hideConfirmationPassword ? 'password' : 'text'" [(ngModel)]="password[2]"/>
          <button
            mat-icon-button
            matSuffix
            (click)="hideConfirmationPassword = !hideConfirmationPassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideConfirmationPassword"
          >
            <mat-icon>{{
              hideConfirmationPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="save-button"
    [class.disabled-div]="isSubmit"
    (click)="editPassword()"
    [ngClass]="{ 'disabled-div': isSubmit }">
        @if(isSubmit) {
        <div class="spinner-border p-2 spinner-border-sm"></div>
        } @else {
        SAVE
        }
    </div>
