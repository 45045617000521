@if(showNotif){
<div class="error-notif"><fa-icon [icon]="faCircleInfo"></fa-icon>{{showNotifText}}</div>
}

<div class="page-deposit-desktop">
    <app-header-simple />
    <div class="container-xxl m-auto">
        <div class="title-page h2 my-5">
            DEPOSIT
        </div>
    
        @if(showError && !showPromo){
        <div class="page-change-payment page-error">
            <div class="container-xxl m-auto">
                <div class="row align-items-center container-change-promo d-flex py-5 pt-3 mb-5">
                    <div class="container-payment">
                        <div class="pembayaran-error">
                            <span class="image-success">
                                <fa-icon [icon]="faShield" [size]="'9x'"></fa-icon>
                                <fa-icon [icon]="faXmark" [size]="'4x'"></fa-icon>
                            </span>
                            <span class="text-pembayaran">Mohon maaf, waktu pembayaran anda sudah habis.<br> Mohon hubungi
                                CS kami jika anda mengalami kendala.</span>
                        </div>
                        <button type="button" class="btn btn-redirect mt-3" [disabled]="disabledButtonSub"
                            (click)="cancelAutoPayment()">Kembali</button>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        }
    
        @if(!showPromo && !showError){
        @if(!showFormPaymentAuto && !showFormPaymentManual &&!loader){
        @if(!nextStepOption){
        <div class="row align-items-center main-deposit d-flex py-5 mb-5">
            <div class="title-page-small h5">
                Pilih Opsi Pembayaran
            </div>
            <div class="option-payment">
                <div class="auto-button-big mt-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                    (click)="selectOptionPayment('auto')">
                    <div class="img-button">
                        <img src="../../../template/green/assets/images/auto.png">
                        <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                    </div>
                    <div class="text-container">
                        <span class="text-main">Auto Deposit</span>
                        <span class="text-sub">Deposit dengan payment gateway</span>
                    </div>
                    <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                </div>
                <div class="manual-button-big mt-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                    (click)="selectOptionPayment('manual')">
                    <div class="img-button">
                        <img src="../../../template/green/assets/images/manual.png">
                        <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                    </div>
                    <div class="text-container">
                        <span class="text-main">Manual Deposit</span>
                        <span class="text-sub">Deposit transfer bank dan lainnya melalui admin</span>
                    </div>
                    <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                </div>
    
                <button type="button" class="btn btn-select-deposit mt-4" (click)="nextButtonOption()">SELANJUTNYA</button>
            </div>
    
        </div>
        } @else {
        <div class="row align-items-center main-deposit d-flex py-5 mb-5">
            <div class="title-page-small h5">
                Pilih Metode Pembayaran
            </div>
            <div class="option-payment">
                <div class="auto-button-big mt-4" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':'' "
                    (click)="selectMethodPayment('vacc')">
                    <div class="img-button">
                        <img src="../../../template/green/assets/images/vacc.png">
                    </div>
                    <div class="text-container">
                        <span class="text-main">Virtual Account</span>
                        <span class="text-sub">Transfer dengan cepat ke akun bank virtual pilihan anda</span>
                    </div>
                    <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                </div>
                <div class="manual-button-big mt-4" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                    (click)="selectMethodPayment('qris')">
                    <div class="img-button">
                        <img src="../../../template/green/assets/images/qris.png">
                    </div>
                    <div class="text-container">
                        <span class="text-main">QRIS</span>
                        <span class="text-sub">Pembayaran cepat dan mudah dengan scan barcode QRIS</span>
                    </div>
                    <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                </div>
    
                <button type="button" class="btn btn-select-deposit mt-4" (click)="nextButtonMethod()">SELANJUTNYA</button>
            </div>
    
        </div>
        }
        } @else{
        @if(!hideSwitchMenu && depositConfig['status']){
        <div class="container-menu-option">
            <div class="small-option-payment">
                <div class="auto-button-small mb-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                    (click)="selectOptionPayment('auto'); checkActiveDepositAuto(); showFormPaymentAuto = true">
                    <div class="img-button">
                        <img src="../../../template/green/assets/images/auto.png">
                        <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                    </div>
                    <div class="text-container">
                        <span class="text-main">Auto Deposit</span>
                        <span class="text-sub">Deposit dengan payment gateway</span>
                    </div>
                </div>
                <div class="manual-button-small mb-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                    (click)="selectOptionPayment('manual'); showFormPaymentManual = true">
                    <div class="img-button">
                        <img src="../../../template/green/assets/images/manual.png">
                        <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                    </div>
                    <div class="text-container">
                        <span class="text-main">Manual Deposit</span>
                        <span class="text-sub">Deposit transfer bank melalui admin</span>
                    </div>
                </div>
            </div>
        </div>
        }
    
        @if(loader) {
        <div class="spinner-container">
            <div class="spinner-border" style="margin: 0 auto;color: var(--accent-main-text-color);" role="status"></div>
        </div>
        }@else{
        @if(showFormPaymentAuto && currentOptionPayment ==='auto'){
        <div class="row main-deposit container-auto-deposit d-flex mb-5">
            <div class="side-bar-deposit"
                [ngStyle]="{'height':(!showConfirmPayment && currentMethodPayment === 'qris')? '505px': (showConfirmPayment && currentMethodPayment === 'vacc')? '525px': (showConfirmPayment && currentMethodPayment === 'qris')?'384px':'600px'}">
                <div class="status-balance-user">
                    <div class="badge-user">
                        <img src="../../../template/green/assets/images/status-gold.png">
                    </div>
                    <div class="data-user">
                        <span class="group-user">
                            <img
                                src="../../../template/green/assets/images/rank/{{(userGroupData['name']).toLowerCase()}}.webp">
                        </span>
                        <span class="username">{{userProfile['login_id']}}</span>
                        @if(balance == undefined){
                        <span class="balance">Loading</span>
                        } @else {
                        <span class="balance">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</span>
                        }
                    </div>
                </div>
                @if(depositConfig['virtual_account']){
                <div class="pay-vacc" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':''"
                    (click)="selectMethodPayment('vacc')">
                    <span class="img-icon">
                        <img src="../../../template/green/assets/images/vacc.png">
                    </span>
                    <span class="text-menu">Pay via Virtual Account</span>
                    <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                </div>
                }
                @if(depositConfig['qris']){
                <div class="pay-qris" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                    (click)="selectMethodPayment('qris')">
                    <span class="img-icon">
                        <img src="../../../template/green/assets/images/qris.png">
                    </span>
                    <span class="text-menu">Pay via QRIS</span>
                    <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                </div>
                }
            </div>
            @if(successDeposit){
            <div class="container-payment">
                <div class="pembayaran-berhasil">
                    <span class="image-success">
                        <fa-icon [icon]="faShield" [size]="'9x'"></fa-icon>
                        <fa-icon [icon]="faCheck" [size]="'4x'"></fa-icon>
                    </span>
                    <span class="text-pembayaran">PEMBAYARAN BERHASIL</span>
                </div>
                <button type="button" class="btn btn-redirect mt-3" [disabled]="disabledButtonSub"
                    (click)="goToTransaction()">Kembali Ke Riwayat
                    Transaksi</button>
                <div></div>
                <button type="button" class="btn btn-auto-redirect" [disabled]="disabledButtonSub">Otomatis kembali ke
                    riwayat
                    transaksi ({{timeCountSecond}}s)</button>
            </div>
            }@else{
            @if(!showConfirmPayment){
            <div class="container-payment">
                @if(currentMethodPayment === 'vacc'){
                <div class="choose-bank">
                    <label class="label-input">Pilih Bank Tujuan</label>
                    <div ngbDropdown class="d-inline-block w-100">
                        <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank"
                            ngbDropdownToggle>
                            BANK
                        </button>
                        <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                            <button ngbDropdownItem></button>
                            <button ngbDropdownItem></button>
                            <button ngbDropdownItem></button>
                        </div>
                    </div>
                </div>
                }
                <div class="input-amount">
                    <span class="title-deposit">DEPOSIT</span>
                    <label class="label-input">Masukan Nominal Deposit</label>
                    <div class="d-inline-block w-100">
                        <div class="container-input"
                            [ngStyle]="{'border-color':isCorrectValue !== 0 ? 'red':'rgba(255, 255, 255, 0.5)'}">
                            <label for="inputAmount" class="label-input-small">Masukan Nominal</label>
                            <input id="inputAmount" type="text" spellcheck="false"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                class="form-control" placeholder=" "
                                [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')"
                                (ngModelChange)="updateValueNominal($event)" name="inputAmount"
                                aria-label="Masukan Nominal" />
                        </div>
                        <div class="chip-amount-container">
                            <swiper-container appSwiper [config]="config" class="swiper-container">
                                @for (item of arrayNominal; track item;) {
                                <swiper-slide class="swiper-item">
                                    <div class="chip-amount" [ngClass]="currentNominal === item ? 'active-chip':''"
                                        (click)="setNominalChip(item)">Rp {{item | currency:' ' : 'symbol' : '1.0-0'}}</div>
                                </swiper-slide>
                                }
                            </swiper-container>
                        </div>
                    </div>
                </div>
                <div class="choose-promo">
                    <label class="label-input">Pilih Voucher Promo</label>
                    <div class="d-inline-block w-100">
                        @if (currentPromo['_id']) {
                        <div class="container-promo-bar active-promo" [ngClass]="disabledButton? 'pointer-off':''">
                            <div class="area-click" (click)="openPromoSearch()">
                                <span class="img-icon">
                                    <img src="../../../template/green/assets/images/voucher_applied.png">
                                </span>
                                <span class="text-status display-600-none">Voucher Applied</span>
                                <span class="text-name-promo">{{currentPromo['title']}}</span>
                            </div>
                            <fa-icon [icon]="faTrashCan" (click)="resetPromo()"></fa-icon>
                        </div>
                        } @else {
                        <div class="container-promo-bar" [ngClass]="disabledButton? 'pointer-off':''"
                            (click)="openPromoSearch()">
                            <span class="img-icon">
                                <img src="../../../template/green/assets/images/voucher.png">
                            </span>
                            <span class="text-status">Voucher Promo</span>
                            <span>Pilih Voucher</span>
                            <!-- <fa-icon [icon]="faTrashCan"></fa-icon> -->
                        </div>
                        }
                    </div>
                </div>
                <div class="transaction-detail">
                    <div class="container-rule-payment">
                        <span>
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            @if(depositConfig['handled_by'] === 1){
                            @if(currentMethodPayment ==="qris"){
                            Transaction Fee {{depositConfig['qris_fee']*100}}% {{depositConfig['qris_fee_fix_amount'] == 0 ?
                            '':'+ '+(depositConfig['qris_fee_fix_amount'] | currency:' ' : 'symbol' : '1.0-0')}}
                            }@else{
                            Transaction Fee {{depositConfig['virtual_account_fee']*100}}%
                            {{depositConfig['virtual_account_fee_fix_amount'] == 0 ? '':'+
                            '+(depositConfig['virtual_account_fee_fix_amount'] | currency:' ' : 'symbol' : '1.0-0')}}
                            }
                            } @else {
                            Transaction Fee FREE
                            }
                        </span>
                        <span>
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            Minimum Jumlah Deposit 10.000
                        </span>
                        <span>
                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                            Maksimum Jumlah Deposit 10.000.000
                        </span>
                    </div>
                    <div class="container-payment-detail">
                        <div class="jumlah-deposit">
                            <span>Jumlah Deposit</span>
                            <span>IDR {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                        </div>
                        <div class="biaya-transaksi">
                            <span>Biaya Transaksi</span>
                            @if(depositConfig['handled_by'] === 1){
                            @if(currentMethodPayment ==="qris"){
                            <span>IDR {{inputAmount ? ((inputAmount *
                                (depositConfig['qris_fee']))+depositConfig['qris_fee_fix_amount'] | currency:' ' : 'symbol'
                                : '1.0-0') : 0}}</span>
                            }@else{
                            <span>IDR {{inputAmount ? ((inputAmount *
                                (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount'] |
                                currency:' ' : 'symbol' : '1.0-0') :
                                0}}</span>
                            }
                            } @else {
                            FREE
                            }
                        </div>
                        <div class="total-pembayaran">
                            <span>Total Diterima</span>
                            @if(depositConfig['handled_by'] === 1){
                            @if(currentMethodPayment ==="qris"){
                            <span>IDR {{inputAmount ? (inputAmount-((inputAmount *
                                (depositConfig['qris_fee']))+depositConfig['qris_fee_fix_amount']) | currency:'
                                ' : 'symbol' : '1.0-0') : 0}}</span>
                            }@else{
                            <span>IDR {{inputAmount ? (inputAmount-((inputAmount *
                                (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount']) |
                                currency:' ' : 'symbol' :
                                '1.0-0') :
                                0}}</span>
                            }
                            }@else{
                            <span>IDR {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                            }
                        </div>
                    </div>
                </div>
    
                <button type="button" id="deposit-button" class="btn track-deposit btn-confirm-deposit mt-2"
                    (click)="confirmDeposit()" [disabled]="disabledButton">{{inProgress ? 'DALAM
                    PROSES':'DEPOSIT'}}</button>
            </div>
            } @else {
            <div class="container-payment container-detail-transfer">
                @if(currentMethodPayment === 'vacc'){
                <div class="area-detail-payment">
                    <div class="detail-bank">
                        <span class="sub-title">Lakukan Pembayaran via Bank</span>
                        <div class="bank-data">
                            <img src="../../../template/green/assets/images/qris.png">
                            <span class="bank-name">Standard Chartered</span>
                        </div>
                    </div>
                    <div class="vacc-detail">
                        <span class="sub-title">Nomor Virtual</span>
                        <span class="vacc-number">6792929283474582892929</span>
                    </div>
                    <div class="transfer-detail">
                        <span class="sub-title">Jumlah Transfer</span>
                        <div class="nominal-transfer">IDR <span class="value-transfer">{{inputAmount ?
                                (inputAmount+((inputAmount *
                                (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount']) |
                                currency:' ' : 'symbol' :
                                '1.0-0') :
                                0}}</span></div>
                    </div>
                </div>
                <button type="button" class="btn btn-check-deposit mt-2">Cek Status Pembayaran</button>
                <button type="button" class="btn btn-cancel-deposit">Batalkan Pembayaran Virtual Account</button>
                }@else {
                <span class="title-deposit-payment">HARAP SEGERA MELAKUKAN PEMBAYARAN</span>
                <div class="area-qris-payment">
                    <div class="qris-image">
                        <!-- <qrcode [imageSrc]="websiteJSONData['header']['favicon']" [imageHeight]="50" [imageWidth]="50" (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="256" [errorCorrectionLevel]="'M'">
                                                            </qrcode> -->
                        <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="210"
                            [imageHeight]="210" [errorCorrectionLevel]="'M'">
                        </qrcode>
    
                        <a [href]="qrCodeDownloadLink" download="qrcode" class="btn btn-download-qr mt-1">Download QR</a>
                    </div>
                    <div class="area-detail-container">
                        <div class="area-detail-payment">
                            <div class="container-detail">
                                <div class="qris-detail">
                                    <span class="user-name">{{autoData['paymentgateway_name']}}</span>
                                </div>
                                <div class="transfer-detail">
                                    <div class="nominal-transfer">IDR {{autoData ? (autoData['amount'] | currency:' ' :
                                        'symbol' : '1.0-0') : 0}}</div>
                                </div>
                                <div class="time-expired">
                                    Berlaku Hingga <span class="count-time">({{ minutesShow }}:{{ secondsShow }})</span>
                                </div>
                            </div>
                            <div class="container-button-qris mt-1">
                                <button type="button" class="btn btn-cancel-deposit" [disabled]="disabledButtonSub"
                                    (click)="cancelAutoPayment()">Batalkan Pembayaran</button>
                                <button type="button" class="btn btn-check-deposit" [disabled]="disabledButtonSub"
                                    (click)="checkStatusDepositAuto()">Konfirmasi Pembayaran</button>
                            </div>
                        </div>
    
                    </div>
                </div>
                }
            </div>
            }
            }
    
        </div>
        }
    
        @if(showFormPaymentManual && currentOptionPayment === "manual" && !loader){
        <app-deposit-manual></app-deposit-manual>
        }
        }
    
        }
        }
        @if(showPromo && !showError){
        <div class="page-change-payment">
            <div class="container-xxl m-auto">
                <div class="row align-items-center container-change-promo d-flex py-5 pt-3 mb-5">
                    <div class="title-page-small h5">
                        Pilih Promo Menarik
                    </div>
                    <div class="container-input-filter">
                        <div class="input-filter">
                            <form (ngSubmit)="inputSearchPromo()">
                                <input class="form-control" type="text" [formControl]="searchPromo"
                                    placeholder="Cari Promo...">
                                <button type="submit" class="btn">
                                    <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                                </button>
                            </form>
                        </div>
                        <!-- <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                                        <div ngbDropdownMenu class="dropdown-menu select-bank">
                                            <div class="d-flex flex-column gap-2">
                                                <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                                    [class.active]="currentSelectPaymentType === 'all'"
                                                    (click)="onSelectDropdownValue('all')">
                                                    Tampilkan Semua
                                                </label>
                                                <label ngbDropdownItem class="d-flex gap-2"
                                                    [class.active]="currentSelectPaymentType === 'deposit'"
                                                    (click)="onSelectDropdownValue('deposit')">
                                                    Deposit Promo
                                                </label>
                                                <label ngbDropdownItem class="d-flex gap-2"
                                                    [class.active]="currentSelectPaymentType === 'cashback'"
                                                    (click)="onSelectDropdownValue('cashback')">
                                                    Cashback Promo
                                                </label>
                                            </div>
                                        </div>
                                    </div> -->
                    </div>
                    <div class="container-list-dynamic">
                        <div class="container-list-promo">
                            @for (item of promoSearch; track $index) {
                            <div class="card-promo" (click)="selectedPromo = item">
                                @if(item['type'] === 'deposit'){
                                <div class="type-promo">DEPOSIT</div>
                                <div class="title-promo deposit">{{item['title']}}</div>
                                <img class="display-600-none"
                                    src="../../../template/green/assets/images/deposit_promo.png" />
                                <img class="display-600-block"
                                    src="../../../template/green/assets/images/deposit_promo_small.png" />
                                @if(selectedPromo['_id'] === item['_id']){
                                <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                                }
                                }
                                @if(item['type'] === 'cashback'){
                                <div class="type-promo">CASHBACK</div>
                                <div class="title-promo cashback">{{item['title']}}</div>
                                <img src="../../../template/green/assets/images/cashback_promo.png" />
                                @if(selectedPromo['_id'] === item['_id']){
                                <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                                }
                                }
                            </div>
                            }
                        </div>
                    </div>
                    <div class="container-buttons">
                        <div class="btn btn-create-rek" (click)="resetPromoBack()">Batal</div>
                        <div class="btn btn-select-rek" (click)="selectPromo()">Pilih Promo</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-pin">
            <div class=""></div>
        </div>
        }
    </div>
    
    <app-footer [data]="footerData" />
</div>
