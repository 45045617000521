import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, PLATFORM_ID, Inject, Input, AfterViewInit, ViewChild, ElementRef, afterNextRender } from '@angular/core';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { SwiperOptions } from 'swiper/types';
import { A11y, Autoplay, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { register } from 'swiper/element/bundle';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-slideshow-home',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgOptimizedImage, RouterModule],
  templateUrl: './slideshow-home.component.html',
  styleUrl: './slideshow-home.component.scss',
})
export class SlideshowHomeComponent implements OnInit, AfterViewInit {
  @ViewChild('swiperContainer') swiperContainerRef!: ElementRef;
  @Input() data: any;

  public configSlideshowHome: SwiperOptions = {};
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.swiperRender();
  }

  swiperRender(){
    afterNextRender((): void => {
      this.configSlideshowHome = {
        loop: true,
        mousewheel:false,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      if (this.swiperContainerRef){
        Object.assign(this.swiperContainerRef.nativeElement, this.configSlideshowHome); // Add parameters to the Swiper
        this.swiperContainerRef.nativeElement.initialize(); // Init Swiper
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log(this.data);
    }
  }
  ngAfterViewInit(): void {
    register();
  }
}
