<div class="page-category">
    <app-header-simple [activeMenu]="id" />
    <app-slideshow2 [data]="slideshowData" />

    @if(id === 'slots' || id === 'live-casino' || id === 'fish-hunter') {
    <div class="page-title">
        <div class="container-xxl m-auto">
            @if(id === 'slots') {
            SLOT
            } @else if(id === 'live-casino') {
            CASINO
            }@else if(id === 'fish-hunter') {
            ARCADE
            }
        </div>
    </div>
    }

    <app-all-games [data]="gameData" [gameTypes]="gameTypes" [filterAll]="false" [seeAll]="false"
        [filterCategory]="true" [loadMore]="true" [lobby]="id === 'live-casino' ? true : false" />
    <app-all-providers [data]="allProvidersData" />

    <app-footer [data]="footerData" />
</div>