import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faLine, faLinkedin, faRedditAlien, faTelegram, faWhatsapp, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faHeadphones, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ContactMobileComponent } from '@template/green/mobile/contact-mobile/contact-mobile.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [HeaderSimpleComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule,ContactMobileComponent],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
   }

  currentScreenSize: string = '';

  faFacebookF: IconProp = faFacebookF;
  faInstagram: IconProp = faInstagram;
  faLine: IconProp = faLine;
  faLinkedin: IconProp = faLinkedin;
  faReddit: IconProp = faRedditAlien;
  faTelegram: IconProp = faTelegram;
  faXTwitter: IconProp = faXTwitter;
  faWhatsapp: IconProp = faWhatsapp;
  faYoutube: IconProp = faYoutube;
  faHeadphones: IconProp = faHeadphones;
  faPhone: IconProp = faPhone;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  websiteJSONData: any = this.data.websiteJSONData;
  livechatURL: string = '';

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else{
        this.currentScreenSize = 'desktop';
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
